 <template>
 <v-col  :cols="cols" style="padding: 0px;padding-bottom:10px;padding-right:5px">
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card :elevation="hover ? 16 : 2">
            <v-toolbar color="#042E52" dark style="height:40px">
             <v-btn icon style="margin-top:-20px">
                <v-icon>mdi-cart</v-icon>
              </v-btn>
              <v-toolbar-title style="margin-top:-20px">Gastos del periodo</v-toolbar-title>
            </v-toolbar>
            
            <v-container fluid>
              <v-list-item style="height:120px;margin-left:7px;padding-left: 5px;padding-right: 5px;margin-top:0px">
                <v-list-item-content style="margin-left:-5px;margin-top:-10px;">
                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="6">Facturas de gastos</v-col>
                    <v-col cols="4" style="margin-top:6px"><hr style="border-style: dotted;color:gray"/> </v-col>
                    <v-col cols="2" style="color:black;font-weight:bolder;">{{$store.state.dashboard.facturasEmitidasEgresos}}</v-col>
                  </v-row>

                  <!-- <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px;margin-top:-8px">
                    <v-col cols="6">Pendientes</v-col>
                    <v-col cols="4" style="margin-top:6px"><hr style="border-style: dotted;color:gray"/> </v-col>
                    <v-col cols="2" style="color:black;font-weight:bolder;">14</v-col>
                  </v-row> -->
                  <hr></hr>
                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="7">Subtotal de gastos</v-col>
                    <v-col cols="5" style="color:black;font-weight:bolder;">{{getSubtotalEgresos}}</v-col>
                  </v-row>

                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="7">IVA</v-col>
                    <v-col cols="5" style="color:black;font-weight:bolder;">{{getivaTrasladadoEgresos}}</v-col>
                  </v-row>
                  <hr></hr>
                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="7" style="color:black;font-weight:bolder;">Total de gastos</v-col>
                    <v-col cols="5" style="color:black;font-weight:bolder;">{{gettotalEgresos}}</v-col>
                  </v-row>

                </v-list-item-content>
               </v-list-item>
               </v-container>

                <!-- <v-divider color="grey"></v-divider> -->
            <v-card-actions>
              <v-spacer></v-spacer>

               <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                      <!--<v-btn v-on="on" v-bind="attrs" color="white"  x-small style="margin-top: 20px;width:40px" @click="deleteConceptoFactura(props.item)">
                          <v-icon style="margin-right:5px" color="red">mdi-delete-forever</v-icon>
                      </v-btn>-->
                      <v-btn v-on="on" 
                      v-bind="attrs" 
                      depressed  
                      small 
                      color="success" 
                      outlined 
                      style="margin-right:5px"
                      :loading="loadingBtn"
                      :disabled="loadingBtn"
                      @click="descargarExcell">
                        <v-icon x-small>mdi-send</v-icon>Descargar
                        </v-btn>
                  </template>
              <span>Reporte de ingresos y egresos</span>
              </v-tooltip>
            </v-card-actions>
            <v-snackbar v-model="snackbar2" :color="colorSnack">
              {{ mensajeSnack }}
              <v-btn text @click="snackbar2 = false">Cerrar</v-btn>
            </v-snackbar>
          </v-card>
          </v-hover>
        </v-col>
</template> 

<script>

import operations from '../../operations';

  export default {
     directives: {

    },
    components:{
     
    },
    name: 'Gastos',
    data: () => ({
    title:'Datos fiscales',
    src:'https://cdn.vuetifyjs.com/images/cards/house.jpg',
    loadingBtn: false,
    snackbar2: false,
    colorSnack: "",
    mensajeSnack: "",
    }),
    methods:{
      descargarExcell(){
        this.loadingBtn = true;
        console.log("totalEgresos: " + this.$store.state.dashboard.totalEgresos);
        console.log("totalIngresos: " + this.$store.state.dashboard.totalIngresos);
        if (this.$store.state.dashboard.totalEgresos == 0 && this.$store.state.dashboard.totalIngresos == 0){
          console.log("No hay ingresos ni egresos...");
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack =
            "No hay ingresos ni egresos en este periodo.";
        } else{
          this.descargarExcellAsync();
        }
        this.loadingBtn = false;
      },
      async descargarExcellAsync() {
        //console.log(this.$store.state.dashboard.fechaPeriodo);
        //console.log('Reporte_Contable_'+this.$store.state.cliente.rfc+"_"+ this.mes + "_" + this.anio+".xls");
        //console.log('DownloadReporteValidacionFacturasForm....');
        try{
          const response = await operations.downloadReporteValidacionFacturasForm({ rfc: this.$store.state.cliente.rfc,anio: this.anio,mes: this.mes});
          console.log(response);

          if(response.status == "200"){
            console.log("ok response excell");
            console.log(response.data.datosFiscales);
            var datos =response.data.datosFiscales;

            const url = window.URL.createObjectURL(new Blob([response.data], {type:'application/vnd.ms-excel'}));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Reporte_Contable_'+this.$store.state.cliente.rfc+"_"+ this.mes+"_"+this.anio+".xls");
            document.body.appendChild(link);
            link.click();
            //console.log(response.data)
          }
        }
        catch(error){
              console.log(error.response);
        }
        this.loadingBtn = false;
      },
    },
    props:{
     cols:String
    },
    computed: {
      getSubtotalEgresos(){
       //{{$store.state.dashboard.subtotalEgresos}}
       var subtotalEgresos = this.$store.state.dashboard.subtotalEgresos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       if(subtotalEgresos.includes('.'))
           return "$"+subtotalEgresos;
           else
           return "$"+subtotalEgresos + ".00";


       return "$"+subtotalEgresos;//$store.state.dashboard.subtotalEgresos;
     },
     getivaTrasladadoEgresos(){
        //{{$store.state.dashboard.ivaTrasladadoEgresos}}
       var ivaTrasladadoEgresos = this.$store.state.dashboard.ivaTrasladadoEgresos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       if(ivaTrasladadoEgresos.includes('.'))
           return "$"+ivaTrasladadoEgresos;
           else
           return "$"+ivaTrasladadoEgresos + ".00";


       return "$"+ivaTrasladadoEgresos;//$store.state.dashboard.ivaTrasladadoEgresos;
     },
     gettotalEgresos(){
        //{{$store.state.dashboard.totalEgresos}}
      var totalEgresos = this.$store.state.dashboard.totalEgresos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if(totalEgresos.includes('.'))
        return "$"+totalEgresos;
        else
        return "$"+totalEgresos + ".00";
     },
     vista(){
         return 6;
     },
     mes(){
       return (this.$store.state.dashboard.fechaPeriodo.split('-'))[1];
     },
     anio(){
       return (this.$store.state.dashboard.fechaPeriodo.split('-'))[0];
     }
    },
  }
</script>

<style scoped>
</style>