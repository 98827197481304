<template>
  <v-container
    dense
    class=""
    style="margin-top: 47px; padding: 0px !important; max-width: 900px"
  >
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="#042E52"
        :fixed-tabs="fixedTabs"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>
        <!-- <v-tab href="#tab-1" style="font-size: small;">Avisos<v-icon>mdi-bell-alert</v-icon>
            </v-tab>-->
        <v-tab href="#tab-2" style="font-size: small"
          >Dashboard<v-icon>mdi-view-dashboard</v-icon>
        </v-tab>
        <v-tab
          v-if="this.$store.state.dashboard.isCSD"
          href="#tab-3"
          style="font-size: small"
          >Emitir CFDI 4.0<v-icon>mdi-file-document-edit-outline</v-icon>
        </v-tab>
        <v-tab
          v-if="this.$store.state.dashboard.isCSD"
          href="#tab-4"
          style="font-size: small"
          >Cancelar CFDI<v-icon>mdi-file-cancel-outline</v-icon>
        </v-tab>
        <v-tab href="#tab-5" style="font-size: small"
          >Contacto<v-icon>mdi-face-agent</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">        
        <!-- Dashboard -->
        <v-tab-item value="tab-2">
          <!-- dashboard -->
          <v-card
            v-if="this.$store.state.billerticket"
            flat
            class="mx-auto"
            elevation="0"
            min-content="200"
            max-width="2024"
            style="width: 100%"
          >
            <v-toolbar color="white" style="margin-bottom: 10px; height: 60px">
              <v-row style="width: 100%; height: 55px">
                <v-col
                  cols="3"
                  style="text-align: right; padding: 0px; margin-top: 14px"
                >
                  <span style="font-weight: bold; width: 100%"
                    >Periodo fiscal:</span
                  >
                </v-col>
                <!-- calendario -->
                <v-col cols="4" style="margin-top: -10px; text-align: right">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="$store.state.dashboard.fechaPeriodo"
                    persistent
                    width="290px"
                  >
                    <template
                      v-slot:activator="{ on }"
                      style="cursor: pointer !important"
                    >
                      <v-btn
                        v-model="$store.state.dashboard.fechaPeriodo"
                        label=""
                        v-on="on"
                        style="
                          width: 110px;
                          margin-right: 200px;
                          margin-top: 6.5px;
                        "
                        color="#0065BB"
                        outlined
                        ><v-icon style="margin-right: 5px">mdi-calendar</v-icon
                        >{{ $store.state.dashboard.fechaPeriodo }}
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="$store.state.dashboard.fechaPeriodo"
                      type="month"
                      scrollable
                      locale="es"
                      :min="periodoMinimo"
                      :max="periodoMaximo"
                      color="#042E52"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#0065BB" @click="modal = false"
                        >Cancelar</v-btn
                      >
                      <v-btn text color="#0065BB" @click="changeFecha"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="5"
                  style="text-align: left; padding: 0px; margin-top: 14px"
                >
                  <span style="font-weight: bold; width: 100%"
                    >Emitidos Utax Me: {{ $store.state.countCFDIS }}</span
                  >
                </v-col>
                <!--  <v-col xs="1" sm="2" md="2" style="text-align:left;">
                                <span style="font-weight: bold;width:100%;">{{$store.state.countCFDIS}}</span>
                            </v-col>-->
              </v-row>
            </v-toolbar>

            <v-container
              fluid
              v-if="this.$store.state.pc"
              style="background-color: white"
            >
              <v-row dense>
                <fiscales cols="6"></fiscales>
                <ingresos cols="6"></ingresos>
                <gastos cols="6"></gastos>
                <cumplimiento cols="6"></cumplimiento>
              </v-row>
            </v-container>

            <v-container
              fluid
              v-if="this.$store.state.mobile"
              style="background-color: white"
            >
              <fiscales cols="12"></fiscales>
              <ingresos cols="12"></ingresos>
              <gastos cols="12"></gastos>
              <cumplimiento cols="12"></cumplimiento>
            </v-container>
          </v-card>

          <v-card
            v-else
            flat
            class="mx-auto"
            min-width="304"
            :height="alturaTabAvisos"
          >
            <v-card-text>
              <div style="margin-top: 5px; line-height: 1.2">
                Estimado cliente, aun no contamos con sus datos fiscales para
                mostrarle la información del Dashboard.
              </div>
              <p style="margin-top: 8px; line-height: 1.2">
                Por favor contacte con uno de nuestros ejecutivos.
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Emitir CFDI -->
        <v-tab-item value="tab-3" v-if="this.$store.state.dashboard.isCSD">
          <v-card
            v-if="!this.$store.state.mobile"
            flat
            class="mx-auto"
            min-width="304"
            color="white"
          >

            <!-- F7F8F9---------------------------------------------------- -->
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <!-- Form Inicial CFDI 4.0 -->
                  <v-form
                    v-model="formInicial.formValid"
                    ref="formInicial"
                    style="width: 100%; margin-left: 0px; margin-top: -10px"
                  >
                    <!-- Card Datos Emisor -->
                    <v-card style="margin-top: 10px" height="90">
                      <v-container>
                        <v-row style="width: 100%">
                          <!-- rfc Emisor SPAN-->
                          <v-col cols="12" md="5" style="text-align: left">
                            <span
                              style="
                                margin-left: 30px;
                                font-weight: bold;
                                width: 100%;
                                position: absolute;
                                margin-top: 20px;
                                font-size: 90%;
                              "
                            >{{$store.state.cliente.rfc}}</span>
                          </v-col>
                        </v-row>
                        <v-row style="width: 100%; height: 55px">
                          <!-- Razón social emisor SPAN -->
                          <v-col cols="12" md="5" style="text-align: left">
                            <span
                              style="
                                margin-left: 30px;
                                font-weight: bold;
                                width: 100%;
                                position: absolute;
                                margin-top: 20px;
                                font-size: 90%;
                              "
                            >{{$store.state.dashboard.razonSocial}}</span>
                          </v-col>
                          <v-col
                            cols="12"
                            md="5"
                            style="text-align: left; padding: 5px; margin-left: 0px; margin-top: 3px;">
                            <v-tooltip left nudge-right="5" nudge-top="20">
                              <template #activator="{on, attrs}">
                                <div multiple v-on="on" style="width: 90%">
                                  <v-select
                                    v-bind="attrs"
                                    dense
                                    :items="formInicial.listRegFiscalUsuario"
                                    v-model="formInicial.formaRegimenSeleccionado"
                                    :error-messages="regFiscalEmisorError"
                                    item-text="descripcionRegimen"
                                    item-value="idSat"
                                    label="Régimen fiscal emisor"
                                    solo
                                    @change="actualizaRegimenEmisor"
                                  ></v-select>
                                </div>
                              </template>
                              <span>Régimen fiscal<br>Emisor</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-btn
                              color="#0065BB"
                              outlined
                              x-small
                              style="
                                margin-left: -25px;
                                width: 150px;
                                height: 32px;
                                margin-top: 0px;
                              "
                              @click="dialogoAvanzado = true"
                            >
                              <v-icon style="margin-right: 8px; margin-left: -5px"
                                size = 20
                                >mdi-cog-outline</v-icon
                              >Configuración</v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  <!-- Card Datos Generales -->
                    <v-card style="margin-top: 3px" height="90">
                      <v-container>
                        <v-row style="margin-top: 5px">
                         <!-- forma de pago comboList-->
                          <v-col cols="12" md="5">
                            <v-tooltip top nudge-left=95>
                              <template #activator="{on, attrs}">
                                <div multiple v-on="on" 
                                  style="width: 90%; margin-left: 20px">
                                  <v-select
                                    v-bind="attrs"
                                    dense
                                    :items="catalogoFormaPago"
                                    v-model="formInicial.formaPagoSeleccionado"
                                    :error-messages="formaPagoError"
                                    item-text="descripcion"
                                    item-value="clave"
                                    label="Forma de pago"
                                    solo
                                    @change="actualizaformaPago"
                                    style="margin-top: 0px"
                                  ></v-select>
                                </div>
                              </template>
                              <span>Forma de pago</span>
                            </v-tooltip>
                          </v-col>
                         <!-- No de orden textField-->
                          <v-col cols="12" md="5">
                            <v-tooltip right nudge-top="10">
                              <template #activator="{on, attrs}">
                                <v-text-field
                                  v-on="on"
                                  v-bind="attrs"
                                  dense
                                  solo
                                  v-model="formInicial.noOrden"
                                  @keypress="caracteresValidos"
                                  :rules="ordenRules"
                                  maxlength="15"
                                  label="No. Orden / Referencia"
                                  required
                                  style="width: 90%; margin-left: -15px"
                                ></v-text-field>
                              </template>
                              <span>Número de orden</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="12" md="2"></v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <!-- Card Datos del cliente -->
                    <v-card max-height="263" color="#042E52" >
                      <v-toolbar dense color="#042E52" max-height = "35px" style="margin-top: 5px">
                        <v-toolbar-title
                          class="title mr-6 hidden-sm-and-down"
                          style="color: white; width: 80%; text-align: left; margin-bottom: 15px; 
                            margin-left: 5px; font-size: 90% !important"
                        >Datos del cliente</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          dark
                          icon
                          small                          
                          @click="showDatosCliente = !showDatosCliente"
                          style="margin-bottom: 11px; margin-right: 5px"
                          >
                          <v-icon>{{ showDatosCliente ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-expand-transition>
                        <div v-show="showDatosCliente">
                          <v-card style="margin-left: 1px; margin-right: 1px;" height="225">
                            <v-container>
                              <!-- RFC cliente combobox-->
                              <v-row style="margin-top: 5px">
                                <v-col cols="12" md="5">
                                  <v-tooltip left nudge-right=5 nudge-top=10>
                                    <template #activator="{on, attrs}">
                                      <div multiple v-on="on" 
                                        style="width: 90%; margin-left: 20px">
                                        <v-combobox
                                          v-bind="attrs"
                                          solo
                                          dense
                                          v-model="formInicial.rfcSeleccionado"
                                          @change="actualizaRfc"
                                          @keypress="validaRFCCaracteres"
                                          @keyup="setMayusculas"
                                          :items="formInicial.rfcClientesList"
                                          :search-input.sync="formInicial.searchRFC"
                                          :error-messages="rfcListError"
                                          :disabled="formInicial.disabled"
                                          :rules="rfcRules"
                                          maxlength="13"
                                          label="Escribe el RFC receptor"
                                          style="margin-top: 3px"
                                          :menu-props= "{maxHeight: 140, nudgeBottom: 1}"
                                          prepend-inner-icon= "mdi-magnify"                              
                                        >
                                        </v-combobox>
                                      </div>
                                    </template>
                                      <span>RFC Receptor</span>
                                  </v-tooltip>
                                </v-col>
                                <v-col cols="12" md="5">
                                  <v-tooltip top nudge-left="110">
                                    <template #activator="{on, attrs}">
                                      <v-text-field
                                        v-on="on"
                                        v-bind="attrs"
                                        dense
                                        solo
                                        v-model="formInicial.razonSocial"
                                        maxlength="120"
                                        @keyup="setMayusculasRazon"
                                        @keypress="caracteresValidosEnie"
                                        :rules="razonSocialRules"
                                        :disabled="formInicial.disabled"
                                        style="font-size: smaller;
                                          margin-top: 3px;
                                          margin-left: -15px;
                                          width: 90%"
                                        label="Razón social"
                                        required
                                      ></v-text-field>
                                    </template>
                                    <span>Razón social</span>
                                  </v-tooltip>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-tooltip bottom>
                                    <template #activator="{on, attrs}">
                                      <v-btn
                                        v-on="on"
                                        v-bind="attrs"
                                        @click="publicoEnGeneral"
                                        dense
                                        outlined
                                        x-small
                                        :color="colorPublicoEnGeneral"
                                        :disabled="disabledPublicoEnGeneral"
                                        :loading="disabledPublicoEnGeneral"
                                        style="
                                          margin-left: -44px;
                                          width: 150px;
                                          height: 32px;
                                          margin-top: 5px"
                                      >Público en general</v-btn>
                                    </template>
                                    <span>Crea una factura <br>al Público en general</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                              <v-row style="margin-top: -20px">
                              <!-- Régimen fiscal receptor comboList-->
                                <v-col cols="12" md="5">
                                  <v-tooltip left nudge-right="5" nudge-top="20">
                                    <template #activator="{on, attrs}">
                                      <div multiple v-on="on" 
                                        style="width: 90%; margin-left: 20px">
                                        <v-select
                                          v-bind="attrs"
                                          dense
                                          :items="catalogoSatRegimenFiscales"
                                          :error-messages="regFiscalReceptorError"
                                          :disabled="formInicial.disabled"
                                          v-model="formInicial.regimenClienteSeleccionado"
                                          item-text="descripcion"
                                          item-value="clave"
                                          label="Régimen fiscal receptor"
                                          solo
                                          @change="actualizaRegimenReceptor"
                                          :menu-props= "{maxHeight: 165}"
                                        ></v-select>
                                      </div>
                                    </template>
                                    <span>Régimen fiscal<br>Receptor</span>
                                  </v-tooltip>
                                </v-col>
                                <!-- Código postal textField-->
                                <v-col cols="12" md="5">
                                  <v-tooltip right nudge-left="3" nudge-top="12">
                                    <template #activator="{on, attrs}">
                                      <v-text-field
                                        v-on="on"
                                        v-bind="attrs"
                                        dense
                                        solo
                                        v-model="formInicial.codigoPostal"
                                        @keypress="caracteresNumerico"
                                        :rules="codigoPostalRules"
                                        :disabled="formInicial.disabled"
                                        maxlength="5"
                                        label="Código postal del cliente"
                                        required
                                        style="width: 90%;
                                          margin-left: -15px;
                                        "
                                      ></v-text-field>
                                    </template>
                                    <span>Código postal</span>
                                  </v-tooltip>
                                </v-col>
                                <v-col cols="12" md="2"></v-col>
                              </v-row>
                              <v-row style="margin-top: -20px">
                                <!-- Correo electronico textField -->
                                <v-col cols="12" md="5">
                                  <v-tooltip left nudge-right="5" nudge-top="20">
                                    <template #activator="{on, attrs}">
                                      <v-text-field
                                        v-on="on"
                                        v-bind="attrs"
                                        dense
                                        solo
                                        v-model="formInicial.email"
                                        :rules="emailRules"
                                        maxlength="50"
                                        label="Correo Electrónico Receptor"
                                        required
                                        style="margin-top: 0px;
                                          margin-left: 20px;
                                          width: 90%;
                                        "
                                      ></v-text-field>
                                    </template>
                                    <span>Correo<br>electrónico</span>
                                  </v-tooltip>
                                </v-col>
                                <!-- add checkbox pendiente-->
                                <v-col cols="12" md="3">
                                  <v-tooltip bottom nudge-top="20">
                                    <template #activator="{on, attrs}">
                                      <div multiple v-on="on"
                                        style="margin-left: -10px; width: 95%">
                                        <v-checkbox
                                          v-bind="attrs"
                                          v-model="checkGuardarCliente"
                                          :disabled="formInicial.disabledClienteFrecuente"
                                          @change="checkedGuardarCliente"
                                          dense
                                          label="¿Es cliente frecuente?"
                                          color="#0065BB"
                                        ></v-checkbox>
                                      </div>
                                      </template>
                                    <span>Activa esta opción para<br>guardar el cliente</span>
                                  </v-tooltip>
                                </v-col>
                                <v-col cols="12" md="1">
                                  <v-tooltip right>
                                    <template #activator="{on, attrs}">
                                      <v-btn
                                        v-on="on"
                                        v-bind="attrs"
                                        color="red"
                                        small
                                        icon
                                        style="margin-left: 30px;
                                          margin-bottom: -10px;"
                                        @click="confirmaBorrarCliente"
                                        :disabled="formInicial.disabledBorrarCliente">
                                        <v-icon >mdi-delete-forever</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Borrar cliente</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </v-container>
                            <v-row></v-row>
                          </v-card>
                        </div>
                      </v-expand-transition>                     
                    </v-card>
                  </v-form>
                  <!-- Card conceptos -->
                  <v-card color="#042E52">
                    <v-toolbar dense color="#042E52" max-height = "35px" style="margin-top: 5px">
                      <v-toolbar-title
                        class="title mr-6 hidden-sm-and-down"
                        style="color: white; width: 80%; text-align: left; margin-bottom: 15px; 
                        margin-left: 5px; font-size: 90% !important"
                      >Conceptos</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        right
                        absolute
                        dark
                        text
                        x-small
                        style="margin-right: 45px;
                          margin-bottom: 10px;
                        "
                        @click="openMisConceptos"
                        :loading="emision.loadingBusqueda"
                        :disabled="emision.loadingBusqueda"
                      ><v-icon>mdi-plus</v-icon>
                        Agregar Concepto
                        <template v-slot:loader>
                          <span>Cargando...</span>
                        </template>
                      </v-btn>
                      <v-btn
                        dark
                        icon
                        small                          
                        @click="showConceptos = !showConceptos"
                        style="margin-bottom: 11px; margin-right: 5px"
                        >
                        <v-icon>{{ showConceptos ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-expand-transition>
                      <div v-show="showConceptos">
                        <v-card style="margin-left: 1px; margin-right: 1px" min-height="100">
                          <v-row style="margin-top: 5px">
                            <v-col cols="12" md="10" style="max-width: 680px">
                              <v-data-table
                                dense                          
                                no-data-text = "Agrega un concepto."
                                hide-default-footer
                                :items-per-page="porPagina"
                                :headers="headersPrincipal"
                                :items="conceptosFinales"
                                style="
                                  margin-top: 0px;
                                  margin-left: 5px;
                                  margin-right: 5px;
                                "
                              >
                                <template v-slot:[`item.cantidad`]="props">
                                  <v-edit-dialog
                                    :return-value.sync="props.item.cantidad"
                                    large
                                    cancel-text="Cancelar"
                                    save-text="Guardar"
                                    @cancel="cancel"
                                    @open="open"
                                    @close="close(props.item)"
                                  >
                                    <v-chip
                                      style="
                                        background: gainsboro;
                                        color: black;
                                        border-radius: 3px;
                                        font-size: 12px;
                                        height: 18px;
                                      "
                                      >{{ props.item.cantidad }}</v-chip
                                    >
                                    <template v-slot:input>
                                      <div class="mt-2 title">Actualiza cantidad</div>
                                      <v-text-field
                                        v-model="props.item.cantidad"
                                        @keypress="caracteresDecimalCantidad"
                                        :rules="numericRulesCantidad"
                                        label="Cantidad"
                                        single-line
                                        autofocus
                                      ></v-text-field>
                                      <!--
                                      <span style="font-size: 12px"
                                        >Presiona enter para continuar</span
                                      >
                                      -->
                                    </template>
                                  </v-edit-dialog>
                                </template>

                                <template v-slot:[`item.descuento`]="props">
                                  <v-edit-dialog
                                    :return-value.sync="props.item.descuento"
                                    @close="close(props.item)"
                                    large
                                    cancel-text="Cancelar"
                                    save-text="Guardar"
                                  >
                                    <v-chip
                                      style="
                                        background: gainsboro;
                                        color: black;
                                        border-radius: 3px;
                                        font-size: 12px;
                                        height: 18px;
                                      "
                                      >{{ props.item.descuento }}</v-chip
                                    >
                                    <template v-slot:input>
                                      <div class="mt-2 title">Actualiza descuento</div>
                                      <v-text-field
                                        v-model="props.item.descuento"
                                        @keypress="caracteresDecimal"
                                        :rules="numericRulesCantidad"
                                        label="Descuento"
                                        single-line
                                        autofocus
                                      ></v-text-field>
                                      <!--
                                      <span style="font-size: 12px"
                                        >Presiona enter para continuar</span
                                      >
                                      -->
                                    </template>
                                  </v-edit-dialog>
                                </template>
                                <template v-slot:[`item.valorUnitario`]="props">
                                  <span>${{ props.item.valorUnitario }}</span>
                                </template>
                                <template v-slot:[`item.importe`]="props">
                                  <span>${{ props.item.importe }}</span>
                                </template>

                                <template v-slot:[`item.accion`]="props">
                                  <v-row
                                    style="
                                      margin-top: -22px;
                                      width: 50px;
                                      text-align: right;
                                    "
                                  >
                                    <v-col cols="12">
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            v-on="on"
                                            v-bind="attrs"
                                            color="red"
                                            icon
                                            small
                                            style="margin-left: -5px; margin-top: 10px; width: 40px"
                                            @click="deleteConceptoFactura(props.item)"
                                          >
                                            <v-icon
                                              >mdi-delete-forever</v-icon
                                            >
                                          </v-btn>
                                        </template>
                                        <span>Borrar concepto</span>
                                      </v-tooltip>
                                    </v-col>
                                  </v-row>
                                </template>
                              </v-data-table>
                            </v-col>
                            <v-col cols="12" md="2" 
                              style="min-width: 230px; margin-left: -10px"
                              align-self="end">
                              <v-card min-height="0" color="#FAFAFA">
                                <!--Subtotal sin descuento-->
                                <v-row
                                  v-if="getDescuentoTotal != 0.0"
                                  dense
                                  style="height: 21px; font-size: smaller"
                                >
                                  <v-col cols="12" md="1" 
                                    style="min-width: 90px; margin-left: 5px">Sub sin Desc:</v-col>
                                  <v-col
                                    cols="12"
                                    md="2"
                                    style="text-align: right; min-width: 112px; margin-right: 3px"
                                    >${{
                                      (
                                        parseFloat(getSubtotalCFDI) +
                                        parseFloat(getDescuentoTotal)
                                      ).toFixed(2)
                                    }}</v-col
                                  >
                                </v-row>
                                <!--Descuento-->
                                <v-row
                                  v-if="getDescuentoTotal != 0.0"
                                  dense
                                  style="height: 21px; font-size: smaller"
                                >
                                  <v-col  cols="12" md="1" 
                                    style="min-width: 80px; color: red; margin-left: 5px">Descuento:</v-col>
                                  <v-col
                                    cols="12"
                                    md="2"
                                    style="margin-right: 3px; 
                                      text-align: right; 
                                      min-width: 122px"
                                    >${{ getDescuentoTotal }}</v-col
                                  >
                                </v-row>
                                <!--Subtotal-->
                                <v-row
                                  v-if="conceptosFinales.length != 0"
                                  dense
                                  style="height: 21px; font-size: smaller"
                                  >
                                  <v-col cols="12" md="1" 
                                    style="min-width: 62px; margin-left: 5px">Subtotal:</v-col>
                                  <v-col
                                    cols="12"
                                    md="2"
                                    style="margin-left: 0px; 
                                      text-align: right; 
                                      min-width: 140px; 
                                      margin-right: 3px"
                                    >${{ getSubtotalCFDI }}</v-col
                                  >
                                </v-row>
                                <!--IVA trasladado-->
                                <v-row
                                  v-if="getIVATrasladado != 0.0"
                                  dense
                                  style="height: 21px; font-size: smaller"
                                >
                                  <v-col cols="12" md="1" 
                                    style="min-width: 70px; margin-left: 5px">IVA Tras.:</v-col>
                                  <v-col
                                    cols="12"
                                    md="2"
                                    style="margin-right: 3px; text-align: right; min-width: 132px"
                                    >${{ getIVATrasladado }}</v-col
                                  >
                                </v-row>
                                <!--IEPS-->
                                <v-row
                                  v-if="getIEPS != 0.0"
                                  dense
                                  style="height: 21px; font-size: smaller"
                                >                                
                                  <v-col cols="12" md="1" 
                                    style="min-width: 62px; margin-left: 5px">IEPS:</v-col>
                                  <v-col
                                    cols="12"
                                    md="2"
                                    style="margin-right: 3px; text-align: right; min-width: 140px"
                                    >${{ getIEPS }}</v-col
                                  >
                                </v-row>
                                <!--ISR Retenido-->
                                <v-row
                                  v-if="getISR != 0.0"
                                  dense
                                  style="height: 21px; font-size: smaller"
                                >
                                  <v-col cols="12" md="1" 
                                    style="min-width: 62px; margin-left: 5px">ISR Ret.:</v-col>
                                  <v-col
                                    cols="12"
                                    md="2"
                                    style="margin-right: 3px; text-align: right; min-width: 140px"
                                    >${{ getISR }}</v-col
                                  >
                                </v-row>
                                <!--IVA Retenido-->
                                <v-row
                                  v-if="getIvaRetenido != 0.0"
                                  dense
                                  style="height: 21px; font-size: smaller"
                                >
                                  <v-col cols="12" md="1" 
                                    style="min-width: 62px; margin-left: 5px">IVA Ret.:</v-col>
                                  <v-col
                                    cols="12"
                                    md="2"
                                    style="margin-right: 3px; text-align: right; min-width: 140px"
                                    >${{ getIvaRetenido }}</v-col
                                  >
                                </v-row>
                                <!--LOCAL ISH-->
                                <v-row
                                  v-if="getLocal != 0.0"
                                  dense
                                  style="height: 21px; font-size: smaller"
                                >                                
                                  <v-col cols="12" md="1" 
                                    style="min-width: 62px; margin-left: 5px">Local.:</v-col>
                                  <v-col
                                    cols="12"
                                    md="2"
                                    style="margin-right: 3px; text-align: right; min-width: 140px"
                                    >${{ getLocal }}</v-col
                                  >
                                </v-row>
                                <!--TOTAL-->
                                <v-row
                                  v-if="conceptosFinales.length != 0"
                                  dense
                                  style="height: 25px; font-size: smaller">
                                  <v-col cols="12" md="1" 
                                    style="font-weight: bold; 
                                      min-width: 50px;
                                      margin-left: 5px;
                                      margin-bottom: 4px">Total:</v-col>
                                  <v-col
                                    cols="12"
                                    md="2"
                                    style="
                                      margin-right: 3px;
                                      text-align: right;
                                      font-weight: bold;
                                      min-width: 152px;
                                      margin-bottom: 4px
                                    "
                                    >${{ getTotalCFDI }}</v-col
                                  >
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>  <!-- ----------------------------------------------- -->
                        </v-card>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#0065BB"
                  outlined
                  small
                  style="margin-left: 13px; margin-top: 10px"
                  @click="clearForm"
                >
                  <v-icon size = 21 style="margin-right: 5px"
                    >mdi-close-circle</v-icon
                  >Cancelar
                </v-btn>
                <v-btn
                  color="success"
                  outlined
                  @click="confirmarCFDI"
                  :loading="loadingCFDI"
                  :disabled="loadingCFDI"
                  small
                  style="margin-left: 13px; margin-top: 10px"
                >
                  <v-icon size = 21 style="margin-right: 5px">mdi-send</v-icon
                  >&nbsp;Generar CFDI
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-container>
            <!-- ------------------------------------------------------------ -->
          </v-card>
          <v-card
            v-else
            flat
            class="mx-auto"
            min-width="304"
            :height="alturaTabAvisos"
          >
            <v-card-text>
              <div style="margin-top: -2px; line-height: 1.2">
                Apreciable
                <span style="font-weight: bold">{{ getRazonSocial }}</span>
              </div>
              <p style="margin-top: 8px; line-height: 1.2">
                La opción de emitir CFDIs esta disponible solo para
                computadoras.
              </p>
              <p style="margin-top: 8px; line-height: 1.2"></p>
              <p
                style="margin-top: -8px; font-weight: bold; font-size: smaller"
              >
                Gracias por su comprensión
              </p>

              <p style="margin-top: -5px; font-size: smaller; line-height: 1.1">
                Si tienes dudas, puede contactarnos:
              </p>
              <div style="margin-top: 5px; line-height: 1.2">
                Teléfonos de contacto:
              </div>
              <p style="margin-top: 8px; line-height: 0.5">
                &nbsp;&nbsp;55-8952-2909
              </p>
              <p style="line-height: 0.5">&nbsp;&nbsp;55-8952-2904</p>
              <p style="line-height: 0.5">&nbsp;&nbsp;55-7575-9662</p>
              <p style="margin-top: 38px; line-height: 1.2">Whatsapp</p>
              <p
                style="margin-top: -13px; font-weight: bold; font-size: smaller"
              >
                &nbsp;
                <a
                  href="https://api.whatsapp.com/send?phone=525568845587"
                  target="_blank"
                  >55 6884 5587</a
                >
              </p>
              <p style="margin-top: 28px; font-size: smaller">
                Correo Electrónico
              </p>
              <p style="margin-top: -20px; font-size: smaller">
                &nbsp;&nbsp;contacto@utaxme.com
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Cancel CFDI -->
        <v-tab-item value="tab-4" v-if="this.$store.state.dashboard.isCSD">
          <v-card
            v-if="!this.$store.state.mobile"
            flat
            class="mx-auto"
            min-width="304"
          >
            <!-- radio buttons de seleccion de criterio de busqueda -->
            <v-toolbar color="white" style="margin-bottom: 10px; height: 56px">
              <v-row style="width: 100%; height: 55px">
                <v-spacer></v-spacer>
                <v-col cols="2"></v-col>
                <v-col cols="8" style="text-align: left">
                  <input
                    style="margin-left: 50px; margin-top: 2px"
                    type="radio"
                    value="O"
                    v-model="busquedaPor"
                    @change="radioCambioO"
                  />
                  <span
                    style="
                      margin-left: 17px;
                      font-weight: bold;
                      width: 100%;
                      position: absolute;
                      margin-top: 4px;
                    "
                    >Búsqueda por No. de orden</span
                  >

                  <input
                    style="margin-left: 223px; margin-top: 2px"
                    type="radio"
                    value="F"
                    v-model="busquedaPor"
                    @change="radioCambioF"
                  />
                  <span
                    style="
                      margin-left: 17px;
                      font-weight: bold;
                      width: 100%;
                      position: absolute;
                      margin-top: 4px;
                    "
                    >Búsqueda por fecha</span
                  >

                  <!-- <v-radio-group  v-model="busquedaPor" row @change="radioCambio">
                                    <v-radio  label="Búsqueda por no. de orden" value="orden"></v-radio>
                                    <v-radio  label="Búsqueda por fecha" value="fecha"></v-radio>
                                </v-radio-group> -->
                </v-col>

                <v-spacer></v-spacer>
              </v-row>
            </v-toolbar>
            <!-- ---------------------------------------------------- -->
            <v-container fluid>
              <!-- por no de orden -->
              <v-row
                v-if="busquedaPor == 'O'"
                style="width: 100%; height: 85px"
                dense
              >
                <v-col cols="3"></v-col>
                <v-col cols="5" style="text-align: right">
                  <v-text-field
                    dense
                    solo
                    v-model="formCancel.filtroOrden"
                    @keypress="caracteresValidos"
                    label="Buscar por No. de orden"
                    style="width: 223px; margin-top: -3px"
                    @keyup.enter.native="findCFDIs"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" style="text-align: left">
                  <v-btn
                    class="mx-2"
                    color="primary"
                    @click="findCFDIs"
                    :loading="formCancel.loadingBusqueda"
                    :disabled="formCancel.loadingBusqueda"
                    style="margin-left: -70px !important"
                  >
                    <v-icon dark>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2"></v-col>
              </v-row>

              <!-- por Rango de fechas -->
              <v-row
                v-if="busquedaPor == 'F'"
                style="width: 100%; height: 85px"
                dense
              >
                <v-col cols="12">
                  <v-card height="105">
                    <v-form
                      ref="formFechasCancel"
                      style="width: 95%; margin-left: 20px; margin-top: -10px"
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="1">
                            <span
                              style="
                                margin-left: 0px;
                                font-weight: bold;
                                width: 100%;
                                position: absolute;
                                margin-top: 10px;
                              "
                              >Desde:</span
                            >
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-menu
                              v-model="fechaIni"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="computedDateFormattedIni"
                                  label="Desde:"
                                  prepend-icon="event"
                                  readonly
                                  solo
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dateFechaIni"
                                locale="es"
                                :min="periodoMinimo"
                                :max="periodoMaximo"
                                @input="fechaIni = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="1">
                            <span
                              style="
                                margin-left: 0px;
                                font-weight: bold;
                                width: 100%;
                                position: absolute;
                                margin-top: 10px;
                              "
                              >Hasta:</span
                            >
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-menu
                              v-model="fechaFin"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  solo
                                  dense
                                  v-model="computedDateFormattedFin"
                                  label="Hasta:"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="dateFechaFin"
                                locale="es"
                                :min="periodoMinimo"
                                :max="periodoMaximo"
                                @input="fechaFin = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="2" style="text-align: right">
                            <v-btn
                              class="mx-2"
                              color="primary"
                              @click="findCFDIs"
                              :loading="formCancel.loadingBusqueda"
                              :disabled="formCancel.loadingBusqueda"
                              style=""
                            >
                              <v-icon dark>mdi-magnify</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card>
                </v-col>
              </v-row>

              <!-- tabla resultados para cancelar -->
              <v-row dense>
                <v-col cols="12">
                  <v-card>
                    <!-- ------------------------------------------------ -->
                    <div>
                      <v-data-table
                        dense
                        height="100%"
                        :items-per-page="porPaginaCancel"
                        :headers="headersCFDIs"
                        :items="arrCFDIsCancel"
                        style="
                          margin-left: 20px;
                          margin-right: 20px;
                          height: 300px!inmportant;
                        "
                        :footer-props="{
                          showFirstLastPage: true,
                          'items-per-page-text': 'CFDIs por página',
                        }"
                      >
                        <template v-slot:[`item.cancela`]="props">
                          <v-row
                            style="
                              margin-top: -22px;
                              width: 50px;
                              text-align: right;
                            "
                          >
                            <v-col cols="12">
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <!--
                                  <v-btn
                                    v-on="on"
                                    v-bind="attrs"
                                    color="white"
                                    x-small
                                    style="margin-top: 20px; width: 40px"
                                    @click="confirmaCancelarCFDI(props.item)"
                                  >                                  
                                  -->

                                  <v-btn
                                    v-on="on"
                                    v-bind="attrs"
                                    color="white"
                                    x-small
                                    style="margin-top: 20px; width: 40px"
                                    @click="selectCatCancelarCFDI(props.item)"
                                  >
                                    <v-icon
                                      style="margin-right: 5px"
                                      color="red"
                                      >mdi-delete-forever</v-icon
                                    >
                                  </v-btn>
                                </template>
                                <span>Cancelar CFDI</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-table>
                    </div>
                    <v-divider></v-divider>
                    <!-- ----------------------------------------------- -->
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <!-- ------------------------------------------------------------ -->
          </v-card>
          <v-card
            v-else
            flat
            class="mx-auto"
            min-width="304"
            :height="alturaTabAvisos"
          >
            <v-card-text>
              <div style="margin-top: -2px; line-height: 1.2">
                Apreciable
                <span style="font-weight: bold">{{ getRazonSocial }}</span>
              </div>
              <p style="margin-top: 8px; line-height: 1.2">
                La opción de cancelar CFDIs esta disponible solo para
                computadoras.
              </p>
              <p style="margin-top: 8px; line-height: 1.2"></p>
              <p
                style="margin-top: -8px; font-weight: bold; font-size: smaller"
              >
                Gracias por su comprensión
              </p>

              <p style="margin-top: -5px; font-size: smaller; line-height: 1.1">
                Si tienes dudas, puede contactarnos:
              </p>
              <div style="margin-top: 5px; line-height: 1.2">
                Teléfonos de contacto:
              </div>
              <p style="margin-top: 8px; line-height: 0.5">
                &nbsp;&nbsp;55-8952-2909
              </p>
              <p style="line-height: 0.5">&nbsp;&nbsp;55-8952-2904</p>
              <p style="line-height: 0.5">&nbsp;&nbsp;55-7575-9662</p>
              <p style="margin-top: 38px; line-height: 1.2">Whatsapp</p>
              <p
                style="margin-top: -13px; font-weight: bold; font-size: smaller"
              >
                &nbsp;
                <a
                  href="https://api.whatsapp.com/send?phone=525568845587"
                  target="_blank"
                  >55 6884 5587</a
                >
              </p>
              <p style="margin-top: 28px; font-size: smaller">
                Correo Electrónico
              </p>
              <p style="margin-top: -20px; font-size: smaller">
                &nbsp;&nbsp;contacto@utaxme.com
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Ayuda -->
        <v-tab-item value="tab-5">
          <v-card
            flat
            class="mx-auto"
            min-width="304"
            :height="alturaTabAvisos"
          >
            <v-card-text>
              <div style="margin-top: 5px; line-height: 1.2; font-size: small">
                Teléfonos de contacto:
              </div>
              <p style="margin-top: 8px; line-height: 0.5">
                &nbsp;&nbsp;55-8952-2909
              </p>
              <p style="line-height: 0.5">&nbsp;&nbsp;55-8952-2904</p>
              <p style="line-height: 0.5">&nbsp;&nbsp;55-7575-9662</p>
              <p style="margin-top: 38px; line-height: 1.2; font-size: small">
                Whatsapp
              </p>
              <p style="margin-top: -13px; font-weight: bold; font-size: small">
                &nbsp;
                <a
                  href="https://api.whatsapp.com/send?phone=525568845587"
                  target="_blank"
                  >55 6884 5587</a
                >
              </p>
              <p style="margin-top: 28px; font-size: small">
                Correo Electrónico
              </p>
              <p style="margin-top: -20px; font-size: small">
                &nbsp;&nbsp;contacto@utaxme.com
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        :vertical="vertical"
        color="error"
        :timeout="timeoutMsg"
        style="text-align: center; font-size: 20px !important"
      >
        {{ textError }}
        <v-btn color="indigo" text @click="snackbar = false">Cerrar</v-btn>
      </v-snackbar>
    </div>
    <v-dialog v-model="dialogoConceptos" persistent max-width="880px">
      <v-card>
        <v-toolbar dense color="#042E52">
          <v-toolbar-title
            class="title mr-6 hidden-sm-and-down"
            style="color: white; width: 100%; text-align: center"
            >Conceptos</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text></v-card-text>
        <!-- -----------------------tabla conceptos----hide-default-footer-----fixed-header----------------------- -->
        <v-data-table
          dense
          :headers="headersx"
          height="350"
          :items-per-page="porPagina"
          :items="conceptosx"
          hide-default-footer
          style="margin-left: 15px; margin-right: 10px"
          :search="searchConcepto"
        >
          <!-- Change buscador de conceptos -->
          <template v-slot:top>
            <v-text-field
              v-model="searchConcepto"
              label="BUSCAR CONCEPTO"
              style="width: 50%; margin-bottom: -10px;"
              autofocus
              dense
              solo
              append-icon="mdi-magnify">
            </v-text-field>
          </template>

          <template v-slot:[`item.cantidad`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.cantidad"
              large
              @cancel="cancel"
              @open="open"
              @close="close(props.item)"
            >
              <v-chip
                style="background: gainsboro; color: black; border-radius: 7px"
                >{{ props.item.cantidad }}</v-chip
              >
              <template v-slot:input>
                <div class="mt-4 title">Actualiza cantidad</div>
                <v-text-field
                  v-model="props.item.cantidad"
                  @keypress="caracteresDecimal"
                  :rules="numericRules"
                  label="Cantidad"
                  single-line
                  counter
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:[`item.descuento`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.descuento"
              @close="close(props.item)"
            >
              <!-- {{ props.item.descuento }} -->
              <v-chip
                style="background: gainsboro; color: black; border-radius: 7px"
                >{{ props.item.descuento }}</v-chip
              >
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.descuento"
                  @keypress="caracteresDecimal"
                  :rules="decimalRules"
                  label="Descuento"
                  single-line
                  counter
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.accion`]="props" style="">
            <v-row dense style="margin-top: -22px">
              <v-col cols="4">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      color="#0065BB"
                      icon
                      small
                      style="                        
                        margin-top: 20px;
                        width: 35px; !important
                        margin-right: -30px;
                      "
                      @click="editarConceptoFactura(props.item)"
                    >
                      <v-icon style="margin-right: 5px">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar concepto</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      color="success"
                      small
                      icon
                      style="
                        margin-top: 20px;
                        width: 35px; !important
                        margin-right: -5px;
                      "
                      @click="agregarConceptoFactura(props.item)"
                    >
                      <v-icon style="margin-right: 0px">mdi-layers-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar concepto</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      color="red"
                      small
                      icon
                      style="margin-top: 20px; width: 40px"
                      @click="confirmaBorrarConcepto(props.item)"
                    >
                      <v-icon color="red" style="margin-right: 5px"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Eliminar concepto</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <!-- ------------------------------------------------------- -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#0065BB"
            text
            small
            style="margin-top: 20px; margin-right: 11px"
            @click="dialogoConceptos = false, searchConcepto = null"
          >
            <v-icon style="margin-right: 5px">mdi-arrow-left-circle-outline</v-icon>Regresar
          </v-btn>
          <v-btn
            color="success"
            outlined
            small
            style="margin-top: 20px"
            @click="nuevoConcepto"
          >
            <v-icon style="margin-right: 5px">mdi-plus-box</v-icon>Nuevo concepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogoConcepto"
      persistent
      max-width="600px"
      style="max-height: 96%;!important"
    >
      <v-card style="overflow: hidden">
        <!-- -----------------------searcher----------------------------- -->
        <v-toolbar color="#042E52">
          <v-toolbar-title
            class="title mr-6 hidden-sm-and-down"
            style="color: white; width: 100%; text-align: center"
            >Concepto</v-toolbar-title
          >
        </v-toolbar>
        <!-- ------------------------------------------------------------ -->
        <v-form
          v-model="formConcepto.formValid"
          ref="formConcepto"
          style="width: 100%; margin-left: 20px; overflow: hidden !important"
        >
          <v-container>
            <v-row v-if="!formConcepto.isEdit" style="margin-top: -6px">
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  solo
                  v-model="formConcepto.filtro"
                  @keypress="caracteresValidos"
                  label="Filtro conceptos SAT"
                  style="width: 200px"
                  @keyup.enter.native="findConceptos"
                ></v-text-field>
                <v-btn
                  class="mx-2"
                  fab
                  x-small
                  color="#0065BB"
                  outlined
                  @click="findConceptos"
                  :loading="formConcepto.loadingBusqueda"
                  :disabled="formConcepto.loadingBusqueda"
                  style="
                    margin-top: -60px;
                    margin-left: 211px !important;
                    position: absolute;
                  "
                >
                  <v-icon style="margin-left: 2px">mdi-magnify</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  id="cboConceptos"
                  dense
                  v-model="formConcepto.conceptoSeleccionado"
                  :items="formConcepto.arrConceptos"
                  @change="selectConcepto"
                  item-text="descripcion"
                  item-value="identificador"
                  label="Conceptos"
                  solo
                  style="margin-top: 0px; margin-left: -50px"
                ></v-select>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-row style="margin-top: 10px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 90%;
                  "
                  >*Nombre producto/servicio:</span
                >
              </v-col>

              <v-col cols="12" md="7">
                <v-text-field
                  dense
                  solo
                  v-model="formConcepto.productoServicio"
                  @keypress="caracteresValidos"
                  maxlength="50"
                  :rules="requeridoRules"
                  label="Producto/servicio"
                  required
                ></v-text-field>
                <!-- <v-select dense :items="catalogoFormaPago" v-model="formConcepto.productoServicio" item-text="descripcion" item-value="clave" label="Tarjeta de debito" solo style="margin-top:0px"></v-select> -->
              </v-col>

              <v-col cols="12" md="1"> </v-col>
            </v-row>

            <v-row style="margin-top: -35px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 90%;
                  "
                  >*Clave producto/servicio:</span
                >
              </v-col>

              <v-col cols="12" md="7">
                <v-text-field
                  dense
                  solo
                  v-model="formConcepto.identificador"
                  @keypress="caracteresValidos"
                  maxlength="20"
                  :rules="requeridoRules"
                  label="Capture su no. de identificación"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="1"> </v-col>
            </v-row>

            <v-row style="margin-top: -35px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 90%;
                  "
                  >*Descripción:</span
                >
              </v-col>

              <v-col cols="12" md="7">
                <v-textarea
                  solo
                  v-model="formConcepto.descripcion"
                  @keypress="caracteresValidosEnie"
                  maxlength="500"
                  :rules="requeridoRules"
                  rows="2"
                  name="input-7-4"
                  label="Descripción"
                  no-resize
                ></v-textarea>
              </v-col>

              <v-col cols="12" md="1"> </v-col>
            </v-row>

            <v-row style="margin-top: -55px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 20px;
                    font-size: 90%;
                  "
                  >*Unidad:</span
                >
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  dense
                  solo
                  v-model="formConcepto.unidad"
                  @keypress="caracteresValidos"
                  maxlength="20"
                  :rules="requeridoRules"
                  label="Unidad"
                  required
                  style="margin-top: 20px; text-transform: uppercase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>

            <v-row style="margin-top: -45px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 20px;
                    font-size:90%;
                  "
                  >*Clave unidad:</span
                >
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  dense
                  solo
                  v-model="formConcepto.claveUnidad"
                  @keypress="caracteresValidos"
                  maxlength="5"
                  :rules="requeridoRules"
                  label="Clave unidad"
                  required
                  style="margin-top: 20px; text-transform: uppercase"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>

            <v-row style="margin-top: -35px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size:90%;
                  "
                  >*Valor unitario:</span
                >
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  dense
                  solo
                  v-model="formConcepto.unitario"
                  @keypress="caracteresMoneda"
                  @focus="cleanText"
                  @blur="defaultText"
                  maxlength="20"
                  :rules="tasaRulesLocal"
                  label="Valor unitario"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- Impuesto trasladado -->
            <v-row style="margin-top: 0px">
              <v-col cols="12" md="4" style="margin-top: 0px">
                
                <input
                  style="margin-left: 0px; margin-top: 2px; zoom: 145%"
                  type="checkbox"
                  id="checkboxTrasladado"
                  v-model="formConcepto.checkImpuestoTrasladado"
                  @change="checkedImpuestoTrasladado"
                />
                <span
                  style="
                    margin-left: 10px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 1px;
                  "
                  >Impuesto trasladado:</span
                >
              </v-col>
              <v-col cols="12" md="7"  style="margin-left: 30px">
                <v-row
                  v-if="formConcepto.hayImpuestoTrasladadoSeleccionado"
                  style="margin-top: -10px"
                >
                  <v-col cols="12" md="2">
                    <span
                      style="
                        margin-left: 0px;
                        font-weight: bold;
                        width: 100%;
                        position: absolute;
                        margin-top: 10px;
                      "
                      >IVA:</span
                    >
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      dense
                      solo
                      v-model="formConcepto.ivaTrasladado"
                      @keypress="caracteresSinDecimal"
                      @blur="defaultTextTasaIvaTrasladado"
                      :rules="tasaRulesIvaTrasladado"
                      maxlength="2"
                      label="Tasa"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row
                  v-if="formConcepto.hayImpuestoTrasladadoSeleccionado"
                  style="margin-top: -30px"
                >
                  <v-col cols="12" md="2">
                    
                    <span
                      style="
                        margin-left: 0px;
                        font-weight: bold;
                        width: 100%;
                        position: absolute;
                        margin-top: 10px;
                      "
                      >IEPS:</span
                    >
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      dense
                      solo
                      v-model="formConcepto.iepsTrasladado"
                      @keypress="caracteresTasaLocal"
                      @blur="defaultTextTasaIepsTrasladado"
                      maxlength="10"
                      label="Tasa"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <!-- Impuesto retenido -->
            <v-row style="margin-top: 0px">
              <v-col cols="12" md="4" style="margin-top: -20px">
                <input
                  style="margin-left: 0px; margin-top: 2px; zoom: 145%"
                  type="checkbox"
                  id="checkboxTrasladado"
                  v-model="formConcepto.checkImpuestoRetenido"
                  @change="checkedImpuestoRetenido"
                />
                <span
                  style="
                    margin-left: 10px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 1px;
                  "
                  >Impuesto retenido:</span
                >
              </v-col>
              
              <v-col cols="12" md="7" style="margin-left:30px; margin-top: -20px">
                <v-row
                  v-if="formConcepto.hayImpuestoRetenidoSeleccionado"
                  style="margin-top: -10px"
                >
                  <v-col cols="12" md="2">
                    <span
                      style="
                        margin-left: 0px;
                        font-weight: bold;
                        width: 100%;
                        position: absolute;
                        margin-top: 10px;
                      "
                      >ISR:</span
                    >
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      dense
                      solo
                      v-model="formConcepto.isrRetenido"
                      @keypress="caracteresTasaLocal"
                      @blur="defaultTextIsrRetenido"
                      maxlength="10"
                      label="Tasa"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row
                  v-if="formConcepto.hayImpuestoRetenidoSeleccionado"
                  style="margin-top: -30px"
                >
                  <v-col cols="12" md="2">
                    <span
                      style="
                        margin-left: 0px;
                        font-weight: bold;
                        width: 100%;
                        position: absolute;
                        margin-top: 10px;
                      "
                      >IVA:</span
                    >
                  </v-col>
                  <v-col cols="12" md="9">
                    <!-- <v-text-field dense solo v-model="formConcepto.ivaRetenido" @keypress="caracteresTasaLocal" @focus="cleanTextTasaLocal" @blur="defaultTextTasaLocal" :rules="tasaRulesLocal" maxlength=10 label="Tasa" required></v-text-field> -->
                    <v-text-field
                      dense
                      solo
                      v-model="formConcepto.ivaRetenido"
                      @keypress="caracteresTasaLocal"
                      @blur="defaultTextTasaIvaRetenido"
                      :rules="tasaRulesIvaRetenido"
                      maxlength="10"
                      label="Tasa"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>

            <v-row style="margin-top: -25px">
              <v-col cols="12" md="4">
                <!-- <span style="margin-left:0px;font-weight:bold;width:100%;position: absolute;margin-top:10px">Impuesto local:</span> -->
                <input
                  style="margin-left: 0px; margin-top: 2px; zoom: 145%"
                  type="checkbox"
                  id="checkbox2"
                  v-model="formConcepto.checkImpuestoLocal"
                  @change="checkedImpuestoLocal"
                />
                <span
                  style="
                    margin-left: 10px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 1px;
                  "
                  >Impuesto local:</span
                >
              </v-col>
              <v-col cols="12" md="7">
                <v-select
                  v-if="formConcepto.hayImpuestoLocal"
                  dense
                  :items="catalogoImpuestosLocales"
                  v-model="formConcepto.impuestoLocalSeleccionado"
                  @change="selectImpuestoLocal"
                  item-text="descripcion"
                  item-value="clave"
                  label="Impuestos"
                  solo
                  style="margin-top: 0px"
                ></v-select>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>

            <v-row
              v-if="formConcepto.hayImpuestoLocalSeleccionado"
              style="margin-top: -35px; height: 57px; margin-bottom: 20px"
            >
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                  "
                  >*Tasa local:</span
                >
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  dense
                  solo
                  v-model="formConcepto.tasaLocal"
                  @keypress="caracteresTasaLocal"
                  @focus="cleanTextTasaLocal"
                  @blur="defaultTextTasaLocal"
                  :rules="tasaRulesLocal"
                  maxlength="10"
                  label="Tasa"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>

            <v-divider></v-divider>
          </v-container>
        </v-form>
        <!-- ------------------------------------------------------ -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#0065BB"
            text
            small
            style="margin-left: 13px; margin-top: 10px"
            @click="cancelaConcepto"
          >
            <v-icon style="margin-right: 5px">mdi-arrow-left-circle-outline</v-icon>Regresar
          </v-btn>
          <v-btn
            :disabled="deshabilitadoGuardarConcepto"
            :loading="deshabilitadoGuardarConceptoLoading"
            color="success"
            outlined
            small
            style="margin-left: 0px; margin-top: 10px"
            @click="agregarConcepto"
          >
            <v-icon style="margin-right: 5px">mdi-send</v-icon>&nbsp;Guardar
          </v-btn>
          <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoAvanzado" persistent max-width="700px">
      <v-card style="overflow: hidden">
        <!-- -----------------------searcher----------------------------- -->
        <v-toolbar color="#042E52">
          <v-toolbar-title
            class="title mr-6 hidden-sm-and-down"
            style="color: white; width: 100%; margin-left: 10px"
            >Configuración avanzada</v-toolbar-title
          >
        </v-toolbar>
        <!-- ------------------------------------------------------------ -->
        <v-form
          v-model="formAvanzado.formValid"
          ref="formAvanzado"
          style="width: 100%; margin-left: 20px; overflow: hidden !important"
        >
          <v-container>
            <!--<v-divider style="margin-top: 10px"></v-divider> -->
            <v-row style="margin-top: 2px">
              <v-col cols="12" md="5">
                <span
                  style="
                    margin-left: 15px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    font-size: 90%;
                  "
                  >Método de pago:</span
                >
              </v-col>

              <v-col cols="12" md="1"> </v-col>

              <v-col cols="12" md="5">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    font-size: 90%;
                  "
                  >Moneda:</span
                >
              </v-col>

            </v-row>

            <v-row style="margin-top: 12px">

              <v-col cols="12" md="5">
                <v-select
                  dense
                  :items="catalogoMetodoPago"
                  v-model="formAvanzado.metodoPago"
                  @change="changeMetodoPago"
                  item-text="descripcion"
                  item-value="clave"
                  label="Método de pago"
                  solo
                  style="margin-left: 15px;
                    width: 100%;
                  "
                ></v-select>
              </v-col>

              <v-col cols="12" md="1"> </v-col>

              <v-col cols="12" md="5">
                <v-select
                  dense
                  :items="catalogoMoneda"
                  v-model="formAvanzado.moneda"
                  item-text="descripcion"
                  item-value="clave"
                  label="Moneda"
                  solo
                  style="margin-left: 0px"
                ></v-select>
              </v-col>
              
            </v-row>

            <v-row style="margin-top: 0px">
              <v-col cols="12" md="5">
                <span
                  style="
                    margin-left: 15px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: -10px;
                    font-size: 90%;
                  "
                  >Uso de CFDI:</span
                >
              </v-col>

              <v-col cols="12" md="1"> </v-col>

              <v-col cols="12" md="5">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: -15px;
                    font-size: 90%;
                  "
                  >Dias de crédito:</span
                >
              </v-col>
              
            </v-row>

            <v-row style="margin-top: -10px">

              <v-col cols="12" md="5">
                <v-select
                  dense
                  :items="catalogoUsoCfdi"
                  :disabled="formAvanzado.disabled"
                  v-model="formAvanzado.usoCFDI"
                  @change="changeUseCFDI"
                  item-text="descripcion"
                  item-value="clave"
                  label="Uso de CFDI"
                  solo
                  style="margin-top: 0px;
                    margin-left: 15px;
                    width: 100%;
                  "
                ></v-select>
              </v-col>

              <v-col cols="12" md="1"> </v-col>

              <v-col cols="12" md="5">
                <v-select
                  dense
                  :items="catalogoDiasCredito"
                  v-model="formAvanzado.diasCredito"
                  @change="changeDiasCredito"
                  item-text="descripcion"
                  item-value="clave"
                  label="Dias de crédito"
                  solo
                  style="margin-top: 0px"
                ></v-select>
              </v-col>
              
            </v-row>

            <v-row style="margin-top: 0px">
              <v-col cols="12" md="5">
                <span
                  style="
                    margin-left: 15px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: -15px;
                    font-size: 90%;
                  "
                  >Condiciones de pago:</span
                >
              </v-col>

              <v-col cols="12" md="1"> </v-col>

              <v-col cols="12" md="5">
                <span
                  v-if= "!formAvanzado.disabledPeriodicidad" 
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: -15px;
                    font-size: 90%;
                  "
                  >Periodicidad:</span
                >
              </v-col>
            </v-row>

            <v-row style="margin-top: -10px">
               <v-col cols="12" md="5">
                <v-text-field
                  dense
                  solo
                  v-model="formAvanzado.condiciones"
                  :rules="condicionesRules"
                  label="Condiciones de pago"
                  required
                  style="margin-left: 15px;
                    width: 100%;"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="1"> </v-col>
              <v-col cols="12" md="5">
                <v-select
                  v-if= "!formAvanzado.disabledPeriodicidad" 
                  dense
                  :items="catalogoPeriodicidad"
                  v-model="formAvanzado.periodicidad"
                  @change="changePeriodicidad"
                  item-text="descripcion"
                  item-value="clave"
                  label="Periodicidad"
                  solo
                  style="margin-top: 0px"
                ></v-select>
              </v-col>
            </v-row>
            <v-row style="margin-top: 0px" v-if= "!formAvanzado.disabledPeriodicidad" >
              <v-col cols="12" md="5">
                <span
                  style="
                    margin-left: 15px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: -15px;
                    font-size: 90%;
                  "
                  >Meses:</span
                >
              </v-col>
              <v-col cols="12" md="1"> </v-col>
              <v-col cols="12" md="5">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: -15px;
                    font-size: 90%;
                  "
                  >Año:</span
                >
              </v-col>
            </v-row>
            <v-row style="margin-top: -10px" v-if= "!formAvanzado.disabledPeriodicidad" >
               <v-col cols="12" md="5">
                <v-select
                  dense
                  :items="catalogoMeses"
                  v-model="formAvanzado.meses"
                  @change="changeMeses"
                  item-text="descripcion"
                  item-value="clave"
                  label="Meses"
                  solo
                  style="margin-top: 0px;
                    margin-left: 15px;
                    width: 100%"
                ></v-select>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  dense
                  solo
                  v-model="formAvanzado.anio"
                  @change="changeAnio"
                  @keypress="caracteresNumerico"
                  :rules="anioRules"
                  :error-messages="anioErrorMessages"
                  maxLength="4"
                  label="Año"
                  required
                  style="margin-top: 0px"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <!-- ------------------------------------------------------ -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#0065BB"
            outlined
            small
            style="margin-right: 27px;
              margin-bottom: 10px;
              margin-top: 0px;
            "
            @click="validateConfAvanzada"
          >
            <v-icon style="margin-right: 5px">mdi-arrow-left-circle-outline</v-icon
            >&nbsp;Regresar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoConfirmaCDFI" persistent max-width="400px">
      <v-card style="overflow: hidden">
        <!-- -----------------------searcher----------------------------- -->
        <v-toolbar color="#042E52" style="height: 40px">
          <v-toolbar-title
            class="title mr-6 hidden-sm-and-down"
            style="
              color: white;
              text-align: center;
              width: 100%;
              margin-top: -17px;
            "
            >GENERAR CFDI</v-toolbar-title
          >
        </v-toolbar>
        <!-- ------------------------------------------------------------ -->
        <v-form
          style="width: 100%; margin-left: 20px; overflow: hidden !important"
        >
          <v-container>
            <v-row style="margin-top: 45px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 93%;
                    position: absolute;
                    margin-top: -15px;
                    text-align: center;
                  "
                  >¿Está seguro que desea generar el CFDI?</span
                >
              </v-col>
              <v-col cols="12" md="6"> </v-col>
              <v-col cols="12" md="4"> </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-container>
        </v-form>
        <!-- ------------------------------------------------------ -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#0065BB"
            text
            @click="dialogoConfirmaCDFI = false"
            :loading="loadingCFDI"
            :disabled="loadingCFDI"
          >
            <v-icon style="margin-right: 5px">mdi-arrow-left-circle-outline</v-icon
            >&nbsp;Regresar
          </v-btn>
          <v-btn
            color="success"
            style=""
            @click="generarCFDI"
            :loading="loadingCFDI"
            :disabled="loadingCFDI"
          >
            <v-icon style="margin-right: 5px">mdi-checkbox-marked-circle</v-icon
            >&nbsp;Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoCDFIMensaje" persistent max-width="600px">
      <v-card style="overflow: hidden">
        <!-- -----------------------searcher----------------------------- -->
        <v-toolbar :color="this.colorSnack" style="height: 40px">
          <v-toolbar-title
            class="title mr-6 hidden-sm-and-down"
            style="
              color: white;
              text-align: center;
              width: 100%;
              margin-top: -17px;
            "
            >CFDI</v-toolbar-title
          >
        </v-toolbar>
        <!-- ------------------------------------------------------------ -->
        <v-form
          style="width: 100%; margin-left: 0px; overflow: hidden !important"
        >
          <v-container>
            <v-row style="margin-top: 10px; margin-bottom: 10px" >
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="10" align="center">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 93%;
                    margin-top: -15px;
                    text-align: center;
                  "
                  >{{this.mensajeSnack}}</span
                >
              </v-col>
               <v-col cols="12" md="1"></v-col>
            </v-row>
            <v-divider></v-divider>
          </v-container>
        </v-form>
        <!-- ------------------------------------------------------ -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="this.colorSnack"
            outlined
            @click="dialogoCDFIMensaje = false"
            :loading="loadingCFDI"
            :disabled="loadingCFDI"
          >
            <v-icon style="margin-right: 5px">mdi-checkbox-marked-circle</v-icon
            >&nbsp;Aceptar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogoConfirmaCancelarCDFI"
      persistent
      max-width="600px"
    >
      <v-card style="overflow: hidden">
        <!-- -----------------------searcher----------------------------- -->
        <v-toolbar color="primary" style="height: 40px">
          <v-toolbar-title
            class="title mr-6 hidden-sm-and-down"
            style="
              color: white;
              text-align: center;
              width: 100%;
              margin-top: -17px;
            "
            >¿Está seguro que desea cancelar el CFDI?
            <!--UUID: {{ cfdiACancelar.uidd }}-->
          </v-toolbar-title>
        </v-toolbar>
        <!-- ------------------------------------------------------------ -->
        <v-form
          style="width: 100%; margin-left: 20px; overflow: hidden !important"
        >
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 14px;
                  "
                  >Número de orden:</span
                >
              </v-col>
              <v-col cols="12" md="7">
                <span
                  style="
                    margin-left: -20px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 14px;
                  "
                  >{{ this.cfdiACancelar.referencia }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 14px;
                  "
                  >Motivo de cancelación:</span
                >
              </v-col>
              <v-col cols="12" md="7">
                <span
                  style="
                    margin-left: -20px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 14px;
                  "
                  >{{ this.cadMotivoCancelacionSeleccionado }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 14px;
                  "
                  >Total:</span
                >
              </v-col>
              <v-col cols="12" md="7">
                <span
                  style="
                    margin-left: -20px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 14px;
                  "
                  >$ {{ this.cfdiACancelar.total }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 14px;
                  "
                  >UIDD:</span
                >
              </v-col>
              <v-col cols="12" md="7">
                <span
                  style="
                    margin-left: -20px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                    font-size: 14px;
                  "
                  >{{ this.cfdiACancelar.uidd }}</span
                >
              </v-col>
            </v-row>

            <v-row v-if="!monto1000" style="margin-top: 45px"> </v-row>

            <v-row
              style="margin-top: 70px; margin-bottom: -30px"
              v-if="monto1000"
            >
              <v-col cols="12" md="4">
                <span
                  style="
                    width: 92%;
                    position: absolute;
                    margin-top: -47px;
                    font-size: 15px;
                  "
                  >El total de este CFDI es mayor a $1,000.00.</span
                >
                <span
                  style="
                    width: 92%;
                    position: absolute;
                    margin-top: -27px;
                    font-size: 15px;
                  "
                  >El proceso de cancelación puede tardar hasta 24 horas.</span
                >
              </v-col>
              <v-col cols="12" md="6"> </v-col>
              <v-col cols="12" md="4"> </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-container>
        </v-form>
        <!-- ------------------------------------------------------ -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            color="error"
            style=""
            @click="backCatalogCancel()"
            :loading="loadingCancelCFDI"
            :disabled="loadingCancelCFDI"
          >
            <v-icon style="margin-right: 5px">mdi-close-circle</v-icon
            >&nbsp;Regresar
          </v-btn>

          <v-btn
            class="mx-2"
            color="primary"
            style=""
            @click="cancelaCFDIReal()"
            :loading="loadingCancelCFDI"
            :disabled="loadingCancelCFDI"
          >
            <v-icon style="margin-right: 5px">mdi-checkbox-marked-circle</v-icon
            >&nbsp;Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogoBorrarCliente"
      persistent
      max-width="600px"
    >
      <v-card style="overflow: hidden">        
        <v-toolbar color="#042E52" style="height: 40px">
          <v-toolbar-title
            class="title mr-6 hidden-sm-and-down"
            style="
              color: white;
              text-align: center;
              width: 93%;
              margin-top: -25px;
            "
            >¿Desea eliminar a este cliente frecuente?</v-toolbar-title
          >
        </v-toolbar>
        <v-form
          style="width: 100%; margin-left: 20px; overflow: hidden !important"
        >
          <v-container>
            <v-row style="margin-top: 5px">
              <v-col cols="12" md="3">
                <span
                  style="
                    margin-left: 20px;
                    font-weight: bold;
                    width: 100%;                    
                    margin-top: 10px;
                  "
                  >RFC receptor:
                </span>
              </v-col>
              <v-col cols="12" md="8">
                 <span
                  style="
                    margin-left: 20px;
                    width: 100%;
                    margin-top: 10px;
                  "
                  >{{this.formInicial.rfcSeleccionado}}
                </span>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <v-row style="margin-top: 5px">
              <v-col cols="12" md="3">
                <span
                  style="
                    margin-left: 20px;
                    font-weight: bold;
                    width: 100%;                    
                    margin-top: 10px;
                  "
                  >Razón social:
                </span>
              </v-col>
              <v-col cols="12" md="8">
                 <span
                  style="
                    margin-left: 20px;
                    width: 100%;
                    margin-top: 10px;
                  "
                  >{{this.formInicial.razonSocial}}
                </span>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <v-row style="margin-top: 5px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 20px;
                    font-weight: bold;
                    width: 100%;                    
                    margin-top: 10px;
                  "
                  >Régimen fiscal:
                </span>
              </v-col>
              <v-col cols="12" md="8">
                 <span
                  style="
                    margin-left: -30px;
                    width: 100%;
                    margin-top: 10px;
                  "
                  >{{this.formInicial.regimenClienteSeleccionado.descripcion}}
                </span>
              </v-col>
            </v-row>
            <v-row style="margin-top: 5px">
              <v-col cols="12" md="3">
                <span
                  style="
                    margin-left: 20px;
                    font-weight: bold;
                    width: 100%;                    
                    margin-top: 10px;
                  "
                  >Email:
                </span>
              </v-col>
              <v-col cols="12" md="8">
                 <span
                  style="
                    margin-left: 20px;
                    width: 100%;
                    margin-top: 10px;
                  "
                  >{{this.formInicial.email}}
                </span>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <v-row style="margin-top: 5px">
              <v-col cols="12" md="3">
                <span
                  style="
                    margin-left: 20px;
                    font-weight: bold;
                    width: 100%;                    
                    margin-top: 10px;
                  "
                  >Código postal:
                </span>
              </v-col>
              <v-col cols="12" md="8">
                 <span
                  style="
                    margin-left: 20px;
                    width: 100%;
                    margin-top: 10px;                    
                  "
                  >{{this.formInicial.codigoPostal}}
                </span>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <v-row style="height: 20px"></v-row>
            <v-divider></v-divider>
          </v-container>
        </v-form>        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            color="#0065BB"
            text
            small
            style=""
            @click="dialogoBorrarCliente = false"
            :loading="loadingCancelCFDI"
            :disabled="loadingCancelCFDI"
          >
            <v-icon style="margin-right: 5px">mdi-arrow-left-circle-outline</v-icon
            >&nbsp;Regresar
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            outlined
            small
            style=""
            @click="deleteClientes()"
            :loading="formInicial.loadingBorrarCliente"
          >
            <v-icon style="margin-right: 5px">mdi-delete-forever</v-icon
            >&nbsp;Borrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogoConfirmaBorrarConcepto"
      persistent
      max-width="600px"
    >
      <v-card style="overflow: hidden">
        <!-- -----------------------searcher----------------------------- -->
        <v-toolbar color="#042E52" style="height: 40px">
          <v-toolbar-title
            class="title mr-6 hidden-sm-and-down"
            style="
              color: white;
              text-align: center;
              width: 93%;
              margin-top: -25px;
            "
            >¿Está seguro que desea borrar este concepto?</v-toolbar-title
          >
        </v-toolbar>
        <!-- ------------------------------------------------------------ -->
        <v-form
          style="width: 100%; margin-left: 20px; overflow: hidden !important"
        >
          <v-container>
            <v-row style="margin-top: 5px">
              <v-col cols="12" md="3">
                <span
                  style="
                    margin-left: 20px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                  "
                  >Descripción:
                </span>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea
                  solo
                  v-model="conceptoABorrar.descripcion"
                  rows="2"
                  name="Descripcion-concepto"
                  label="Descripcion-concepto"
                  readonly
                  style="margin-left: -20px"
                  no-resize
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="1"> </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-container>
        </v-form>
        <!-- ------------------------------------------------------ -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            color="#0065BB"
            text
            small
            @click="dialogoConfirmaBorrarConcepto = false"
            :loading="loadingCancelCFDI"
            :disabled="loadingCancelCFDI"
          >
            <v-icon style="margin-right: 5px">mdi-arrow-left-circle-outline</v-icon
            >&nbsp;Regresar
          </v-btn>
          <v-btn
            class="mx-2"
            color="error"
            outlined
            small
            @click="borrarConceptoFactura()"
            :loading="loadingCancelCFDI"
            :disabled="loadingCancelCFDI"
          >
            <v-icon style="margin-right: 5px">mdi-delete</v-icon
            >&nbsp;Borrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!----------- Catalogo de cancelacion ---------------->
    <v-dialog
      v-model="dialogoCatalogoCancelacion"
      persistent
      max-width="600px"
      style="max-height: 96%;!important"
    >
      <v-card style="overflow: hidden">
        <v-toolbar color="primary">
          <v-toolbar-title
            class="title mr-6 hidden-sm-and-down"
            style="color: white; width: 100%; text-align: center"
            >Cancelar CFDI</v-toolbar-title
          >
        </v-toolbar>
        <!-- ------------------------------------------------------------ -->
        <v-form
          v-model="formConcepto.formValid"
          ref="formConcepto"
          style="width: 100%; margin-left: 20px; overflow: hidden !important"
        >
          <v-container>
            <v-divider style="margin-top: -27px"></v-divider>
            <v-row style="margin-top: 20px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                  "
                  >Número de orden:</span
                >
              </v-col>

              <v-col cols="12" md="7">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                  "
                  >{{ this.cfdiACancelar.referencia }}</span
                >
              </v-col>
            </v-row>

            <v-row style="margin-top: 35px">
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 10px;
                  "
                  >Motivo de cancelación:</span
                >
              </v-col>

              <v-col cols="12" md="7">
                <v-select
                  dense
                  :items="this.catalogoCancelacion"
                  v-model="formCancelInicial.motivoCancelacionSeleccionado"
                  item-text="descripcion"
                  item-value="clave"
                  label="Seleccione el motivo de cancelación"
                  background-color="white"
                  solo
                  @change="validaCatalogoCancel"
                ></v-select>
              </v-col>
            </v-row>

            <v-row
              style="margin-top: -18px"
              v-if="this.formCancelInicial.motivoCancelacionSeleccionado == '01'"
            >
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 1px;
                  "
                  >Número de órden del<br />
                  nuevo CFDI:
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon style="margin-left: 10px" v-bind="attrs" v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span
                      >Debes indicar el No. de orden del<br />
                      CFDI que reemplazará al actual
                    </span>
                  </v-tooltip>
                </span>
              </v-col>

              <v-col cols="12" md="7">
                <v-select
                  dense
                  :items="this.arrCFDISReplaces"
                  v-model="formCancelInicial.ordenReplaceSeleccionado"
                  item-text="referencia"
                  item-value="idCfdi"
                  label="Seleccione un número de orden"
                  background-color="white"
                  solo
                  @change="setCfdiReplace()"
                ></v-select>
              </v-col>
            </v-row>

            <v-row
              style="margin-top: -25px"
              v-if="
                this.formCancelInicial.ordenReplaceSeleccionado.idCfdi != '00000' &&
                this.formCancelInicial.motivoCancelacionSeleccionado == '01'
              "
            >
              <v-col cols="12" md="4">
                <span
                  style="
                    margin-left: 0px;
                    font-weight: bold;
                    width: 100%;
                    position: absolute;
                    margin-top: 15px;
                  "
                  >Datos del nuevo<br />
                  CFDI:
                </span>
              </v-col>

              <v-col cols="12" md="7">
                <div>
                  <span style="font-size: small"> Fecha: </span>
                  <span style="font-size: small; margin-left: 3px">
                    {{ this.cfdiReplace.fechaTimbrado }}
                  </span>

                  <span style="font-size: small; margin-left: 10px">
                    RFC receptor:
                  </span>
                  <span style="font-size: small; margin-left: 3px">
                    {{ this.cfdiReplace.rfcReceptor }}
                  </span>
                </div>
                <div>
                  <span style="font-size: small"> Total: </span>
                  <span style="font-size: small; margin-left: 5px">
                    $ {{ this.cfdiReplace.total }}
                  </span>
                </div>
                <div style="margin-bottom: 5px">
                  <span style="font-size: small"> UIDD: </span>
                  <span style="font-size: small; margin-left: 3px">
                    {{ this.cfdiReplace.uidd }}
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-divider></v-divider>
          </v-container>
        </v-form>
        <!-- ------------------------------------------------------ -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            style="margin-top: 10px; margin-right: 5px"
            @click="backCancel()"
          >
            <v-icon style="margin-right: 5px">mdi-close-circle</v-icon>Regresar
          </v-btn>

          <v-btn
            :disabled="deshabilitadoGuardarConcepto"
            :loading="deshabilitadoGuardarConceptoLoading"
            color="primary"
            small
            style="margin-right: 10px; margin-top: 10px"
            @click="goToCancel()"
          >
            <v-icon style="margin-right: 5px">mdi-send</v-icon>&nbsp;Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar2" :color="colorSnack">
      {{ mensajeSnack }}
      <v-btn text @click="snackbar2 = false">Cerrar</v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbar3" :color="colorSnack2">
      {{ mensajeSnack2 }}
      <v-btn text @click="snackbar3 = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import router from "../router";
import operations from "../operations";
import descarga from "../views/DasboardDetalles/Descarga";
import fiscales from "../views/DasboardDetalles/Fiscales";
import cumplimiento from "../views/DasboardDetalles/Cumplimiento";
import gastos from "../views/DasboardDetalles/Gastos";
import ingresos from "../views/DasboardDetalles/Ingresos";
import axios from "axios";

export default {
  directives: {},
  components: {
    descarga,
    fiscales,
    cumplimiento,
    gastos,
    ingresos,
  },
  name: "Dashboard",
  data: () => ({
    colorPublicoEnGeneral: "#0065BB",
    disabledPublicoEnGeneral: false,
    loader: null,
    auxTipoFactura: {
      clave: "",
      descripcion: "",
    },
    checkPublico: false,
    checkGuardarCliente: false,
    monto5000: true,
    monto1000: true,
    dialogoConfirmaCancelarCDFI: false,
    dialogoConfirmaBorrarConcepto: false,
    dialogoBorrarCliente: false,
    arrCFDIsCancel: [],
    arrCFDISReplaces: [],
    cfdiReplace: {},
    busquedaPor: "F",
    busquedaPorO: null,
    busquedaPorF: null,
    dateFechaIni: new Date().toISOString().substr(0, 10),
    dateFechaFin: new Date().toISOString().substr(0, 10),
    dateFormattedIni: new Date().toISOString().substr(0, 10),
    dateFormattedFin: new Date().toISOString().substr(0, 10),
    fechaIni: false,
    fechaFin: false,
    loadingCFDI: false,
    dialogoConfirmaCDFI: false,
    dialogoCDFIMensaje: false,
    loadingCancelCFDI: false,
    deshabilitadoGuardarConceptoLoading: false,
    deshabilitadoGuardarConcepto: false,
    porPagina: 1000,
    porPaginaCancel: 5,
    mensajeSnack: "",
    mensajeSnack2: "",
    snackbar2: false,
    snackbar3:false,
    dialogoAvanzado: false,
    catalogoFormaPago: [],
    catalogoImpuestos: [],
    catalogoImpuestosLocales: [],
    catalogoMetodoPago: [],
    //catalogoPlataformas: [],
    catalogoTiposComprobantes: [],
    catalogoUsoCfdi: [],
    // Llenado de catálogos para Configuración avanzada CFDI 4.0}
    //ventanaClientes: false,
    catalogoPeriodicidad: [],
    catalogoMeses: [],
    catalogoSatRegimenFiscales: [],
    anioErrorMessages: [],
    regFiscalEmisorError: [],
    regFiscalReceptorError: [],
    formaPagoError: [],
    rfcListError: [],
    anioErrorMensaje: "",
    anioError: false,
    anioAnterior: "",
    catalogoCancelacion: [],
    catalogoMoneda: [
      {
        clave: "MXN",
        descripcion: "MXN-Peso Mexicano",
      },
    ],
    catalogoDiasCredito: [
      {
        clave: "0",
        descripcion: "0",
      },
      {
        clave: "15",
        descripcion: "15",
      },
      {
        clave: "30",
        descripcion: "30",
      },
      {
        clave: "45",
        descripcion: "45",
      },
    ],
    searchConcepto: null,
    isLoadingSAT: false,
    modelSAT: null,
    listaConceptos: [],
    dialogoConceptos: false,
    dialogoConcepto: false,
    dialogoCatalogoCancelacion: false,
    rfcRules: [
      (v) => !!v || "El RFC es requerido",
      (v) =>
        (v && (v.length == 12 || v.length == 13)) ||
        "El RFC debe tener 12 o 13 caracteres",
    ],
    ordenRules: [
      (v) => !!v || "El número de orden es requerido",
      //v => /^[A-Za-z0-9_ ]+$/g.test(v) || 'Solo se aceptan letras y números',//'Solo letras y números',
      (v) => (v && v.length >= 3) || "Ingrese al menos 3 caracteres",
    ],
    codigoPostalRules: [
      (v) => !!v || "El campo es requerido",
      (v) => /^[-+]?[0-9]+$/.test(v) || "Ingrese un valor númerico",
      (v) => (v && v.length == 5) || "El código postal debe tener 5 caracteres",
    ],
    razonSocialRules: [
      (v) => !!v || "La razón social es requerida",
      (v) => (v && v.length >= 3) || "Ingrese al menos 3 caracteres",
    ],
    currencyRules: [
      (v) => !!v || "El campo es requerido",
      (v) =>
        /^([^\d\s]{1,}\s?[+-]?)(\d{1,3})(\,\d{3})*(\.\d{1,})?$/.test(v) ||
        "Ingrese el valor separado por comas por ejemplo: ($0,000.00)",
    ],
    decimalRules: [
      (v) => !!v || "El campo es requerido",
      (v) =>
        /^(\d+\.?\d{0,9}|\.\d{1,9})$/.test(v) || "Ingrese el valor decimal",
    ],
    numericRules: [
      (v) => !!v || "El campo es requerido",
      (v) => /^[-+]?[0-9]+$/.test(v) || "Ingrese el valor númerico",
    ],
    numericRulesCantidad: [
      (v) => !!v || "El campo es requerido",
      // v => /^(\d+\.?\d{0,9}|\.\d{0,9})$/.test(v) || 'Ingrese el valor decimal',
      (v) => /^\d+(\.\d{1,2})?$/.test(v) || "Ingrese el valor decimal",
    ],
    decimalRulesX: [
      (v) => !!v || "El campo es requerido",
      (v) =>
        /^(\d+\.?\d{0,9}|\.\d{1,9})$/.test(v) || "Ingrese el valor decimal",
    ],
    tasaRules: [
      (v) => !!v || "El campo es requerido",
      (v) => /^[-+]?[0-9]+$/.test(v) || "Ingrese el valor  por ejemplo: (16)",
    ],
    tasaRulesLocal: [
      (v) => !!v || "El campo es requerido",
      (v) =>
        /^[-+]?[0-9]+\.[0-9]+$/.test(v) ||
        "Ingrese el valor  por ejemplo: (16.00)",
    ],
    tasaRulesIvaTrasladado: [
      (v) => !!v || "El campo es requerido",
      (v) => (v && parseInt(v) < 17) || "El valor debe ser menor o igual a 16",
      //v => /^(.+)$(??{$^N>=0 && $^N<17 ? '':'^'})/ || 'Ingrese el valor  menor a 16',
      // v => /^[-+]?[0-9]+$/.test(v) || 'Ingrese el valor  menor a 16 sin decimales',
      // v => v.length < 16 || 'El valor debe ser menor a 16',
    ],
    tasaRulesIvaRetenido: [
      (v) => !!v || "El campo es requerido",
      (v) => (v && parseInt(v) < 17) || "El valor debe ser menor o igual a 16",
      //v => /^(.+)$(??{$^N>=0 && $^N<17 ? '':'^'})/ || 'Ingrese el valor  menor a 16',
      // v => /^[-+]?[0-9]+$/.test(v) || 'Ingrese el valor  menor a 16 sin decimales',
      // v => v.length < 16 || 'El valor debe ser menor a 16',
    ],
    emailRules: [
      (v) => !!v || "El E-mail es requerido",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "el E-mail debe ser válido",

      // v => /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(v) || 'el E-mail debe ser válido',
    ],
    condicionesRules: [(v) => !!v || "Las condiciones de pago son requeridas"],

    //Regla para validar el campo Año en la configuración avanzada CFDI 4.0
    anioRules: [
      (v) => !!v || "El año es requerido",
      (v) => /^[-+]?[0-9]+$/.test(v) || "Ingrese un valor númerico",
      (v) => (v && v.length == 4) || "Ingrese un año válido",
    ],
    requeridoRules: [(v) => !!v || "El campo es requerido"],
    //arrFormasPago: ['Tarjeta de debito', 'Bar', 'Fizz', 'Buzz'],
    max25chars: (v) => v.length <= 25 || "!Campo demasiado largo!",
    headersPrincipal: [
      {
        text: "Clave",
        align: " d-none",
        sortable: false,
        value: "idConceptoCliente",
        class: "encabezado-azul",
      },
      {
        text: "Descripción",
        align: "left",
        value: "descripcion",
        sortable: false,
        class: "encabezado-azul",
        width: "190"
      },
      {
        text: "Cantidad",
        value: "cantidad",
        sortable: false,
        align: "center",
        class: "encabezado-azul",
        width: "90"
      },
      {
        text: "Descuento",
        value: "descuento",
        sortable: false,
        align: "center",
        class: "encabezado-azul",
        width: "90"
      },
      {
        text: "claveUnidad",
        value: "claveUnidad",
        sortable: false,
        class: "encabezado-azul",
        align: " d-none",
      },
      {
        text: "Unidad",
        value: "unidad",
        sortable: false,
        class: "encabezado-azul",
        align: " d-none",
      },
      {
        text: "Unitario",
        value: "valorUnitario",
        sortable: false,
        align: "right",
        class: "encabezado-azul",
        width: "100"
      },
      {
        text: "Importe",
        value: "importe",
        sortable: false,
        align: "right",
        class: "encabezado-azul",
        width: "100"
      },
      {
        text: "x",
        value: "accion",
        sortable: false,
        align: "center",
        class: "encabezado-azul",
        width: "20"
      },
      {
        value: "impuestoSeleccionado",
        align: " d-none",
      },
      {
        value: "impuestoSeleccionadoDescripcion",
        align: " d-none",
      },
      {
        value: "claveImpuestoSatDescripcion",
        align: " d-none",
      },
    ],
    headersCFDIs: [
      {
        text: "No. de Orden",
        align: "center",
        sortable: false,
        value: "referencia",
        class: "encabezado-azul",
      },
      {
        text: "RFC del Receptor",
        align: "left",
        value: "rfcReceptor",
        sortable: false,
        class: "encabezado-azul",
      },
      {
        text: "Total",
        value: "total",
        sortable: false,
        align: "center",
        class: "encabezado-azul",
      },
      {
        text: "Fecha de Emisión",
        value: "fechaTimbrado",
        sortable: false,
        align: "center",
        class: "encabezado-azul",
      },
      {
        text: "Cancelar",
        value: "cancela",
        sortable: false,
        align: "center",
        class: "encabezado-azul",
      },
    ],
    headersx: [
      {
        text: "Clave",
        align: "center",
        sortable: false,
        value: "idConceptoCliente",
        class: "encabezado-azul",
        width: "70px",
      },
      {
        text: "Nombre",
        align: "left",
        value: "productoServicio",
        sortable: false,
        class: "encabezado-azul",
        filterable: true,
        width: "300px",
      },
      // {
      //     text: 'Cantidad',
      //     value: 'cantidad',
      //     sortable: false,
      //     align: 'center',
      //     class: 'encabezado-azul'
      // },
      // {
      //     text: 'Descuento',
      //     value: 'descuento',
      //     sortable: false,
      //     align: 'center',
      //     class: 'encabezado-azul'
      // },
      {
        text: "claveUnidad",
        value: "claveUnidad",
        sortable: false,
        class: "encabezado-azul",
        align: " d-none",
      },
      {
        text: "Unidad",
        value: "unidad",
        sortable: false,
        class: "encabezado-azul",
        align: "center",
        width: "100px",
      },
      {
        text: "Unitario",
        value: "valorUnitario",
        sortable: false,
        align: "center",
        class: "encabezado-azul",
        width: "160px",
      },
      // {
      //     text: 'Importe',
      //     value: 'importe',
      //     sortable: false,
      //     align: 'center',
      //     class: 'encabezado-azul'
      // },
      {
        text: "Acción",
        value: "accion",
        sortable: false,
        align: "center",
        class: "encabezado-azul",
        width: "200px",
      },
      {
        value: "impuestoSeleccionado",
        align: " d-none",
      },
      {
        value: "impuestoSeleccionadoDescripcion",
        align: " d-none",
      },
    ],
    conceptosx: [],
    conceptosFinales: [],
    tipoFactura: "AIRBNB",
    //tipoFactura: "UTAXME",
    idTipoFactura: "",
    positionMenu: false,
    emision: {
      loadingBusqueda: false,
    },
    formCancel: {
      loadingBusqueda: false,
      filtroOrden: "",
    },
    formConcepto: {
      ivaTrasladado: "",
      iepsTrasladado: "",
      isrRetenido: "",
      ivaRetenido: "",
      modo: "alta",
      idConceptoCliente: "",
      checkImpuestoLocal: false,
      checkImpuestoTrasladado: false,
      checkImpuestoRetenido: false,
      isEdit: false,
      productoServicio: "",
      formValid: false,
      filtro: "",
      arrConceptos: [],
      loadingBusqueda: false,
      loader: null,
      identificador: "",
      descripcion: "",
      claveUnidad: "",
      unidad: "",
      unitario: "",
      impuestos: "",
      tasa: "",
      tasaLocal: "",
      hayImpuesto: false,
      hayImpuestoLocal: false,
      hayImpuestoLocalSeleccionado: false,
      hayImpuestoTrasladadoSeleccionado: false,
      hayImpuestoRetenidoSeleccionado: false,
      impuestoSeleccionado: {
        clave: "",
        descripcion: "",
      },
      impuestoLocalSeleccionado: {
        clave: "",
        descripcion: "",
      },
      impuestoSeleccionadoDescripcion: "",
      impuestoLocalSeleccionadoDescripcion: "",
      conceptoSeleccionado: {
        identificador: "",
        descripcion: "",
        claveUnidad: "",
        unidad: "",
      },
    },
    formAvanzado: {
      disabled: false,
      disabledPeriodicidad: true,
      formValid: false,
      metodoPago: {
        clave: "PUE",
        descripcion: "Pago en una sola exhibición",
      },
      moneda: {
        clave: "MXN",
        descripcion: "MXN-Peso Mexicano",
      },
      usoCFDI: {
        clave: "G03",
        descripcion: "Gastos en general",
      },
      diasCredito: {
        clave: "0",
        descripcion: "0",
      },
      periodicidad: {
        clave: "04",
        descripcion: "Mensual",
      },
      meses: {
        clave: "01",
        descripcion: "Enero",
      },
      anioActual: "",
      mesActual:0,
      anio: "2022",
      condiciones: "PAGO EN UNA SOLA EXHIBICIÓN",
    },
    showDatosCliente: true,
    showConceptos:false,
    formInicial: {
      disabled: false,
      disabledClienteFrecuente: false,
      razonSocial: "",
      formaPagoSeleccionado: {
        clave: "0",
        descripcion: "Forma de pago",
      },
      formaRegimenSeleccionado: {
        clave: "0",
        descripcion: "Régimen fiscal emisor",
      },
      regimenClienteSeleccionado: {
        clave: "0",
        descripcion: "Régimen fiscal receptor",
      },
      formValid: false,
      formaPago01: {
        clave: "28",
        descripcion: "Tarjeta de débito",
      },
      noOrden: "",
      email: "",
      codigoPostal: "",
      clientesList: [],
      rfcClientesList: [],
      listRegFiscalUsuario: [],
      searchRFC:"",
      rfcSeleccionado: "",
      idClienteFrecuente: 0,
      loadingBorrarCliente: false,
      disabledBorrarCliente: true,
      usoCFDICache: {
        clave: "G03",
        descripcion: "Gastos en general",
      },
      emailCache: "",
      rfcSeleccionadoCache: "",
      razonSocialCache:"",
      formaPagoSeleccionadoCache: {
        clave: "0",
        descripcion: "Forma de pago",
      },
      regimenClienteSeleccionadoCache: {
        clave: "0",
        descripcion: "Régimen fiscal receptor",
      },
      codigoPostalCache: "",
    },
    formCancelInicial: {
      motivoCancelacionSeleccionado: {
        clave: "0",
        descripcion: "Seleccione el motivo de cancelación",
      },
      ordenReplaceSeleccionado: {
        idCfdi: "00000",
        referencia: "Seleccione un número de orden",
      },
    },
    cadMotivoCancelacionSeleccionado: "",
    isPlatform: true,
    auxModel: "",
    checkPlataformas: true,
    closeOnContentClick: true,
    tituloEncabezado: "Encabezado",
    tituloFactura: "Factura electrónica",
    facturacionSeciones: [
      {
        title: "Encabezado",
        src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        flex: 12,
      },
      {
        title: "Facturación electrónica",
        src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
        flex: 12,
      },
    ],
    fixedTabs: true,
    alturaTabAvisos: "",
    tab: null,
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
    menu2: false,
    cards: [
      {
        title: "Datos fiscales",
        src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        flex: 6,
      },
      {
        title: "Ingresos del periodo",
        src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
        flex: 6,
      },
      {
        title: "Gastos del periodo",
        src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
        flex: 6,
      },
      {
        title: "Cumplimiento fiscal",
        src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
        flex: 6,
      },
    ],
    cardsMobile: [
      {
        title: "Datos fiscales",
        src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        flex: 12,
      },
      {
        title: "Ingresos del periodo",
        src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
        flex: 12,
      },
      {
        title: "Gastos del periodo",
        src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
        flex: 12,
      },
      {
        title: "Cumplimiento fiscal",
        src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
        flex: 12,
      },
    ],
    dialogoDashboard: true,
    vertical: true,
    timeoutMsg: 4000,
    textError: "",
    snackbar: false,
    colorTitulo1: "tituloAzul",
    colorTitulo2: "tituloGris",
    colorTitulo3: "tituloGris",
    separador1: true,
    separador2: true,
    hover: "",
    windowWidth: 0,
    windowHeight: 0,
    flagMultiline: false,
    colorSnack: "green",
    colorSnack2: "green",
    cfdiACancelar: {},
    conceptoABorrar: {},
  }),
  mounted() {
    console.log("obteniendo datos..");
    this.datosFiscales();
    this.ingresosEgresos();
    this.cumplimiento();

    this.CatalogosSatForm();
    this.getClientesList();
    console.log("obteniendo countCFDIs..");
    this.countCFDIs();

    this.ListRegimenFiscalUsuaurio();

    var currentdate = new Date();
    currentdate.setDate(currentdate.getMonth() - 1);
    this.dateFechaIni = currentdate.toISOString().substr(0, 10);

    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("resize", this.getWindowHeight);
      //Init
      this.getWindowWidth();
      this.getWindowHeight();
    });
  },
  methods: {
    async ListRegimenFiscalUsuaurio() {
      console.log("listRegFiscalUsuario....");
      try {
        const response = await operations.listRegFiscalUsuario({
          rfc: this.$store.state.cliente.rfc,
        });
        console.log(response);

        if (response.status == "200") {
          //combo 1
          this.$store.state.listRegFiscalUsuario = response.data.lista;

          if (response.data.lista.length > 0) {
            this.$store.state.regimenFiscalUsuario =
              response.data.lista[0].descripcionRegimen;

            this.$store.state.regimenFiscalUsuarioId =
              response.data.lista[0].idSat;
            this.$store.state.regimenFiscalUsuarioDesc =
              response.data.lista[0].descripcionRegimen;
          }
          this.formInicial.listRegFiscalUsuario = this.$store.state.listRegFiscalUsuario;
          // this.catalogoImpuestos.splice(0, 0, {clave:'-1',descripcion:'Sin impuesto'});
          //this.catalogoImpuestosLocales.splice(0, 0, {clave:'-1',descripcion:'Sin impuesto'});

          console.log("ok listRegFiscalUsuario...");
        }
      } catch (error) {
        console.log(error.response);
      }
    },
    publicoEnGeneral() {
      console.log(this.checkPublico);
      //Desactivar factura Público en general
      if (this.checkPublico) {
        this.disabledPublicoEnGeneral = true;
        this.checkPublico = false;
        this.formInicial.rfcSeleccionado = "";
        this.formInicial.razonSocial = "";
        //caché
        this.formInicial.rfcSeleccionado = this.formInicial.rfcSeleccionadoCache;
        this.actualizaRfc();
        this.formInicial.razonSocial = this.formInicial.razonSocialCache;
        this.formInicial.regimenClienteSeleccionado = this.formInicial.regimenClienteSeleccionadoCache;
        this.formInicial.codigoPostal = this.formInicial.codigoPostalCache;
        this.formInicial.email = this.formInicial.emailCache;
        this.formAvanzado.usoCFDI = this.formInicial.usoCFDICache;
        this.formInicial.formaPagoSeleccionado = this.formInicial.formaPagoSeleccionadoCache;
        this.formAvanzado.disabled = false;
        this.formAvanzado.disabledPeriodicidad= true;
        this.formInicial.disabled = false;
        this.formInicial.disabledClienteFrecuente = false;
        this.colorPublicoEnGeneral = "#0065BB";
        this.disabledPublicoEnGeneral = false;
        
      } 
      //Activar factura Público en general
      else {
        this.updatePeriodicidad();
        this.checkGuardarCliente = false;
        this.disabledPublicoEnGeneral = true;
        this.checkPublico = true;
        this.formAvanzado.disabled = true;
        this.formAvanzado.disabledPeriodicidad= false;
        this.formInicial.disabled = true;
        this.formInicial.disabledClienteFrecuente = true;
        this.formInicial.disabledBorrarCliente = true;
        this.formInicial.rfcSeleccionadoCache = this.formInicial.rfcSeleccionado;
        this.formInicial.rfcSeleccionado = "XAXX010101000";
        this.formInicial.razonSocialCache = this.formInicial.razonSocial;
        this.formInicial.razonSocial = "PUBLICO EN GENERAL";
        this.formInicial.regimenClienteSeleccionadoCache = this.formInicial.regimenClienteSeleccionado;
        this.formInicial.regimenClienteSeleccionado =  
          this.catalogoSatRegimenFiscales.find(regimen => regimen.clave === "616");
          this.actualizaRegimenReceptor();
          
        this.formInicial.codigoPostalCache = this.formInicial.codigoPostal;
        var arrayDomicilio = this.$store.state.dashboard.domicilio.split("CP.");
        var codigoPostal = arrayDomicilio[1].slice(0,5).toString();
        this.formInicial.codigoPostal = codigoPostal;
        this.formInicial.emailCache = this.formInicial.email;
        this.formInicial.email = this.$store.state.dashboard.email;
        this.formInicial.usoCFDICache = this.formAvanzado.usoCFDI;
        this.formAvanzado.usoCFDI = {
          clave: "S01",
          descripcion: "Sin efectos fiscales"
        }
        this.formInicial.formaPagoSeleccionadoCache = this.formInicial.formaPagoSeleccionado;
        this.formInicial.formaPagoSeleccionado = 
          this.catalogoFormaPago.find(formaPago => formaPago.clave === "03");
          this.actualizaformaPago();
        this.colorPublicoEnGeneral = "success";
        this.disabledPublicoEnGeneral = false;
        this.dialogoAvanzado = true;
        this.snackbar2 = true;
          this.colorSnack = "info";
          this.mensajeSnack =
            "Valida que la configuración sea correcta";
      }
    },
    checkedGuardarCliente() {
      console.log("Guardar cliente frecuente: " + this.checkGuardarCliente);
    },
    opcion1() {
      console.log(this.busquedaPor);
    },
    radioCambioO() {
      // if(this.busquedaPor=='fecha'){
      //     this.arrCFDIsCancel=[];
      // }else{
      this.formCancel.filtroOrden = "";
      this.busquedaPor = "O";
      //this.busquedaPorO=true;
      //this.busquedaPorF=false;
      //}
    },
    radioCambioF() {
      //if(this.busquedaPor=='fecha'){
      this.arrCFDIsCancel = [];
      this.busquedaPor = "F";
      //this.busquedaPorF=true;
      //this.busquedaPorO=false;
      // }else{
      //   this.formCancel.filtroOrden='';
      // }
    },
    findCFDIs(isReload) {
      if (isReload != true) isReload = false;
      //

      if (this.busquedaPor == "F") {
        //-----------------------------------------------------------
        console.log("fecha inicio....." + this.computedDateFormattedIni);
        console.log("fecha fin....." + this.computedDateFormattedFin);
        var d1 = new Date(this.dateFechaIni);
        var d2 = new Date(this.dateFechaFin);
        if (d1 > d2) {
          console.log("la fecha ini no puede ser mayor que la final");
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack =
            "La fecha inicial no puede ser mayor que la fecha final";
        } else {
          console.log("busqueda de CFDIs.....");
          this.callSearchCFDIs("F", isReload);
        }
        //-----------------------------------------------------------
      } else {
        //-----------------------------------------------------------
        if (this.formCancel.filtroOrden == "") {
          // console.log("la fecha ini no puede ser mayor que la final");
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack = "El número de orden es requerido";
        } else {
          console.log("busqueda de CFDIs por orden....");
          this.callSearchCFDIs("O", isReload);
        }
        //-----------------------------------------------------------
      }
    },
    async cancelaCFDIReal() {
      console.log("cancelaCFDIReal");
      console.log(this.cfdiACancelar);
      this.loadingCancelCFDI = true;
      var motivo = this.formCancelInicial.motivoCancelacionSeleccionado;
      var uiddReemplazo = "";

      if (this.formCancelInicial.motivoCancelacionSeleccionado == "01") {
        uiddReemplazo = this.cfdiReplace.uidd;
      }

      try {
        const response = await operations.cancelarCFDI({
          idCfdi: this.cfdiACancelar.idCfdi,
          uidd: this.cfdiACancelar.uidd,
          motivoCancelacion: motivo,
          uiddReemplazoCancelacion: uiddReemplazo,
        });
        console.log(response);

        //if (response.status == "200") {
        if (response.data.codigo == "200") {
          console.log("CFDI eliminado...");

          this.snackbar2 = true;
          this.colorSnack = "green";
          this.mensajeSnack = "CFDI cancelado correctamente";
          this.dialogoConfirmaCancelarCDFI = false;

          //recargar tabla
          this.findCFDIs(true);
          this.loadingCancelCFDI = false;
        } else {
          console.log(response.data.mensaje);
          this.loadingCancelCFDI = false;
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack =
            "Error al  cancelar el CFDI: " + response.data.mensaje;
        }
      } catch (error) {
        //cae aquí
        console.log(error.response);
        this.loadingCancelCFDI = false;
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Error al  cancelar el CFDI: " + error;
      }
    },
    async callSearchCFDIs(tipo, isReload) {
      console.log("callSearchCFDIs");

      var fechaInicial = this.dateFechaIni;
      var fechaFin = this.dateFechaFin;
      var orden = this.formCancel.filtroOrden;

      if (this.busquedaPor == "F") {
        orden = "";
      } else {
        fechaInicial = "";
        fechaFin = "";
      }

      try {
        const response = await operations.CfdiGeneradosFilter({
          rfc: this.$store.state.cliente.rfc,
          fechaInicialFilter: fechaInicial,
          fechaFinFilter: fechaFin,
          referencia: orden,
          tipo: tipo,
        });
        console.log(response);

        if (response.status == "200") {
          console.log("cfdis encontrados...");
          console.log(response.data);

          if (response.data.lista == undefined) {
            if (isReload) return;
            this.snackbar2 = true;
            this.colorSnack = "red";
            this.mensajeSnack = "No se encontrarón resultados";
            return;
          }

          if (response.data.lista.length == 0) {
            if (isReload) {
              this.arrCFDIsCancel = [];
              return;
            }
            this.snackbar2 = true;
            this.colorSnack = "red";
            this.mensajeSnack = "No se encontrarón resultados";
            this.arrCFDIsCancel = [];
            return;
          }

          this.arrCFDIsCancel = response.data.lista;

          for (var i = 0; i < this.arrCFDIsCancel.length; i++) {
            try {
              var xx = new Date(this.arrCFDIsCancel[i].fechaTimbrado);
              this.arrCFDIsCancel[i].fechaTimbrado =
                this.zeros(xx.getDate()) +
                "/" +
                this.zeros(xx.getMonth() + 1) +
                "/" +
                xx.getFullYear();
            } catch (error) {
              console.log("Error al formatear fecha:" + error);
            }
          }
        }
      } catch (error) {
        console.log(error.response);
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Error al obtener CFDIs: " + error.response;
      }
    },
    zeros(valor) {
      var val = parseInt(valor);
      if (val < 10) return "0" + valor;
      else return valor;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    changeUseCFDI() {
      console.log(this.formAvanzado.usoCFDI);
    },
    changeMetodoPago() {
      // console.log(this.formAvanzado);
      console.log(this.formAvanzado.metodoPago);
    },

    // Métodos para la configuración Avanzada CFDI 4.0
    changePeriodicidad() {
      console.log(this.formAvanzado.periodicidad);
    },
    changeMeses() {
      console.log(this.formAvanzado.meses);
    },
    changeAnio() {
      this.anioAnterior = parseInt(this.formAvanzado.anioActual, 10) - 1;
      this.anioErrorMensaje =
        "Ingrese un año entre " + this.anioAnterior + " y " + this.formAvanzado.anioActual + ".";

      if (
        this.formAvanzado.anio == this.formAvanzado.anioActual ||
        this.formAvanzado.anio == this.anioAnterior
      ) {
        console.log(this.formAvanzado.anio);
        this.anioError = false;
      } else {
        this.anioError = true;
      }

      if (this.anioError) {
        this.anioErrorMessages = [this.anioErrorMensaje];
        console.log(this.anioErrorMessages);
      } else {
        this.anioErrorMessages = [];
      }
    },
    async validateConfAvanzada() {
      console.log(this.formAvanzado.metodoPago);
      console.log(this.formAvanzado.usoCFDI);
      console.log(this.formAvanzado.periodicidad);
      console.log(this.formAvanzado.meses);
      console.log(this.formAvanzado.anio);
      this.$refs.formAvanzado.validate();
      if (!this.formAvanzado.formValid) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Verifique los campos faltantes";
        return;
      }
      this.dialogoAvanzado = false;
    },
    clearForm() {
      console.log("limpiando forms...");
      this.conceptosFinales = [];
      this.formInicial = {
        disabled: false,
        disabledClienteFrecuente: false,
        razonSocial: "",
        formaPagoSeleccionado: {
          clave: "0",
          descripcion: "Forma de pago",
        },
        formaRegimenSeleccionado: {
          clave: "0",
          descripcion: "Régimen fiscal emisor",
        },
        regimenClienteSeleccionado: {
          clave: "0",
          descripcion: "Régimen fiscal receptor",
        },
        formValid: false,
        formaPago01: {
          clave: "28",
          descripcion: "Tarjeta de débito",
        },
        noOrden: "",
        email: "",
        codigoPostal: "",
        clientesList: [],
        rfcClientesList: [],
        listRegFiscalUsuario: [],
        searchRFC:"",
        rfcSeleccionado: "",
        idClienteFrecuente: 0,
        loadingBorrarCliente: false,
        disabledBorrarCliente: true,
        usoCFDICache: {
          clave: "G03",
          descripcion: "Gastos en general",
        },
        emailCache: "",
        rfcSeleccionadoCache: "",
        razonSocialCache:"",
        formaPagoSeleccionadoCache: {
          clave: "0",
          descripcion: "Forma de pago",
        },
        regimenClienteSeleccionadoCache: {
          clave: "0",
          descripcion: "Régimen fiscal receptor",
        },
        codigoPostalCache: ""
      };
      this.formAvanzado.disabled = false;
      this.formAvanzado.disabledPeriodicidad = true;
      this.formAvanzado.metodoPago = {
        clave: "PUE",
        descripcion: "Pago en una sola exhibición",
      };
      this.formAvanzado.moneda = {
        clave: "MXN",
        descripcion: "MXN-Peso Mexicano",
      };
      this.formAvanzado.usoCFDI = {
        clave: "G03",
        descripcion: "Gastos en general",
      };
      this.formAvanzado.diasCredito = {
        clave: "0",
        descripcion: "0",
      };
      this.formAvanzado.periodicidad = {
        clave: "04",
        descripcion: "Mensual",
      };
      this.formAvanzado.meses = {
        clave: "01",
        descripcion: "Enero",
      };  
      this.formAvanzado.condiciones = "PAGO EN UNA SOLA EXHIBICIÓN";
      this.checkPublico = true;
      this.getClientesList();
      this.publicoEnGeneral();
      this.updatePeriodicidad();
      this.ListRegimenFiscalUsuaurio();
    },
    deleteConceptoFactura(item) {
      console.log("deleteConceptoFactura");
      console.log(item);
      console.log("conceptosFinales...antes");
      console.log(this.conceptosFinales.length);
      //
      for (var i = 0; i < this.conceptosFinales.length; i++) {
        if (this.conceptosFinales[i].consecutivo == item.consecutivo) {
          this.conceptosFinales.splice(i, 1);
          break;
        }
      }

      console.log("conceptosFinales...despues");
      console.log(this.conceptosFinales.length);
    },
    selectCatCancelarCFDI(item) {
      console.log(item);
      this.cfdiACancelar = item;
      this.dialogoCatalogoCancelacion = true;
    },
    confirmaCancelarCFDI(item) {
      console.log("confirmaCancelarCFDI");
      console.log(item);
      this.cfdiACancelar = item;

      if (item.total > 5000) {
        this.monto5000 = true;
      } else {
        this.monto5000 = false;
      }

      this.dialogoConfirmaCancelarCDFI = true;
    },
    confirmaBorrarConcepto(item) {
      console.log("confirmaBorrarConcepto");
      console.log(item);
      this.conceptoABorrar = item;

      this.dialogoConfirmaBorrarConcepto = true;
    },
    confirmaBorrarCliente() {
      console.log("confirma Borrar cliente");
      this.actualizaRfc();
      this.dialogoBorrarCliente = true;
    },
    nuevoConcepto() {
      this.deshabilitadoGuardarConcepto = false;
      this.formConcepto.modo = "alta";
      this.formConcepto.productoServicio = "";
      this.formConcepto.formValid = false;
      this.formConcepto.filtro = "";
      this.formConcepto.arrConceptos = [];
      this.formConcepto.loadingBusqueda = false;
      this.formConcepto.loader = null;
      this.formConcepto.identificador = "";
      this.formConcepto.descripcion = "";
      this.formConcepto.claveUnidad = "";
      this.formConcepto.unidad = "";
      this.formConcepto.unitario = "";
      this.formConcepto.impuestos = "";
      this.formConcepto.tasa = "";
      this.formConcepto.tasaLocal = "";
      this.formConcepto.hayImpuesto = false;
      this.formConcepto.hayImpuestoLocal = false;

      this.formConcepto.checkImpuestoLocal = false;
      this.formConcepto.checkImpuestoTrasladado = false;
      this.formConcepto.checkImpuestoRetenido = false;
      this.formConcepto.hayImpuestoTrasladadoSeleccionado = false;
      this.formConcepto.hayImpuestoRetenidoSeleccionado = false;

      this.formConcepto.hayImpuestoLocalSeleccionado = false;
      this.formConcepto.impuestoSeleccionado = {
        clave: "-1",
        descripcion: "Sin impuesto",
      };
      this.formConcepto.impuestoLocalSeleccionado = {
        clave: "-1",
        descripcion: "Sin impuesto",
      };
      this.formConcepto.conceptoSeleccionado = {};

      //this.formConcepto.reset();
      try {
        this.$refs.formConcepto.reset();
      } catch (error) {
        console.log("error on reset concepto form");
      }

      this.dialogoConcepto = !this.dialogoConcepto;
      this.formConcepto.isEdit = false;
    },
    editarConceptoFactura(item) {
      this.formConcepto.ivaTrasladado = "";
      this.formConcepto.iepsTrasladado = "";
      this.formConcepto.isrRetenido = "";
      this.formConcepto.ivaRetenido = "";
      this.formConcepto.checkImpuestoLocal = false;
      this.formConcepto.checkImpuestoTrasladado = false;
      this.formConcepto.checkImpuestoRetenido = false;
      this.formConcepto.tasa = "";
      this.formConcepto.tasaLocal = "";
      this.formConcepto.hayImpuesto = false;
      this.formConcepto.hayImpuestoLocal = false;
      this.formConcepto.hayImpuestoLocalSeleccionado = false;
      this.formConcepto.hayImpuestoTrasladadoSeleccionado = false;
      this.formConcepto.hayImpuestoRetenidoSeleccionado = false;

      this.formConcepto.modo = "edicion";
      this.deshabilitadoGuardarConcepto = false;
      console.log("editarConceptoFactura");
      console.log(item);
      //mapping concept properties
      this.formConcepto.idConceptoCliente = item.idConceptoCliente;
      this.formConcepto.productoServicio = item.productoServicio;
      this.formConcepto.identificador = item.identificadorSat;
      this.formConcepto.descripcion = item.descripcion;
      this.formConcepto.unidad = item.unidad.trim().toUpperCase();
      //this.formConcepto.cveunidad=item.unidad;
      this.formConcepto.claveUnidad = item.claveUnidad.trim().toUpperCase();
      this.formConcepto.unitario = item.valorUnitario.trim().replace("$", "");
      this.formConcepto.impuestoSeleccionado = "002";
      //this.formConcepto.impuestoSeleccionado.clave=item.claveImpuestoSat;

      if (item.tasa != null || item.ieps != null) {
        this.formConcepto.checkImpuestoTrasladado = true;
        this.formConcepto.hayImpuestoTrasladadoSeleccionado = true;

        this.formConcepto.ivaTrasladado = item.tasa == null ? "" : item.tasa;
        this.formConcepto.iepsTrasladado =
          item.ieps == null ? "" : parseFloat(item.ieps).toFixed(4);
      }
      if (item.isrRet != null || item.ivaRet != null) {
        this.formConcepto.checkImpuestoRetenido = true;
        this.formConcepto.hayImpuestoRetenidoSeleccionado = true;

        this.formConcepto.isrRetenido =
          item.isrRet == null ? "" : parseFloat(item.isrRet).toFixed(4);
        this.formConcepto.ivaRetenido =
          item.ivaRet == null ? "" : parseFloat(item.ivaRet).toFixed(4);
      }

      this.formConcepto.impuestoLocalSeleccionado = {
        clave: item.claveImpuestoLocal == null ? "-1" : item.claveImpuestoLocal,
        descripcion:
          item.claveImpuestoLocal == null
            ? "Sin impuesto"
            : item.claveImpuestoLocal,
      };

      //revisar fixeds
      this.formConcepto.tasa = parseFloat(
        item.tasa == null ? null : item.tasa
      ).toFixed(0);
      this.formConcepto.ivaTrasladado = this.formConcepto.tasa;
      if (this.formConcepto.ivaTrasladado == NaN)
        this.formConcepto.ivaTrasladado = null;
      if (this.formConcepto.ivaTrasladado == "NaN")
        this.formConcepto.ivaTrasladado = null;

      this.formConcepto.tasaLocal = parseFloat(
        item.tasaLocal == null ? 0 : item.tasaLocal
      ).toFixed(2);

      if (
        this.formConcepto.tasaLocal != "" &&
        this.formConcepto.tasaLocal != "0.00"
      ) {
        this.formConcepto.checkImpuestoLocal = true;
        this.formConcepto.hayImpuestoLocal = true;
        this.formConcepto.hayImpuestoLocalSeleccionado = true;
      }

      if (
        this.formConcepto.impuestoLocalSeleccionado.descripcion ==
          "Sin impuesto" ||
        this.formConcepto.impuestoLocalSeleccionado.descripcion == "-1"
      ) {
        this.formConcepto.checkImpuestoLocal = false;
        this.formConcepto.hayImpuestoLocal = false;
        this.formConcepto.hayImpuestoLocalSeleccionado = false;
      }

      this.formConcepto.isEdit = true;
      this.dialogoConcepto = !this.dialogoConcepto;
    },
    checkedImpuestoLocal() {
      console.log("check impuesto local...");
      if (this.formConcepto.checkImpuestoLocal == true) {
        //si marca el checkbox
        this.formConcepto.hayImpuestoLocal = true;
        this.formConcepto.hayImpuestoLocalSeleccionado = false;
        console.log(this.formConcepto.impuestoLocalSeleccionado);

        if (this.formConcepto.modo == "alta") {
          this.formConcepto.tasaLocal = "";
          this.formConcepto.impuestoLocalSeleccionado = {
            clave: "-1",
            descripcion: "Sin impuesto",
          };
        }
      } else {
        this.formConcepto.hayImpuestoLocal = false;
        this.formConcepto.hayImpuestoLocalSeleccionado = false;
        console.log(this.formConcepto.impuestoLocalSeleccionado);
        this.formConcepto.tasaLocal = "";
        this.formConcepto.impuestoLocalSeleccionado = {
          clave: "-1",
          descripcion: "Sin impuesto",
        };
      }
    },
    checkedImpuestoRetenido() {
      console.log("check impuesto retenido...");
      this.formConcepto.isrRetenido = null;
      this.formConcepto.ivaRetenido = null;

      if (this.formConcepto.checkImpuestoRetenido == true) {
        //si marca el checkbox
        //this.formConcepto.hayImpuestoLocal=true;
        this.formConcepto.hayImpuestoRetenidoSeleccionado = true;
        //console.log(this.formConcepto.impuestoLocalSeleccionado);

        if (this.formConcepto.modo == "alta") {
          //  this.formConcepto.tasaLocal="";
          //  this.formConcepto.impuestoLocalSeleccionado={
          //     clave:'-1',
          //     descripcion:'Sin impuesto'
          // };
        }
      } else {
        //this.formConcepto.hayImpuestoLocal=false;
        this.formConcepto.hayImpuestoRetenidoSeleccionado = false;
        this.formConcepto.isrRetenido = null;
        this.formConcepto.ivaRetenido = null;
      }
    },
    checkedImpuestoTrasladado() {
      console.log("check impuesto trasladado...");
      this.formConcepto.ivaTrasladado = null;
      this.formConcepto.iepsTrasladado = null;

      if (this.formConcepto.checkImpuestoTrasladado == true) {
        //si marca el checkbox
        //this.formConcepto.hayImpuestoLocal=true;
        this.formConcepto.hayImpuestoTrasladadoSeleccionado = true;
        //console.log(this.formConcepto.impuestoLocalSeleccionado);

        if (this.formConcepto.modo == "alta") {
          //  this.formConcepto.tasaLocal="";
          //  this.formConcepto.impuestoLocalSeleccionado={
          //     clave:'-1',
          //     descripcion:'Sin impuesto'
          // };
        }
      } else {
        //this.formConcepto.hayImpuestoLocal=false;
        this.formConcepto.hayImpuestoTrasladadoSeleccionado = false;
        this.formConcepto.ivaTrasladado = null;
        this.formConcepto.iepsTrasladado = null;
      }
    },
    backCancel() {
      this.dialogoCatalogoCancelacion = false;
      this.dialogoConfirmaCancelarCDFI = false;
      this.cadMotivoCancelacionSeleccionado = "";
      this.formCancelInicial.motivoCancelacionSeleccionado = {
        clave: "0",
        descripcion: "Seleccione el motivo de cancelación",
      };
      this.formCancelInicial.ordenReplaceSeleccionado = {
        idCfdi: "00000",
        referencia: "Seleccione un número de orden",
      };
      this.cfdiReplace = {};
      this.cfdiACancelar = {};
    },
    backCatalogCancel() {
      this.dialogoCatalogoCancelacion = true;
      this.dialogoConfirmaCancelarCDFI = false;
      this.cadMotivoCancelacionSeleccionado = "";
    },
    goToCancel() {
      if (this.formCancelInicial.motivoCancelacionSeleccionado.clave == 0) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Por favor seleccione un motivo de cancelación";
        return;
      } else if (
        this.formCancelInicial.motivoCancelacionSeleccionado == 1 &&
        this.formCancelInicial.ordenReplaceSeleccionado.idCfdi == "00000"
      ) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack =
          "Por favor seleccione el número de orden del nuevo CFDI";
        return;
      } else {
        var aux = parseInt(
          this.formCancelInicial.motivoCancelacionSeleccionado
        );
        switch (aux) {
          case 1:
            this.cadMotivoCancelacionSeleccionado =
              "Comprobante emitido con errores con relacion";
            break;
          case 2:
            this.cadMotivoCancelacionSeleccionado =
              "Comprobante emitido con errores sin relacion";
            break;
          case 3:
            this.cadMotivoCancelacionSeleccionado =
              "No se llevo a cabo la operacion";
            break;
          case 4:
            this.cadMotivoCancelacionSeleccionado =
              "Operacion nominativa relacionada con una factura global";
            break;
        }
        if (this.cfdiACancelar.total > 1000) {
          this.monto1000 = true;
        } else {
          this.monto1000 = false;
        }
        this.dialogoCatalogoCancelacion = false;
        this.dialogoConfirmaCancelarCDFI = true;
      }
    },
    getDescripcionImpuesto(clave) {
      return "xxx";
      if (clave == null) return "";
      //this.catalogoImpuestos;
      var result = this.catalogoImpuestos.filter((obj) => {
        return obj.clave === clave;
      });
      console.log(result);
      return result[0].descripcion;
    },
    onCFDI() {
      console.log("on CFDI tab...");
      this.conceptosFinales = [];
    },
    Validar(){

    },
    async generarCFDI() {
      //this.$store.state.regimenFiscalUsuarioId = response.data.lista[0].idSat;
      //this.$store.state.regimenFiscalUsuarioDesc = response.data.lista[0].descripcionRegimen;
      var _emisor = {
        //regimenFiscal: this.$store.state.regimenFiscalUsuarioId, //this.$store.state.dashboard.idSat,
        //Change set regimen fiscal
        regimenFiscal: this.formInicial.formaRegimenSeleccionado,
        razonSocial: this.$store.state.dashboard.razonSocial,
        rfc: this.$store.state.cliente.rfc,
      };
      var regimenFiscalAux = "";
      if (this.formInicial.regimenClienteSeleccionado.clave == undefined) {
        regimenFiscalAux = this.formInicial.regimenClienteSeleccionado;
      } else {
        regimenFiscalAux = this.formInicial.regimenClienteSeleccionado.clave;
      };
      var usoCfdiaux = "";
      if (this.formAvanzado.usoCFDI.clave == undefined) {
        usoCfdiaux = this.formAvanzado.usoCFDI;
      } else {
        usoCfdiaux = this.formAvanzado.usoCFDI.clave;
      };
      var _receptor = {
        razonSocial: this.formInicial.razonSocial,
        rfc: this.formInicial.rfcSeleccionado,
        cp:this.formInicial.codigoPostal,
        emailPrincipal: this.formInicial.email,
        regimenFiscal: regimenFiscalAux,
        usoCfdi: usoCfdiaux
      };
      if(this.checkPublico){
        var periodicidadAux = "";
        if (this.formAvanzado.periodicidad.clave == undefined) {
          periodicidadAux = this.formAvanzado.periodicidad;
        } else {
          periodicidadAux = this.formAvanzado.periodicidad.clave;
        };
        var mesesAux = "";
        if (this.formAvanzado.meses.clave == undefined) {
          mesesAux = this.formAvanzado.meses;
        } else {
          mesesAux = this.formAvanzado.meses.clave;
        };
        var _informacionGlobal = {
          periodicidad: periodicidadAux,
          meses: mesesAux,
          anio: this.formAvanzado.anio
        }
      };
      
      var _conceptos = [];
      for (var i = 0; i < this.conceptosFinales.length; i++) {
        var _cantidad = parseFloat(this.conceptosFinales[i].cantidad);
        var _precioUnitario = parseFloat(
          this.conceptosFinales[i].valorUnitario.replace("$", "")
        );
        var _descuento = parseFloat(this.conceptosFinales[i].descuento);
        var _tasa = parseFloat(this.conceptosFinales[i].tasa);
        var _tasaAux = parseFloat(this.conceptosFinales[i].tasa);
        var _subtotal = parseFloat(
          _cantidad * _precioUnitario - _descuento
        ).toFixed(2);
        var _impuestoAplicado = parseFloat(_subtotal * _tasa).toFixed(2);

        var _tasaLocalAux = parseFloat(this.conceptosFinales[i].tasaLocal);
        var _impuestoLocalAplicado = parseFloat(
          _subtotal * _tasaLocalAux
        ).toFixed(2);

        _conceptos.push({
          nombreProductoServicio: this.conceptosFinales[i].productoServicio,
          numeroIdentificacion: this.conceptosFinales[i].identificadorSat,
          descripcion: this.conceptosFinales[i].descripcion,
          claveUnidad: this.conceptosFinales[i].claveUnidad
            .trim()
            .toUpperCase(),
          precioUnitario: _precioUnitario.toFixed(2),
          cantidad: _cantidad,
          subTotal: (parseFloat(_subtotal) + parseFloat(_descuento)).toFixed(2),
          descuento: parseFloat(_descuento).toFixed(2),
          taxObject: this.conceptosFinales[i].taxObject,
          impuestos: [
            {
              nombreImpuesto: "IVA",
              tasa:
                this.conceptosFinales[i].tasa == null
                  ? "remove"
                  : parseFloat(this.conceptosFinales[i].tasa).toFixed(4),
              base: _subtotal,
              total: parseFloat(
                (_subtotal * parseFloat(this.conceptosFinales[i].tasa)) / 100
              ).toFixed(2),
              isRetencion: "false",
            },
            {
              nombreImpuesto: "IEPS",
              tasa:
                this.conceptosFinales[i].ieps == null
                  ? "remove"
                  : parseFloat(this.conceptosFinales[i].ieps).toFixed(4),
              base: _subtotal,
              total: parseFloat(
                (_subtotal * parseFloat(this.conceptosFinales[i].ieps)) / 100
              ).toFixed(2),
              isRetencion: "false",
            },
            {
              nombreImpuesto: "ISR",
              tasa:
                this.conceptosFinales[i].isrRet == null
                  ? "remove"
                  : parseFloat(this.conceptosFinales[i].isrRet).toFixed(4),
              base: _subtotal,
              total: parseFloat(
                (_subtotal * parseFloat(this.conceptosFinales[i].isrRet)) / 100
              ).toFixed(2),
              isRetencion: "true",
            },
            {
              nombreImpuesto: "IVA",
              tasa:
                this.conceptosFinales[i].ivaRet == null
                  ? "remove"
                  : parseFloat(this.conceptosFinales[i].ivaRet).toFixed(4),
              base: _subtotal,
              total: parseFloat(
                (_subtotal * parseFloat(this.conceptosFinales[i].ivaRet)) / 100
              ).toFixed(2),
              isRetencion: "true",
            },
            {
              nombreImpuesto: "ISH",
              tasa:
                this.conceptosFinales[i].tasaLocal == null
                  ? "remove"
                  : parseFloat(this.conceptosFinales[i].tasaLocal).toFixed(2),
              base: _subtotal,
              total: parseFloat(
                (_subtotal * parseFloat(this.conceptosFinales[i].tasaLocal)) /
                  100
              ).toFixed(2),
              isRetencion: "false",
            },
          ],
        });
      }

      try {
        var tipocfdi = this.isPlatform
          ? "I"
          : this.catalogoTiposComprobantes[0].descripcion;
        this.loadingCFDI = true;
        console.log("this.formInicial");
        console.log(this.formInicial);
        var pagoSeleccionadox = "";
        if (this.formInicial.formaPagoSeleccionado.clave == undefined) {
          pagoSeleccionadox = this.formInicial.formaPagoSeleccionado;
        } else {
          pagoSeleccionadox = this.formInicial.formaPagoSeleccionado.clave;
        }

        for (var jj = 0; jj < _conceptos.length; jj++) {
          var _impuestosAux = _conceptos[jj].impuestos.filter((obj) => {
            return obj.tasa != "remove";
          });
          _conceptos[jj].impuestos = _impuestosAux;
           //Validar taxObject 01 - No objeto de impuesto | 02 - (Sí objeto de impuesto)
          if (_conceptos[jj].impuestos.length === 0) 
            _conceptos[jj].taxObject = "01"
           else 
            _conceptos[jj].taxObject = "02";
          console.log("objeto de impuesto: " + this.taxObject);
        }

        

        var tipoFacturaAux = "";
        if (this.tipoFactura == "Ingreso") {
          tipoFacturaAux = "I";
        } else if (this.tipoFactura == "Traslado") {
          tipoFacturaAux = "T";
        } else {
          tipoFacturaAux = this.tipoFactura;
        }
        //Guardar cliente frecuente
        if(this.checkGuardarCliente) this.saveClientes();
        
        //Emitir CFDI 4.0
        const response = await operations.emitir({
          usoCfdi: usoCfdiaux, //ok
          tipoCfdi: this.checkPlataformas == true ? "I" : tipoFacturaAux, //ok
          lugarExpedicion: this.$store.state.dashboard.lugarExpedicion, //ok
          formaPago: pagoSeleccionadox, //this.formInicial.formaPagoSeleccionado.clave,//ok
          metodoPago:
            this.formAvanzado.metodoPago.clave == undefined
              ? this.formAvanzado.metodoPago
              : this.formAvanzado.metodoPago.clave, //ok
          moneda:
            this.formAvanzado.moneda.clave == undefined
              ? this.formAvanzado.moneda
              : this.formAvanzado.moneda.clave,
          plataformaTecnologica:
            this.checkPlataformas == false ? "UTAXME" : this.idTipoFactura, //new
          observaciones: "", //ok
          bancoPago: "", //ok
          cuentaBancaria: "0000", //ok
          numeroOrden: this.formInicial.noOrden,

          subTotal: (
            parseFloat(this.getSubtotalCFDI) +
            parseFloat(this.getDescuentoTotal)
          ).toFixed(2), //this.getSubtotalCFDI,
          total: this.getTotalCFDI,

          condicionesPago: this.formAvanzado.condiciones, //ok
          emisor: _emisor,
          receptor: _receptor,
          informacionGlobal: _informacionGlobal,
          conceptos: _conceptos
        });

        console.log(response);

        if (response.status == "200") {
          if (response.data.codigo == "501") {
            console.log("CFDI 501 error...");
            this.snackbar2 = true;
            this.colorSnack = "error";
            this.mensajeSnack = response.data.mensaje;
            this.dialogoCDFIMensaje = true;
          } else if (response.data.codigo == "500") {
            console.log("CFDI 500 error...");
            this.snackbar2 = true;
            this.colorSnack = "error";
            this.mensajeSnack = response.data.mensaje;
            this.dialogoCDFIMensaje = true;
          }
           else {
            console.log("CFDI generado OK...");
            this.snackbar2 = true;
            this.colorSnack = "green";
            this.mensajeSnack = "CFDI generado correctamente";
            this.dialogoConfirmaCDFI = false;
            this.dialogoCDFIMensaje = true;
            //Clean all fields
            this.clearForm();
          }
        }

        this.loadingCFDI = false;
        this.dialogoConfirmaCDFI = false;
      } catch (error) {
        console.log(error);
        console.log(error.response);

        this.loadingCFDI = false;
        this.dialogoConfirmaCDFI = false;
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Error al generar la factura intentelo más tarde";
      }
    },
    confirmarCFDI() {
      this.$refs.formInicial.validate();
      if (this.conceptosFinales.length <= 0) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack =
          "Agregue al menos un concepto para generar una factura";
        return;
      }
      if (!this.formInicial.formValid) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Verifique los campos faltantes";
        return;
      }
      for (var i = 0; i < this.conceptosFinales.length; ++i) {
        if (this.conceptosFinales[i].importe <= 0) {
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack =
            "No se permite facturar importes en cero o negativos, por favor rectifique ";
          return;
          break;
        }
      }
      //Change valida regimen
      if (this.formInicial.formaRegimenSeleccionado.clave == 0) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Por favor seleccione un régimen fiscal";
        return;
      }
      if (this.getTotalCFDI == 0.0) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "El monto de la factura debe ser mayor a cero";
        return;
      }
      //this.conceptosFinales[]
      //claveImpuestoLocal
      //tasaLocal
      var bLocal = false;

      var tasaLocal = this.conceptosFinales[0].tasaLocal;
      //var sigTasaLocal='';
      for (var x = 0; x < this.conceptosFinales.length; x++) {
        //sigTasaLocal=this.conceptosFinales[x].tasaLocal;
        if (this.conceptosFinales[x].tasaLocal == 0) continue;

        if (this.conceptosFinales[x].tasaLocal != tasaLocal) {
          bLocal = true;
          break;
        }
      }

      if (bLocal) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack =
          "En el CFDI no pueden haber más de 1 concepto con el impuesto local con diferentes tasas";
        return;
      }

      var bValida = false;
      var mensajeError = "";
      if (this.tipoFactura == "AIRBNB") {
        for (var xx = 0; xx < this.conceptosFinales.length; xx++) {
          if (this.conceptosFinales[xx].tasa == null) {
            mensajeError =
              "Para la plataforma de AIRBNB, no se permite generar un CFDI sin IVA trasladado.";
            bValida = true;
            break;
          }

          if (this.conceptosFinales[xx].tasa == 0) {
            mensajeError =
              "Para la plataforma de AIRBNB, no se permite generar un CFDI con IVA tasa 0%.";
            bValida = true;
            break;
          }

          if (this.conceptosFinales[xx].ieps != null) {
            mensajeError =
              "Para la pataforma de ARIBNB, no se permite agregar el impuesto IEPS.";
            bValida = true;
            break;
          }

          if (this.conceptosFinales[xx].tasa == "") {
            mensajeError =
              "Para la plataforma de AIRBNB, no se permite generar un CFDI sin IVA trasladado.";
            bValida = true;
            break;
          }
        }

        if (bValida) {
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack = mensajeError;
          return;
        }
      }

      this.dialogoConfirmaCDFI = true;
    },
    async borrarConceptoFactura() {
      console.log("borrarConceptoFactura");
      console.log(this.conceptoABorrar);

      try {
        const response = await operations.eliminarConcepto({
          idConcepto: this.conceptoABorrar.idConceptoCliente,
        });
        console.log(response);

        if (response.status == "200") {
          console.log("concepto eliminado...");
          this.recargaConceptos();
          this.snackbar2 = true;
          this.colorSnack = "green";
          this.mensajeSnack = "Concepto eliminado correctamente";
          this.searchConcepto = null;
          this.dialogoConfirmaBorrarConcepto = false;
        }
      } catch (error) {
        console.log(error.response);
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Error al  eliminar el concepto: " + error.response;
      }
    },
    agregarConceptoFactura(item) {
      console.log("agregarConceptoFactura");
      console.log(item);

      var _taxObject = "";
      this.conceptosFinales.push({
        cantidad: item.cantidad,
        claveImpuestoLocal: item.claveImpuestoLocal,
        claveImpuestoSat: item.claveImpuestoSat,
        claveImpuestoSatDescripcion: this.getDescripcionImpuesto(
          item.claveImpuestoSat
        ),
        claveUnidad: item.claveUnidad.trim().toUpperCase(),
        consecutivo: item.consecutivo,
        unidad: item.unidad,
        descripcion: item.descripcion,
        descuento: item.descuento,
        taxObject: _taxObject,
        fechaRegistro: item.fechaRegistro,
        idCliente: item.idCliente,
        idConceptoCliente: item.idConceptoCliente,
        identificadorSat: item.identificadorSat,
        productoServicio: item.productoServicio,
        rfc: item.rfc,
        tasa: item.tasa == null ? null : parseFloat(item.tasa),
        tasaLocal: item.tasaLocal == null ? null : parseFloat(item.tasaLocal),

        ieps: item.ieps == null ? null : parseFloat(item.ieps),
        isrRet: item.isrRet == null ? null : parseFloat(item.isrRet),
        ivaRet: item.ivaRet == null ? null : parseFloat(item.ivaRet),

        cantidad: item.cantidad,
        descuento: item.descuento,
        valorUnitario: item.valorUnitario.replace("$", ""),
        importe: item.importe,
        impuestoSeleccionado: item.impuestoSeleccionado,
        impuestoSeleccionadoDescripcion: item.impuestoSeleccionadoDescripcion,
      });

      //this.impuestos

      console.log("arr Conceptos final");
      console.log(this.conceptosFinales);
      this.dialogoConceptos = false;
      this.showConceptos = true;
    },
    selectImpuestoLocal() {
      console.log(this.formConcepto.impuestoLocalSeleccionado);

      var result = this.catalogoImpuestosLocales.filter((obj) => {
        return obj.clave === this.formConcepto.impuestoLocalSeleccionado;
      });
      console.log(result);
      this.formConcepto.impuestoLocalSeleccionadoDescripcion =
        result[0].descripcion;
      console.log(".........");
      console.log(this.formConcepto.impuestoLocalSeleccionadoDescripcion);

      try {
        if (this.formConcepto.impuestoLocalSeleccionado == "-1") {
          this.formConcepto.hayImpuestoLocalSeleccionado = false;
          this.formConcepto.tasaLocal = null;
        } else {
          this.formConcepto.hayImpuestoLocalSeleccionado = true;
          this.formConcepto.tasaLocal = "0.00";
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectImpuesto() {
      //formConcepto.hayImpuesto
      console.log(this.formConcepto.impuestoSeleccionado);

      var result = this.catalogoImpuestos.filter((obj) => {
        return obj.clave === this.formConcepto.impuestoSeleccionado;
      });
      console.log(result);
      this.formConcepto.impuestoSeleccionadoDescripcion = result[0].descripcion;
      console.log(this.formConcepto.impuestoSeleccionadoDescripcion);

      if (this.formConcepto.impuestoSeleccionado == "-1") {
        this.formConcepto.hayImpuesto = false;
        this.formConcepto.tasa = null;
      } else {
        this.formConcepto.hayImpuesto = true;
        this.formConcepto.tasa = "0.00";
      }
    },
    selectConcepto() {
      console.log(this.formConcepto.conceptoSeleccionado);
      console.log(this.formConcepto.arrConceptos);

      var result = this.formConcepto.arrConceptos.filter((obj) => {
        return obj.identificador === this.formConcepto.conceptoSeleccionado;
      });

      console.log(result);
      this.formConcepto.identificador = result[0].identificador;
      this.formConcepto.descripcion = result[0].descripcion;
      this.formConcepto.unidad = result[0].unidad;
      this.formConcepto.claveUnidad = result[0].claveUnidad
        .trim()
        .toUpperCase();
      this.formConcepto.unitario = "0.00";
    },
    changeDiasCredito() {
      var fechaCondiciones = new Date();

      if (this.formAvanzado.diasCredito == "0") {
        this.formAvanzado.condiciones = "PAGO EN UNA SOLA EXHIBICIÓN";
      } else if (this.formAvanzado.diasCredito == "15") {
        fechaCondiciones.setDate(fechaCondiciones.getDate() + 15);
        this.formAvanzado.condiciones =
          "Páguese a más tardar el " +
          this.formattedDate(fechaCondiciones) +
          " PAGO EN UNA SOLA EXHIBICIÓN.";
      } else if (this.formAvanzado.diasCredito == "30") {
        fechaCondiciones.setDate(fechaCondiciones.getDate() + 30);
        this.formAvanzado.condiciones =
          "Páguese a más tardar el " +
          this.formattedDate(fechaCondiciones) +
          " PAGO EN UNA SOLA EXHIBICIÓN.";
      } else if (this.formAvanzado.diasCredito == "45") {
        fechaCondiciones.setDate(fechaCondiciones.getDate() + 45);
        this.formAvanzado.condiciones =
          "Páguese a más tardar el " +
          this.formattedDate(fechaCondiciones) +
          " PAGO EN UNA SOLA EXHIBICIÓN.";
      }
    },
    formattedDate(d = new Date()) {
      let month = String(d.getMonth() + 1);
      let day = String(d.getDate());
      const year = String(d.getFullYear());

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return `${day}/${month}/${year}`;
    },
    setMayusculas: function (tecla) {
      this.formInicial.searchRFC = this.formInicial.searchRFC.toUpperCase();
    },
    setMayusculasRazon: function (tecla) {
      this.formInicial.razonSocial = this.formInicial.razonSocial.toUpperCase();
    },
    validaRFCCaracteres: function (tecla) {
      var texto = tecla.key;
      var reg = /^[A-Za-z0-9]+$/g;
      if (reg.test(texto)) {        
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    cleanText: function (tecla) {
      if (this.formConcepto.unitario == "0.00") {
        this.formConcepto.unitario = "";
      }
    },
    cleanTextTasa: function (tecla) {
      if (this.formConcepto.tasa == "0") {
        this.formConcepto.tasa = "";
      }
    },
    cleanTextTasaLocal: function (tecla) {
      if (this.formConcepto.tasaLocal == "0.00") {
        this.formConcepto.tasaLocal = "";
      }
    },
    defaultText: function (tecla) {
      if (this.formConcepto.unitario.trim() == "") {
        this.formConcepto.unitario = "0.00";
      } else {
        this.formConcepto.unitario = parseFloat(
          this.formConcepto.unitario
        ).toFixed(2);
      }
    },
    defaultTextTasa: function (tecla) {
      if (this.formConcepto.tasa.trim() == "") {
        this.formConcepto.tasa = "0";
      } else {
        this.formConcepto.tasa = parseFloat(this.formConcepto.tasa).toFixed(0);
      }
    },
    defaultTextTasaLocal: function (tecla) {
      try {
        if (this.formConcepto.tasaLocal.trim() == "") {
          this.formConcepto.tasaLocal = "0.00";
        } else {
          this.formConcepto.tasaLocal = parseFloat(
            this.formConcepto.tasaLocal
          ).toFixed(2);
        }
      } catch (error) {
        this.formConcepto.tasaLocal = "0.00";
      }
    },
    defaultTextIsrRetenido: function (tecla) {
      try {
        if (this.formConcepto.isrRetenido == 0) {
          this.formConcepto.isrRetenido = "";
          return;
        }

        if (this.formConcepto.isrRetenido.trim() != "") {
          this.formConcepto.isrRetenido = parseFloat(
            this.formConcepto.isrRetenido
          ).toFixed(4);
        }
      } catch (error) {
        //this.formConcepto.isrRetenido='0.00';
      }
    },
    defaultTextTasaIepsTrasladado: function (tecla) {
      try {
        if (this.formConcepto.iepsTrasladado.trim() == "") {
          this.formConcepto.iepsTrasladado = "";
          return;
        }

        if (this.formConcepto.iepsTrasladado.trim() != "") {
          this.formConcepto.iepsTrasladado = parseFloat(
            this.formConcepto.iepsTrasladado
          ).toFixed(4);
        }
      } catch (error) {
        //this.formConcepto.isrRetenido='0.00';
      }
    },
    defaultTextTasaIvaRetenido: function (tecla) {
      try {
        if (this.formConcepto.ivaRetenido.trim() == "") {
          this.formConcepto.ivaRetenido = "";
          return;
        }

        if (this.formConcepto.ivaRetenido.trim() != "") {
          this.formConcepto.ivaRetenido = parseFloat(
            this.formConcepto.ivaRetenido
          ).toFixed(4);
        }
      } catch (error) {
        //this.formConcepto.isrRetenido='0.00';
      }
    },
    defaultTextTasaIvaTrasladado: function (tecla) {
      try {
        if (this.formConcepto.ivaTrasladado.trim() == "") {
          this.formConcepto.ivaTrasladado = "0";
        }
      } catch (error) {
        this.formConcepto.ivaTrasladado = "0";
      }
    },

    caracteresMoneda: function (tecla) {
      var texto = tecla.key;

      var reg = /^[$0-9,.]+$/g;
      if (reg.test(texto)) {
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    async agregarConcepto() {

      if (
        this.formConcepto.isrRetenido == "0" ||
        this.formConcepto.isrRetenido == "0.0000"
      ) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.formConcepto.isrRetenido = "";
        this.mensajeSnack = "El ISR retenido no puede ser 0";
        return;
      }
      if (
        this.formConcepto.iepsTrasladado == "0" ||
        this.formConcepto.iepsTrasladado == "0.0000"
      ) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.formConcepto.iepsTrasladado = "";
        this.mensajeSnack = "El IEPS trasladado no puede ser 0";
        return;
      }

      if (this.formConcepto.unitario == "0.00") {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.formConcepto.unitario = "";
        this.mensajeSnack = "El valor unitario debe ser mayor a 0";
        return;
      }

      if (
        this.formConcepto.tasaLocal == "0.0000" &&
        this.formConcepto.hayImpuestoLocal
      ) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.formConcepto.tasaLocal = "";
        this.mensajeSnack = "La tasa local del impuesto debe ser mayor a 0";
        return;
      }

      this.$refs.formConcepto.validate();
      if (!this.formConcepto.formValid) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Verifique los campos faltantes";
        return;
      }
      console.log("agregarConcepto....");
      console.log(this.formConcepto);
      if (this.formConcepto.modo == "alta") {
        if (
          this.formConcepto.tasaLocal == "" ||
          this.formConcepto.tasaLocal == "0.00"
        ) {
          this.formConcepto.tasaLocal = null;
        }

        if (this.formConcepto.hayImpuestoTrasladadoSeleccionado == false) {
          this.formConcepto.ivaTrasladado = null;
          this.formConcepto.iepsTrasladado = null;
        }
        if (this.formConcepto.hayImpuestoRetenidoSeleccionado == false) {
          this.formConcepto.isrRetenido = null;
          this.formConcepto.ivaRetenido = null;
        }

        try {
          this.deshabilitadoGuardarConceptoLoading = true;

          try {
            if (this.formConcepto.ivaTrasladado == "NaN")
              this.formConcepto.ivaTrasladado = null;
            if (this.formConcepto.ivaTrasladado == NaN)
              this.formConcepto.ivaTrasladado = null;
            if (this.formConcepto.ivaTrasladado == "")
              this.formConcepto.ivaTrasladado = null;

            if (this.formConcepto.isrRetenido == NaN)
              this.formConcepto.isrRetenido = null;
            if (this.formConcepto.isrRetenido == "")
              this.formConcepto.isrRetenido = null;
            if (this.formConcepto.iepsTrasladado == NaN)
              this.formConcepto.iepsTrasladado = null;
            if (this.formConcepto.iepsTrasladado == "")
              this.formConcepto.iepsTrasladado = null;
          } catch (error) {}

          try {
            if (this.formConcepto.impuestoLocalSeleccionado.clave == "-1")
              this.formConcepto.impuestoLocalSeleccionado = null;
          } catch (error) {}

          const response = await operations.agregarConcepto({
            productoServicio: this.formConcepto.productoServicio.toUpperCase(),
            identificadorSat: this.formConcepto.identificador, //ok
            descripcion: this.formConcepto.descripcion.trim().toUpperCase(), //ok
            claveUnidad: this.formConcepto.claveUnidad.trim().toUpperCase(), //ok
            claveImpuestoSat: "002", //ok  fijo
            tasa:
              this.formConcepto.ivaTrasladado == null
                ? null
                : parseFloat(this.formConcepto.ivaTrasladado).toFixed(0), //ok iva trasladado
            rfc: this.$store.state.cliente.rfc, //ok
            unidad: this.formConcepto.unidad,
            valorUnitario: this.formConcepto.unitario.trim().replace("$", ""),
            claveImpuestoLocal: this.formConcepto.impuestoLocalSeleccionado,
            tasaLocal:
              this.formConcepto.tasaLocal == null
                ? null
                : parseFloat(this.formConcepto.tasaLocal).toFixed(2),
            ivaRet:
              this.formConcepto.ivaRetenido == null
                ? null
                : parseFloat(this.formConcepto.ivaRetenido).toFixed(4), //new
            isrRet:
              this.formConcepto.isrRetenido == null
                ? null
                : parseFloat(this.formConcepto.isrRetenido).toFixed(4), //new
            ieps:
              this.formConcepto.iepsTrasladado == null
                ? null
                : parseFloat(this.formConcepto.iepsTrasladado).toFixed(4), //new
            idConceptoCliente: 0,
          });
          console.log(response);
          this.deshabilitadoGuardarConceptoLoading = false;
          if (response.status == "200") {
            this.recargaConceptos();
            this.searchConcepto = this.formConcepto.productoServicio;
            this.dialogoConcepto = false;
            console.log("ok catalogos cargados...");
            this.snackbar2 = true;
            this.colorSnack = "green";
            this.mensajeSnack = "Concepto agregado correctamente";
          }
        } catch (error) {
          console.log(error);
          this.deshabilitadoGuardarConceptoLoading = false;
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack = "Error al agregar el concepto: " + error;
        }
      } else {
        //EDIT CONCEPT
        try {
          var cveImpuestoSat = "";
          //     if(this.formConcepto.impuestoSeleccionado.clave==undefined || this.formConcepto.impuestoSeleccionado.clave==null)
          //        cveImpuestoSat = this.formConcepto.impuestoSeleccionado;
          //    else
          //       cveImpuestoSat = this.formConcepto.impuestoSeleccionado.clave;

          //    if(cveImpuestoSat.clave==null && cveImpuestoSat.descripcion==''){
          //        this.formConcepto.tasa=null;
          //        cveImpuestoSat = null;
          //    }

          // if(cveImpuestoSat=='-1' ){
          //     cveImpuestoSat = null;
          // }

          var cveImpuestoLocal = "";
          if (this.formConcepto.impuestoLocalSeleccionado.clave == undefined)
            cveImpuestoLocal = this.formConcepto.impuestoLocalSeleccionado;
          else
            cveImpuestoLocal =
              this.formConcepto.impuestoLocalSeleccionado.clave;

          //    if(this.formConcepto.impuestoLocalSeleccionado.clave=='-1' ){
          //      cveImpuestoLocal = null;
          //      this.formConcepto.tasaLocal=null;
          //    }

          if (this.formConcepto.hayImpuestoLocal == false) {
            cveImpuestoLocal = null;
            this.formConcepto.tasaLocal = null;
          }

          if (cveImpuestoLocal == undefined) {
            cveImpuestoLocal = null;
          }

          if (cveImpuestoSat == undefined) {
            cveImpuestoSat = null;
          }

          //    if(this.formConcepto.tasa=="" ){
          //      this.formConcepto.tasa = null;
          //    }

          //    if(this.formConcepto.tasa==0 ){
          //      this.formConcepto.tasa = null;
          //    }

          if (this.formConcepto.tasaLocal == "") {
            this.formConcepto.tasaLocal = null;
          }
          if (this.formConcepto.ivaTrasladado == "NaN")
            this.formConcepto.ivaTrasladado = null;
          if (this.formConcepto.ivaTrasladado == NaN)
            this.formConcepto.ivaTrasladado = null;
          if (this.formConcepto.ivaTrasladado == "")
            this.formConcepto.ivaTrasladado = null;

          if (this.formConcepto.iepsTrasladado == NaN)
            this.formConcepto.iepsTrasladado = null;
          if (this.formConcepto.iepsTrasladado == "")
            this.formConcepto.iepsTrasladado = null;

          if (this.formConcepto.isrRetenido == NaN)
            this.formConcepto.isrRetenido = null;
          if (this.formConcepto.isrRetenido == "")
            this.formConcepto.isrRetenido = null;

          if (this.formConcepto.ivaRetenido == NaN)
            this.formConcepto.ivaRetenido = null;
          if (this.formConcepto.ivaRetenido == "")
            this.formConcepto.ivaRetenido = null;

          try {
            if (this.formConcepto.impuestoLocalSeleccionado.clave == "-1")
              this.formConcepto.impuestoLocalSeleccionado = null;
          } catch (error) {}

          this.deshabilitadoGuardarConceptoLoading = true;
          const response = await operations.actualizarConcepto({
            productoServicio: this.formConcepto.productoServicio.toUpperCase(),
            identificadorSat: this.formConcepto.identificador, //ok
            descripcion: this.formConcepto.descripcion.trim().toUpperCase(), //ok
            claveUnidad: this.formConcepto.claveUnidad.trim().toUpperCase(), //ok
            claveImpuestoSat: "002", //ok
            tasa:
              this.formConcepto.ivaTrasladado == null
                ? null
                : parseFloat(this.formConcepto.ivaTrasladado).toFixed(0), //ok iva trasladado
            rfc: this.$store.state.cliente.rfc, //ok
            unidad: this.formConcepto.unidad,
            valorUnitario: this.formConcepto.unitario.trim().replace("$", ""),
            claveImpuestoLocal: cveImpuestoLocal, //this.formConcepto.impuestoLocalSeleccionado,
            tasaLocal:
              this.formConcepto.tasaLocal == null
                ? null
                : parseFloat(this.formConcepto.tasaLocal).toFixed(2),
            ivaRet:
              this.formConcepto.ivaRetenido == null
                ? null
                : parseFloat(this.formConcepto.ivaRetenido).toFixed(4), //new
            isrRet:
              this.formConcepto.isrRetenido == null
                ? null
                : parseFloat(this.formConcepto.isrRetenido).toFixed(4), //new
            ieps:
              this.formConcepto.iepsTrasladado == null
                ? null
                : parseFloat(this.formConcepto.iepsTrasladado).toFixed(4), //new

            idConceptoCliente: this.formConcepto.idConceptoCliente,
          });
          console.log(response);

          if (response.status == "200") {
            this.recargaConceptos();
            this.deshabilitadoGuardarConceptoLoading = false;
            this.dialogoConcepto = false;
            this.searchConcepto = this.formConcepto.productoServicio;
            console.log("ok catalogos recargados...");
            this.snackbar2 = true;
            this.colorSnack = "green";
            this.mensajeSnack = "Concepto actualizado correctamente";
          }
          this.deshabilitadoGuardarConceptoLoading = false;
        } catch (error) {
          this.deshabilitadoGuardarConceptoLoading = false;
          console.log(error);
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack = "Error al agregar el concepto: " + error;
        }
      }
    },
    cancelaConcepto() {
      //TODO
      //clean concepto form fields....
      this.deshabilitadoGuardarConcepto = true;
      this.dialogoConcepto = false;
    },
    caracteresTasa: function (tecla) {
      var texto = tecla.key;

      var reg = /^[0-9 ]+$/g;
      if (reg.test(texto)) {
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    caracteresTasaLocal: function (tecla) {
      var texto = tecla.key;

      var reg = /^[0-9. ]+$/g;
      if (reg.test(texto)) {
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    caracteresSinDecimal: function (tecla) {
      var texto = tecla.key;

      var reg = /^[0-9]+$/g;
      if (reg.test(texto)) {
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    caracteresDecimal: function (tecla) {
      var texto = tecla.key;

      var reg = /^[0-9. ]+$/g;
      if (reg.test(texto)) {
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    caracteresDecimalCantidad: function (tecla) {
      var texto = tecla.key;
      // console.log(this.conceptosFinales);

      var reg = /^[0-9. ]+$/g;
      //var reg =/^\d+(\.\d{1,2})?$/g;
      // var reg = /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/g;
      if (reg.test(texto)) {
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    caracteresNumerico: function (tecla) {
      var texto = tecla.key;

      var reg = /^[0-9]+$/g;
      if (reg.test(texto)) {
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    caracteresValidos: function (tecla) {
      var texto = tecla.key;

      var reg = /^[A-Za-z0-9-. ]+$/g;
      if (reg.test(texto)) {
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    caracteresValidosEnie: function (tecla) {
      var texto = tecla.key;

      var reg = /^[ñÑA-Za-z0-9-. ]+$/g;
      if (reg.test(texto)) {
        return true;
      } else {
        tecla.preventDefault();
        return false;
      }
    },
    async CatalogosSatForm() {
      console.log("catalogosCFDI....");
      try {
        const response = await operations.catalogosSatForm({
          rfc: this.$store.state.cliente.rfc,
        });
        console.log(response);

        if (response.status == "200") {
          //combo 1
          this.$store.state.catalogoPlataformas =
            response.data.catalogoPlataformas;

          this.catalogoTiposComprobantes =
            response.data.catalogoTiposComprobantes;

          this.catalogoFormaPago = response.data.catalogoFormaPago;
          this.catalogoImpuestos = response.data.catalogoImpuestos;
          this.catalogoImpuestosLocales =
            response.data.catalogoImpuestosLocales;
          //this.catalogoImpuestos.push({clave:'-1',descripcion:'Seleccionar...'});
          //this.catalogoImpuestos.insert(0, {clave:'-1',descripcion:'Seleccionar...'});
          this.catalogoImpuestos.splice(0, 0, {
            clave: "-1",
            descripcion: "Sin impuesto",
          });
          this.catalogoImpuestosLocales.splice(0, 0, {
            clave: "-1",
            descripcion: "Sin impuesto",
          });

          this.catalogoMetodoPago = response.data.catalogoMetodoPago;

          this.catalogoUsoCfdi = response.data.catalogoUsoCfdi;
          //this.catalogoCancelacion = this.catalogoFormaPago;
          this.catalogoCancelacion = response.data.catalogoMotivoCancelacion;

          //Llenado de catálodos CFDI 4.0
          this.catalogoPeriodicidad = response.data.catalogoSatPeriodicidad;
          this.catalogoMeses = response.data.catalogoSatMeses;
          this.catalogoSatRegimenFiscales = response.data.catalogoSatRegimenFiscales;

          console.log("ok catalogos cargados...");
        }
      } catch (error) {
        console.log(error.response);
      }
    },
    updatePeriodicidad(){
      const date = new Date();
      this.formAvanzado.anioActual = date.getFullYear();
      this.formAvanzado.anio = this.formAvanzado.anioActual.toString();
      this.formAvanzado.mesActual = date.getMonth();
      this.formAvanzado.meses = this.catalogoMeses.find(mes => mes.clave ===
        this.catalogoMeses[this.formAvanzado.mesActual].clave);
    },
    async findConceptos() {
      this.formConcepto.loader = "loading";
      this.formConcepto.loadingBusqueda = true;
      console.log("CatalogoProductosSAT....");
      console.log(this.formConcepto.filtro);
      this.formConcepto.arrConceptos = [];
      try {
        const response = await operations.catalogoProductosSAT({
          filtro: this.formConcepto.filtro,
        });
        console.log(response);

        if (response.status == "200") {
          this.deshabilitadoGuardarConcepto = false;
          //combo 1
          this.formConcepto.arrConceptos = response.data.catalogoProductos;

          console.log("ok catalogo producto cargado...");
          this.formConcepto.loader = null;
          this.formConcepto.loadingBusqueda = false;
          this.snackbar2 = true;
          this.colorSnack = "green";

          if (this.formConcepto.arrConceptos.length > 0) {
            this.mensajeSnack =
              this.formConcepto.arrConceptos.length +
              " concepto(s) encontrado(s). Seleccione un concepto";
            document.getElementById("cboConceptos").click();
          } else {
            this.mensajeSnack =
              this.formConcepto.arrConceptos.length +
              " concepto(s) encontrado(s).";
          }
        } else {
          this.deshabilitadoGuardarConcepto = true;
        }
      } catch (error) {
        this.deshabilitadoGuardarConcepto = true;
        console.log("Error al obtener los catalogos de productos..");
        this.formConcepto.loader = null;
        this.formConcepto.loadingBusqueda = false;
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Error al consultar: " + error.response;
        console.log(error.response);
      }
    },
    async getClientesList(){
      console.log("obteniendo lista de clientes frecuentes...");
      try {
        const response = await operations.getClientesList({
          rfcCliente: this.$store.state.cliente.rfc,
        });
        console.log(response);
        if (response.status == "200") {
          var arrClientes = [];
          this.formInicial.clientesList = [];
          this.formInicial.rfcClientesList = [];
          if (response.data.clientes != null){
            for (var i = 0; i < response.data.clientes.length; i++) {
              arrClientes.push({
                idEmisionServicios:
                  response.data.clientes[i].idEmisionServicios,
                idCliente:
                  response.data.clientes[i].idCliente,
                rfcCliente:
                  response.data.clientes[i].rfcCliente.trim().toUpperCase(),
                rfcReceptor:
                  response.data.clientes[i].rfcReceptor.trim().toUpperCase(),
                razonSocial:
                  response.data.clientes[i].razonSocial.trim().toUpperCase(),
                correoElectronico:
                  response.data.clientes[i].correoElectronico.trim(),
                regimenFiscal:
                  response.data.clientes[i].regimenFiscal,
                codigoPostal:
                  response.data.clientes[i].codigoPostal,
              });
              this.formInicial.rfcClientesList.push(
                arrClientes[i].rfcReceptor
              );
            } console.log("clientes frecuentes cargados ..");
          } else console.log("No hay clientes frecuentes ..");
          this.formInicial.clientesList = arrClientes;
        }
      } catch (error) {
        console.log("Error al obtener los clientes frecuentes ...");
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Error al consultar los clientes frecuentes.";
        console.log(error.response);
      }
    },
    async saveClientes(){
      console.log("Guardando nuevo cliente frecuente...");
      try {
        const response = await operations.saveClientes({
          rfcCliente: this.$store.state.cliente.rfc,
          rfcReceptor: this.formInicial.rfcSeleccionado,
          razonSocial: this.formInicial.razonSocial,
          correoElectronico: this.formInicial.email,
          regimenFiscal: this.formInicial.regimenClienteSeleccionado,
          codigoPostal: this.formInicial.codigoPostal,
        });
        console.log(response);
        if (response.status == "200") {
          console.log("Cliente frecuente guardado correctamente");
          this.snackbar3 = true;
          this.colorSnack2 = "green";
          this.mensajeSnack2 = "Cliente frecuente guardado";
        }
      } catch (error) {
        console.log("Error al guardar el cliente frecuente: " + 
          this.formInicial.rfcSeleccionado + " " + this.formInicial.razonSocial);
        this.snackbar3 = true;
        this.colorSnack2 = "red";
        this.mensajeSnack2 = "Error al guardar el cliente frecuente: " + this.formInicial.rfcSeleccionado;
        console.log(error.response);
      }
      this.getClientesList();
    },
    async deleteClientes(){
      console.log("Guardando nuevo cliente frecuente...");
      this.formInicial.loadingBorrarCliente = true;
      try {
        const response = await operations.deleteClientes({
          idEmisionServicios: this.formInicial.idClienteFrecuente
        });
        console.log(response);
        if (response.status == "200") {
          console.log("Cliente frecuente eliminado correctamente");
          this.formInicial.rfcSeleccionado = "";
          this.getClientesList();
          this.actualizaRfc();
          this.formInicial.loadingBorrarCliente = false;
          this.dialogoBorrarCliente = false;
          this.snackbar2 = true;
          this.colorSnack = "green";
          this.mensajeSnack = "Cliente frecuente eliminado";
        }
      } catch (error) {
        console.log("Error al eliminar el cliente frecuente: " + 
          this.formInicial.rfcSeleccionado + " " + this.formInicial.razonSocial);
        this.getClientesList();
        this.dialogoBorrarCliente = false;
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Error al eliminar el cliente frecuente: " + this.formInicial.rfcSeleccionado;
        console.log(error.response);
      }
    },
    async openMisConceptos() {
      console.log("openMisConceptos....");
      this.$refs.formInicial.validate();

      //Valida Régimen Emisor
      if (this.formInicial.formaRegimenSeleccionado.clave == 0) {
        this.formInicial.formValid = false;
        this.regFiscalEmisorError = ["Seleccione un régimen fiscal emisor"];
      }
      //Valida Régimen Receptor
      if (this.formInicial.regimenClienteSeleccionado.clave == 0) {
        this.formInicial.formValid = false;
        this.regFiscalReceptorError = ["Seleccione un régimen fiscal receptor"];
      }
      //Valida Forma de pago
      if (this.formInicial.formaPagoSeleccionado.clave == 0) {
        this.formInicial.formValid = false;
        this.formaPagoError = ["Seleccione una forma de pago"];
      }
      //Valida Form DIABLITO
      if (!this.formInicial.formValid) {
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Verifique los campos faltantes";
        return;
      }

      this.emision.loadingBusqueda = true;
      try {
        const response = await operations.consultarConceptos({
          rfc: this.$store.state.cliente.rfc,
        });
        console.log(response.data.listaConceptos);

        if (response.status == "200") {
          var arrConceptos = [];
          this.conceptosx = [];
          if (response.data.listaConceptos != null) {
            for (var i = 0; i < response.data.listaConceptos.length; i++) {
              arrConceptos.push({
                claveImpuestoSat:
                  response.data.listaConceptos[i].claveImpuestoSat,
                claveImpuestoLocal:
                  response.data.listaConceptos[i].claveImpuestoLocal,
                claveUnidad: response.data.listaConceptos[i].claveUnidad
                  .trim()
                  .toUpperCase(),
                consecutivo: response.data.listaConceptos[i].consecutivo,
                unidad: response.data.listaConceptos[i].unidad
                  .trim()
                  .toUpperCase(),
                descripcion: response.data.listaConceptos[i].descripcion
                  .trim()
                  .toUpperCase(),
                fechaRegistro: response.data.listaConceptos[i].fechaRegistro,
                idCliente: response.data.listaConceptos[i].idCliente,
                idConceptoCliente:
                  response.data.listaConceptos[i].idConceptoCliente,
                identificadorSat:
                  response.data.listaConceptos[i].identificadorSat,
                productoServicio:
                  response.data.listaConceptos[i].productoServicio,
                rfc: response.data.listaConceptos[i].rfc,
                tasa: response.data.listaConceptos[i].tasa, //iva retenido
                tasaLocal: response.data.listaConceptos[i].tasaLocal,

                ieps: response.data.listaConceptos[i].ieps, //ieps retenido
                isrRet: response.data.listaConceptos[i].isrRet, //isr retenido
                ivaRet: response.data.listaConceptos[i].ivaRet, //iva retenido

                cantidad: "0",
                descuento: "0.00",
                valorUnitario:
                  "$" +
                  (response.data.listaConceptos[i].valorUnitario == null
                    ? 0
                    : response.data.listaConceptos[i].valorUnitario
                  ).toFixed(2),
                importe: "0.00",
              });
            }
          }
          this.conceptosx = arrConceptos;

          console.log("ok conceptos cargados...");
          console.log(this.conceptosx);
          this.emision.loadingBusqueda = false;
          this.dialogoConceptos = true;
        }
      } catch (error) {
        this.emision.loadingBusqueda = false;
        console.log(error);
        this.snackbar2 = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Error al obtener los conceptos";
      }
    },
    //async openMisClientes() {
    //  console.log("openMisClientes....");
    //  this.ventanaClientes = true;
    //},
    async recargaConceptos() {
      console.log("recrargConceptos....");

      this.emision.loadingBusqueda = true;
      try {
        const response = await operations.consultarConceptos({
          rfc: this.$store.state.cliente.rfc,
        });
        console.log(response.data.listaConceptos);

        if (response.status == "200") {
          var arrConceptos = [];
          this.conceptosx = [];
          for (var i = 0; i < response.data.listaConceptos.length; i++) {
            arrConceptos.push({
              claveImpuestoSat:
                response.data.listaConceptos[i].claveImpuestoSat,
              claveImpuestoLocal:
                response.data.listaConceptos[i].claveImpuestoLocal,
              claveUnidad: response.data.listaConceptos[i].claveUnidad
                .trim()
                .toUpperCase(),
              consecutivo: response.data.listaConceptos[i].consecutivo,
              unidad: response.data.listaConceptos[i].unidad
                .trim()
                .toUpperCase(),
              descripcion: response.data.listaConceptos[i].descripcion
                .trim()
                .toUpperCase(),
              fechaRegistro: response.data.listaConceptos[i].fechaRegistro,
              idCliente: response.data.listaConceptos[i].idCliente,
              idConceptoCliente:
                response.data.listaConceptos[i].idConceptoCliente,
              identificadorSat:
                response.data.listaConceptos[i].identificadorSat,
              productoServicio:
                response.data.listaConceptos[i].productoServicio,
              rfc: response.data.listaConceptos[i].rfc,
              tasa: response.data.listaConceptos[i].tasa,
              tasaLocal: response.data.listaConceptos[i].tasaLocal,

              ieps: response.data.listaConceptos[i].ieps, //ieps retenido
              isrRet: response.data.listaConceptos[i].isrRet, //isr retenido
              ivaRet: response.data.listaConceptos[i].ivaRet, //iva retenido

              cantidad: "0",
              descuento: "0.00",
              valorUnitario:
                "$" +
                (response.data.listaConceptos[i].valorUnitario == null
                  ? 0
                  : response.data.listaConceptos[i].valorUnitario
                ).toFixed(2),
              importe: "0.00",
            });
          }

          this.conceptosx = arrConceptos;

          console.log("ok conceptos cargados...");
          console.log(this.conceptosx);
          this.emision.loadingBusqueda = false;
          this.dialogoConceptos = true;
        }
      } catch (error) {
        this.emision.loadingBusqueda = false;
        console.log(error.response);
      }
    },
    async countCFDIs() {
      console.log("countCFDIs....");

      // this.emision.loadingBusqueda=true;
      try {
        const response = await operations.countCFDIS({
          rfc: this.$store.state.cliente.rfc,
          anio: this.anio,
          mes: this.mes,
        });
        console.log(response.data);

        this.$store.state.countCFDIS = response.data;
      } catch (error) {
        //this.emision.loadingBusqueda=false;
        this.$store.state.countCFDIS = 0;
        console.log(error);
      }
    },
    checkedPlataformas() {
      this.isPlatform = this.checkPlataformas;
      //this.tipoFactura = this.isPlatform ? this.$store.state.catalogoPlataformas[0].plataforma : this.catalogoTiposComprobantes[0].descripcion;
      // console.log(this.checkPlataformas);
      // console.log(this.formInicial.formaPagoSeleccionado);
      //!this.isPlatform;
      if (this.checkPlataformas) {
        // console.log(this.$store.state.catalogoPlataformas);
        this.setTipoFactura(
          this.$store.state.catalogoPlataformas[0].idPlataforma,
          this.$store.state.catalogoPlataformas[0].plataforma
        );
        //this.setTipoFactura("ABNB","AIRBNB");
      } else {
        //console.log(this.catalogoTiposComprobantes);
        //this.auxTipoFactura.descripcion = "Ingreso";
        this.setTipoFactura(null, this.catalogoTiposComprobantes[0]);
      }
    },
    open() {
      // this.snack = true
      // this.snackColor = 'info'
      // this.snackText = 'Dialog opened'
    },
    save() {},
    close(item) {
      console.log("cerrando opciones de cantidad y descuentos...");
      // console.log(item);
      try {
        console.log("aplicando el valor...");
        if (item.cantidad.toString().trim() == "" || item.cantidad == undefined)
          item.cantidad = 0;

        if (
          item.valorUnitario.toString().trim() == "" ||
          item.valorUnitario == undefined
        )
          item.valorUnitario = 0;

        if (
          item.descuento.toString().trim() == "" ||
          item.descuento == undefined
        )
          item.descuento = 0;

        if (item.importe.toString().trim() == "" || item.importe == undefined)
          item.importe = 0;

        item.cantidad = parseFloat(item.cantidad).toFixed(2); //<----new
        item.valorUnitario = parseFloat(item.valorUnitario).toFixed(2);
        item.descuento = parseFloat(item.descuento).toFixed(2);

        item.importe = item.cantidad * item.valorUnitario - item.descuento;

        item.importe = parseFloat(item.importe).toFixed(2);

        for (var i = 0; i < this.conceptosFinales.length; i++) {
          if (
            this.conceptosFinales[i].idConceptoCliente == item.idConceptoCliente
          ) {
            this.conceptosFinales[i].importe = parseFloat(item.importe).toFixed(
              2
            );

            break;
          }
        }
      } catch (error) {
        console.log(error);
      }

      //console.log(this.conceptosFinales);
    },
    cancel() {},
    setTipoFactura(_IdTipoFactura, _tipoFactura) {
      if (_IdTipoFactura == null) {
        this.tipoFactura = _tipoFactura.descripcion;
        this.idTipoFactura = _tipoFactura.descripcion;
        this.checkPlataformas = false;
      } else {
        this.idTipoFactura = _IdTipoFactura;
        this.tipoFactura = _tipoFactura;
      }

      // console.log(this.tipoFactura);
    },
    actualizaRegimenEmisor() {
      console.log(this.$store.state.listRegFiscalUsuario);
      console.log(this.formInicial.formaRegimenSeleccionado);
      this.regFiscalEmisorError = [];
    },
    actualizaRegimenReceptor() {
      // cambio pendiente 4.0
      console.log("lista de regimenes fiscales receptores");
      console.log(this.formInicial.regimenClienteSeleccionado);
      this.regFiscalReceptorError = [];
    },
    actualizaformaPago() {
      console.log(this.catalogoFormaPago);
      console.log(this.formInicial.formaPagoSeleccionado);
      this.formaPagoError = [];
    },
    actualizaRfc() {
      console.log("RFC: " + this.formInicial.rfcSeleccionado);
      if (this.formInicial.clientesList.length > 0){
        for (var i = 0; i < this.formInicial.clientesList.length; i++){
          if(this.formInicial.clientesList[i].rfcReceptor == this.formInicial.rfcSeleccionado){
            this.formInicial.razonSocial = 
              this.formInicial.clientesList[i].razonSocial;
            this.formInicial.email = 
              this.formInicial.clientesList[i].correoElectronico;
            this.formInicial.regimenClienteSeleccionado = 
              this.catalogoSatRegimenFiscales.find(regimen => regimen.clave ===
                this.formInicial.clientesList[i].regimenFiscal)
            this.formInicial.codigoPostal =
              this.formInicial.clientesList[i].codigoPostal;
            this.formInicial.idClienteFrecuente = 
              this.formInicial.clientesList[i].idEmisionServicios;
              this.actualizaRegimenReceptor();
              this.formInicial.disabledBorrarCliente = false;
              this.formInicial.disabledClienteFrecuente = true;
            break;
          }else{
            console.log("no encontrado")
            if (this.formInicial.razonSocial == this.formInicial.clientesList[i].razonSocial){
              this.formInicial.razonSocial = "";
              this.formInicial.regimenClienteSeleccionado= {
                clave: "0",
                descripcion: "Régimen fiscal receptor",
              }
            }
            if (this.formInicial.email == this.formInicial.clientesList[i].correoElectronico)
              this.formInicial.email = "";
            if (this.formInicial.codigoPostal == this.formInicial.clientesList[i].codigoPostal)
              this.formInicial.codigoPostal = "";
            this.formInicial.disabledClienteFrecuente = false;
            this.formInicial.disabledBorrarCliente = true;
          }
        }
      }  
    },
    async validaCatalogoCancel() {
      console.log("validando catalogo");

      if (this.formCancelInicial.motivoCancelacionSeleccionado == "01") {
        console.log(this.cfdiACancelar.referencia);
        var fechaTem = this.cfdiACancelar.fechaTimbrado.split("/");

        var fechaInicial = fechaTem[2] + "-" + fechaTem[1] + "-" + fechaTem[0];
        var fechaFin = new Date().toISOString().substr(0, 10);
        var orden = "";

        console.log("INICIO: " + fechaInicial);
        console.log("FIN: " + fechaFin);

        try {
          const response = await operations.CfdiGeneradosFilter({
            rfc: this.$store.state.cliente.rfc,
            fechaInicialFilter: fechaInicial,
            fechaFinFilter: fechaFin,
            referencia: orden,
            tipo: "F",
          });

          if (response.status == "200") {
            console.log(response.data);

            if (
              response.data.lista == undefined ||
              response.data.lista.length == 0
            ) {
              this.arrCFDISReplaces = [];
              this.snackbar2 = true;
              this.colorSnack = "red";
              this.mensajeSnack =
                "No existen folios que puedan reemplazar al actual";
              return;
            }

            this.arrCFDISReplaces = response.data.lista;
            console.log(this.arrCFDISReplaces);

            var existe = -1;

            for (var i = 0; i < this.arrCFDISReplaces.length; i++) {
              try {
                if (
                  this.arrCFDISReplaces[i].referencia ==
                  this.cfdiACancelar.referencia
                ) {
                  existe = i;
                }
                var xx = new Date(this.arrCFDISReplaces[i].fechaTimbrado);
                this.arrCFDISReplaces[i].fechaTimbrado =
                  this.zeros(xx.getDate()) +
                  "/" +
                  this.zeros(xx.getMonth() + 1) +
                  "/" +
                  xx.getFullYear();
              } catch (error) {
                console.log("Error al formatear fecha:" + error);
              }
            }
            if (existe != -1) {
              console.log(existe);
              this.arrCFDISReplaces.splice(existe, 1);
            }
          }
        } catch {
          console.log(error.response);
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack =
            "Error al obtener folios de reemplazo: " + error.response;
        }
      }
    },
    setCfdiReplace() {
      for (var i = 0; i < this.arrCFDISReplaces.length; ++i) {
        if (
          this.arrCFDISReplaces[i].idCfdi ==
          this.formCancelInicial.ordenReplaceSeleccionado
        ) {
          this.cfdiReplace = this.arrCFDISReplaces[i];
          break;
        }
      }
      console.log(this.cfdiReplace);
    },
    setRegimenFiscal(_regimenFiscalId, _regimenFiscalDesc) {
      this.$store.state.regimenFiscalUsuarioId = _regimenFiscalId;
      this.$store.state.regimenFiscalUsuarioDesc = _regimenFiscalDesc;

      this.$store.state.regimenFiscalUsuario = _regimenFiscalDesc;
    },
    changeFecha() {
      this.$refs.dialog.save(this.$store.state.dashboard.fechaPeriodo);

      this.$store.state.dashboard.facturasEmitidasEgresos = 0;
      this.$store.state.dashboard.ivaTrasladadoEgresos = 0;
      this.$store.state.dashboard.subtotalEgresos = 0;
      this.$store.state.dashboard.totalEgresos = 0;

      this.$store.state.dashboard.facturasEmitidasIngresos = 0;
      this.$store.state.dashboard.ivaTrasladadoIngresos = 0;
      this.$store.state.dashboard.ivaRetenidoIngresos = 0;
      this.$store.state.dashboard.isrRetenido = 0;
      this.$store.state.dashboard.subtotalIngresos = 0;
      this.$store.state.dashboard.totalIngresos = 0;

      this.$store.state.dashboard.anio = "2018";
      this.$store.state.dashboard.mes = "7";
      this.$store.state.dashboard.estatus = 0;
      this.$store.state.dashboard.montoPagar = 0;
      this.$store.state.dashboard.montoRecargosMulta = 0;
      this.$store.state.dashboard.opinionUrl = "";
      this.$store.state.dashboard.acuseUrl = "";

      this.datosFiscales();
      this.ingresosEgresos();
      this.cumplimiento();
      this.countCFDIs();
    },
    async datosFiscales() {
      console.log("datosFiscales....");
      try {
        const response = await operations.datosFiscales({
          rfc: this.$store.state.cliente.rfc,
        });
        console.log(response);

        if (response.status == "200") {
          console.log("ok datos fiscales");
          console.log(response.data.datosFiscales);
          var datos = response.data.datosFiscales;

          this.$store.state.dashboard.email = datos.emailPrincipal;
          this.$store.state.dashboard.razonSocial = datos.razonSocial;
          this.$store.state.dashboard.domicilio =
            datos.calle +
            " " +
            datos.numeroExterior +
            " " +
            datos.numeroInterior +
            ", " +
            datos.colonia +
            " CP." +
            datos.cp +
            ", " +
            datos.municipio +
            ", " +
            datos.estado;

          this.$store.state.dashboard.lugarExpedicion = datos.cp;
          this.$store.state.dashboard.regimenFiscal = datos.regimenFiscal;
          this.$store.state.dashboard.idSat = datos.idSat;
          this.$store.state.dashboard.isCSD = datos.isCSD;
        }
      } catch (error) {
        console.log(error.response);
      }
    },
    async ingresosEgresos() {
      try {
        //this.$store.state.cliente.rfc='RASD661204II6';
        const response = await operations.ingresosEgresos({
          rfc: this.$store.state.cliente.rfc,
          anio: this.anio,
          mes: this.mes,
        });
        console.log(response);

        console.log(response.data.listaReporteIngresosEgresosBean);
        var datos = response.data.listaReporteIngresosEgresosBean;

        //if(tipoComprobante)
        if (datos.length > 0) {
          for (var i = 0; i < datos.length; i++) {
            if (datos[i].tipoComprobante == "INGRESOS") {
              this.$store.state.dashboard.facturasEmitidasIngresos =
                datos[i].facturas;
              this.$store.state.dashboard.ivaTrasladadoIngresos = datos[i].iva;
              this.$store.state.dashboard.ivaRetenidoIngresos =
                datos[i].ivaRetenido;
              this.$store.state.dashboard.isrRetenido = datos[i].isrRetenido;
              this.$store.state.dashboard.subtotalIngresos = datos[i].subtotal;
              this.$store.state.dashboard.totalIngresos = datos[i].total;
            }
            if (datos[i].tipoComprobante == "GASTOS") {
              this.$store.state.dashboard.facturasEmitidasEgresos =
                datos[i].facturas;
              this.$store.state.dashboard.ivaTrasladadoEgresos = datos[i].iva;
              this.$store.state.dashboard.subtotalEgresos = datos[i].subtotal;
              this.$store.state.dashboard.totalEgresos = datos[i].total;
            }
          }
        }
      } catch (error) {
        console.log(error.response);
        //console.log(error.response.data);

        // if(error.response.status == 500){
        //   console.log("500");
        // }else{
        //      console.log("error unknow");
        // }
      }
    },
    async cumplimiento() {
      try {
        //this.$store.state.cliente.rfc='RASD661204II6';  2018 07
        const response = await operations.cumplimiento({
          rfc: this.$store.state.cliente.rfc,
          anio: this.anio,
          mes: this.mes,
        });

        console.log(response.data);
        var datos = response.data;

        this.$store.state.dashboard.anio = datos.anio;
        this.$store.state.dashboard.mes = datos.mes;
        this.$store.state.dashboard.estatus = datos.estatus;
        this.$store.state.dashboard.montoPagar = datos.montoPagar;
        this.$store.state.dashboard.montoRecargosMulta =
          datos.montoRecargosMulta;

        if (datos.listDocumentos.length > 0) {
          for (var i = 0; i < datos.listDocumentos.length; i++) {
            if (datos.listDocumentos[i].tipo == "OPINION") {
              this.$store.state.dashboard.opinionUrl =
                datos.listDocumentos[i].url;
            }
            if (datos.listDocumentos[i].tipo == "ACUSE") {
              this.$store.state.dashboard.acuseUrl =
                datos.listDocumentos[i].url;
            }
            if (datos.listDocumentos[i].tipo == "DECLARACION") {
              this.$store.state.dashboard.declaracionUrl =
                datos.listDocumentos[i].url;
            }
            if (datos.listDocumentos[i].tipo == "CONSTANCIA") {
              this.$store.state.dashboard.constanciaUrl =
                datos.listDocumentos[i].url;
            }
          }
          // if(datos.listDocumentos.length==1){
          //     if(datos.listDocumentos[0].tipo=='OPINION'){
          //       this.$store.state.dashboard.opinionUrl = datos.listDocumentos[0].url;
          //     }else{
          //       this.$store.state.dashboard.acuseUrl = datos.listDocumentos[0].url;
          //     }
          // }else  if(datos.listDocumentos.length==2){
          //   this.$store.state.dashboard.acuseUrl = datos.listDocumentos[0].url;
          //   this.$store.state.dashboard.opinionUrl = datos.listDocumentos[1].url;
          // }
        } else {
          this.$store.state.dashboard.acuseUrl = "";
          this.$store.state.dashboard.opinionUrl = "";
        }
      } catch (error) {
        console.log(error.response);
        //console.log(error.response.data);

        // if(error.response.status == 500){
        //   console.log("500");
        // }else{
        //      console.log("error unknow");
        // }
      }
    },
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth;
      //console.log("windowWidth::"+this.windowWidth);

      this.$store.state.mobile = false;
      this.$store.state.tablet = false;
      this.$store.state.pc = false;
      //console.log(this.windowWidth);
      if (this.windowWidth < 699) {
        this.$store.state.mobile = true;
      }
      // if(this.windowWidth > 600 && this.windowWidth <= 959){
      //   this.tablet=true;
      // }
      if (this.windowWidth > 700) {
        this.$store.state.pc = true;
      }
      //------------------------------------------------------------
    },
    getWindowHeight(event) {
      // console.log("alto::"+document.documentElement.clientHeight);
      // document.getElementById("rowContenedor").style.height=this.windowHeight+"px";
      this.alturaTabAvisos = document.documentElement.clientHeight - 202 + "px";
      // document.getElementById("rowContenedor").style.border="dotted";
    },
    validateImport() {
      console.log(this.conceptosFinales);
      for (var i = 0; i < this.conceptosFinales.length; i++) {
        if (this.conceptosFinales[i].importe <= 0) {
          return true;
          break;
        }
      }
      return false;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
    window.removeEventListener("resize", this.getWindowHeight);
    document.body.removeAttribute("class", "noscroll");
  },
  created() {
    console.log("fecha calendario");
    console.log(this.$store.state.dashboard.fechaPeriodo);

    //change set tipo factura
    //this.setTipoFactura("ABNB", "AIRBNB");
    this.setTipoFactura("UTAXME", "UTAXME");

    /*this.auxTipoFactura = {
          descripcion:"",
          clave:""  
        }*/
  },
  computed: {
    computedDateFormattedIni() {
      return this.formatDate(this.dateFechaIni);
    },
    computedDateFormattedFin() {
      return this.formatDate(this.dateFechaFin);
    },
    getDescuentoTotal() {
      var descuento = 0.0;
      try {
        for (var i = 0; i < this.conceptosFinales.length; i++) {
          descuento += parseFloat(
            this.conceptosFinales[i].descuento == null
              ? 0
              : this.conceptosFinales[i].descuento
          );
        }
      } catch (error) {
        console.log(error);
      }
      console.log("descuento:");
      console.log(descuento);
      return parseFloat(descuento).toFixed(2);
    },
    getSubtotalCFDI() {
      var subTotal = 0.0;
      try {
        for (var i = 0; i < this.conceptosFinales.length; i++) {
          subTotal += parseFloat(
            this.conceptosFinales[i].importe == null
              ? 0
              : this.conceptosFinales[i].importe
          );
        }
      } catch (error) {
        console.log(error);
      }
      console.log("subtotal:");
      console.log(subTotal);
      return parseFloat(subTotal).toFixed(2);
    },
    getTotalCFDI() {
      console.log("getTotalCFDI-----");
      console.log(this.getIVATrasladado);
      console.log(this.getIEPS);
      console.log(this.getISR);
      console.log(this.getIvaRetenido);
      console.log(this.getLocal);

      var total = 0.0;
      try {
        total =
          parseFloat(this.getSubtotalCFDI) +
          parseFloat(this.getIVATrasladado) +
          parseFloat(this.getIEPS) -
          parseFloat(this.getISR) -
          parseFloat(this.getIvaRetenido) +
          parseFloat(this.getLocal);
      } catch (error) {
        console.log(error);
      }
      console.log("total:");
      console.log(total);
      return parseFloat(total).toFixed(2);
    },
    getIVATrasladado() {
      var iva = 0.0;
      var subtotal = this.getSubtotalCFDI;
      var cantidad = 0.0;
      var regresaNull = false;
      try {
        for (var i = 0; i < this.conceptosFinales.length; i++) {
          if (this.conceptosFinales[i].tasa == null) {
            this.conceptosFinales[i].tasa = 0;
            regresaNull = true;
          }
          if (
            this.conceptosFinales[i].tasa != "" &&
            this.conceptosFinales[i].tasa != null
          ) {
            //iva+= parseFloat(this.conceptosFinales[i].tasa) * parseFloat(subtotal) / 100;
            iva +=
              (parseFloat(this.conceptosFinales[i].valorUnitario) *
                parseFloat(this.conceptosFinales[i].cantidad) -
                parseFloat(this.conceptosFinales[i].descuento)) *
              (parseFloat(this.conceptosFinales[i].tasa) / 100);
          }
          if (regresaNull) {
            this.conceptosFinales[i].tasa = null;
            regresaNull = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
      return parseFloat(iva).toFixed(2);
    },
    getIEPS() {
      var ieps = 0.0;
      var subtotal = this.getSubtotalCFDI;
      var cantidad = 0.0;
      var regresaNull = false;

      try {
        for (var i = 0; i < this.conceptosFinales.length; i++) {
          if (this.conceptosFinales[i].ieps == null) {
            this.conceptosFinales[i].ieps = 0;
            regresaNull = true;
          }

          if (
            this.conceptosFinales[i].ieps != "" ||
            this.conceptosFinales[i].ieps != null
          ) {
            // && this.conceptosFinales[i].cantidad != '0'){ // && cantidad > 0.0 ){

            ieps +=
              (parseFloat(this.conceptosFinales[i].ieps) *
                parseFloat(subtotal)) /
              100;
          }
          if (regresaNull) {
            this.conceptosFinales[i].ieps = null;
            regresaNull = false;
          }
        }
      } catch (error) {
        console.log(error);
      }

      return parseFloat(ieps).toFixed(2) == NaN
        ? 0.0
        : parseFloat(ieps).toFixed(2);
    },
    getISR() {
      var isr = 0.0;
      var subtotal = this.getSubtotalCFDI;
      var cantidad = 0.0;
      var regresaNull = false;

      try {
        for (var i = 0; i < this.conceptosFinales.length; i++) {
          if (this.conceptosFinales[i].isrRet == null) {
            this.conceptosFinales[i].isrRet = 0;
            regresaNull = true;
          }
          if (
            this.conceptosFinales[i].isrRet != "" &&
            this.conceptosFinales[i].isrRet != null
          ) {
            // && cantidad > 0.0 ){

            isr +=
              (parseFloat(this.conceptosFinales[i].isrRet) *
                parseFloat(this.conceptosFinales[i].cantidad) *
                parseFloat(this.conceptosFinales[i].valorUnitario)) /
              100;
          }
          if (regresaNull) {
            this.conceptosFinales[i].isrRet = null;
            regresaNull = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
      return parseFloat(isr).toFixed(2);
    },
    getLocal() {
      var local = 0.0;
      var subtotal = this.getSubtotalCFDI;
      var cantidad = 0.0;
      var regresaNull = false;

      try {
        for (var i = 0; i < this.conceptosFinales.length; i++) {
          if (this.conceptosFinales[i].tasaLocal == null) {
            this.conceptosFinales[i].tasaLocal = 0;
            regresaNull = true;
          }
          if (
            this.conceptosFinales[i].tasaLocal != null &&
            this.conceptosFinales[i].tasaLocal != ""
          ) {
            //&& cantidad > 0.0 ){

            local +=
              (parseFloat(
                this.conceptosFinales[i].tasaLocal == null
                  ? 0
                  : this.conceptosFinales[i].tasaLocal
              ) *
                parseFloat(subtotal)) /
              100;
          }
          if (regresaNull) {
            this.conceptosFinales[i].tasaLocal = null;
            regresaNull = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
      return parseFloat(local).toFixed(2);
    },
    getIvaRetenido() {
      var otro = 0.0;
      var subtotal = this.getSubtotalCFDI;
      var cantidad = 0.0;
      var regresaNull = false;

      try {
        for (var i = 0; i < this.conceptosFinales.length; i++) {
          if (this.conceptosFinales[i].ivaRet == null) {
            this.conceptosFinales[i].ivaRet = 0;
            regresaNull = true;
          }
          if (
            this.conceptosFinales[i].ivaRet != null &&
            this.conceptosFinales[i].ivaRet != ""
          ) {
            //&& cantidad > 0.0 ){

            otro +=
              (parseFloat(
                this.conceptosFinales[i].ivaRet == null
                  ? 0
                  : this.conceptosFinales[i].ivaRet
              ) *
                parseFloat(this.conceptosFinales[i].cantidad) *
                parseFloat(this.conceptosFinales[i].valorUnitario)) /
              100;
          }
          if (regresaNull) {
            this.conceptosFinales[i].ivaRet = null;
            regresaNull = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
      return parseFloat(otro).toFixed(2);
    },
    periodoMinimo() {
      return "2015-01";
    },
    periodoMaximo() {
      return new Date().toISOString().substr(0, 10);
    },
    getSesion() {
      return this.$store.getters.getSesion;
    },
    getCliente() {
      return this.$store.getters.getCliente;
    },
    mes() {
      return this.$store.state.dashboard.fechaPeriodo.split("-")[1];
    },
    anio() {
      return this.$store.state.dashboard.fechaPeriodo.split("-")[0];
    },
    getRazonSocial() {
      return this.$store.state.dashboard.razonSocial; //.toUpperCase();
    },
  },
  watch: {
    dateFechaIni(val) {
      this.dateFormattedIni = this.formatDate(this.dateFechaIni);
    },
    dateFechaFin(val) {
      this.dateFormattedFin = this.formatDate(this.dateFechaFin);
    },
    modelSAT(val) {
      // if (val != null) this.tab = 0
      // else this.tab = null
    },
    searchConcepto(val) {
      // Items have already been loaded
      if (this.listaConceptos.length > 0) return;

      this.isLoadingSAT = true;

      // Lazily load input items
      fetch("https://api.coingecko.com/api/v3/coins/list")
        .then((res) => res.clone().json())
        .then((res) => {
          this.listaConceptos = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoadingSAT = false));
    },
  },
  beforeCreate: function () {
    document.getElementsByTagName("body")[0].className = "noscroll";
  },
};
</script>

<style scoped>
/* .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 96%;
} */
.v-data-table /deep/ .encabezado-azul {
  position: sticky;
  top: 0;
  background-color: #042E52;
  color: white !important;
  border-radius: 3px;
  z-index: 1;
}

.v-data-table /deep/ tbody td {
  font-size: 12px !important;
}

.container {
  width: 100%;
  padding: 3px !important;
}

.v-dialog {
  position: absolute;
  top: -120px;
}

.tituloGris {
  color: #c7c3c3;
}

.tituloAzul {
  color: #013872;
}

input[type="radio"]:after {
  width: 22px;
  height: 22px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 9px solid #d1d3d1;
}

input[type="radio"]:checked:after {
  width: 22px;
  height: 22px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 9px solid #013872;
}

input[type="checkbox"]:after {
  width: 12px;
  height: 12px;
  border-radius: 1px;
  top: -5px;
  left: 0px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid #d1d3d1;
}

input[type="checkbox"]:checked:after {
  width: 12px;
  height: 12px;
  border-radius: 1px;
  top: -5px;
  left: 0px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 5px solid #013872;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.selectedRow {
  background-color: lightgrey;
  font-weight: bold;
}
</style>
