 <template>
 <v-col  :cols="cols" style="padding: 0px;padding-bottom:10px;padding-right:5px">
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card :elevation="hover ? 16 : 2">
            <v-toolbar color="#042E52" dark style="height:40px">
             <v-btn icon style="margin-top:-20px">
                <v-icon>mdi-currency-usd</v-icon>
              </v-btn>
              <v-toolbar-title style="margin-top:-20px">Ingresos del periodo</v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <v-list-item style="height:140px;margin-left:7px;padding-left: 5px;padding-right: 5px;margin-top:10px">
                <v-list-item-content style="margin-left:-5px;margin-top:-10px;">
                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="6">Facturas emitidas</v-col>
                    <v-col cols="4" style="margin-top:6px"><hr style="border-style: dotted;color:gray"/> </v-col>
                    <v-col cols="2" style="color:black;font-weight:bolder;">{{$store.state.dashboard.facturasEmitidasIngresos}}</v-col>
                  </v-row>
                  <hr></hr>
                   <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="7">Subtotal de ingresos</v-col>
                    <v-col cols="5" style="color:black;font-weight:bolder;">{{getSubtotalIngresos}}</v-col>
                  </v-row>

                   <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="7">IVA Trasladado</v-col>
                    <v-col cols="5" style="color:black;font-weight:bolder;">{{getIvaTrasladadoIngresos}}</v-col>
                  </v-row>

                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="7">IVA Retenido</v-col>
                    <v-col cols="5" style="color:black;font-weight:bolder;">{{getIvaRetenidoIngresos}}</v-col>
                  </v-row>

                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="7">ISR Retenido</v-col>
                    <v-col cols="5" style="color:black;font-weight:bolder;">{{getIsrRetenido}}</v-col>
                  </v-row>
                  <hr></hr>
                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="7" style="color:black;font-weight:bolder;">Total de ingresos</v-col>
                    <v-col cols="5" style="color:black;font-weight:bolder;">{{getTotalIngresos}}</v-col>
                  </v-row>

                </v-list-item-content>
               </v-list-item>
               </v-container>
               
          </v-card>
          </v-hover>
        </v-col>
</template> 

<script>

import operations from '../../operations';

  export default {
     directives: {

    },
    components:{
     
    },
    name: 'Ingresos',
    data: () => ({
     title:'Datos fiscales',
     src:'https://cdn.vuetifyjs.com/images/cards/house.jpg'
    
    }),
    props:{
     cols:String
    },
    mounted() {
       //console.log("mounted");
      
    },
    methods: {
    
    },
    getWindowHeight(event) {

    },
    beforeDestroy() {
      
    },
    created(){
    
    },
    computed: {
     getSubtotalIngresos(){
       var subtotalIngresos = this.$store.state.dashboard.subtotalIngresos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       if(subtotalIngresos.includes('.'))
           return "$"+subtotalIngresos;
           else
           return "$"+subtotalIngresos + ".00";


       return "$"+subtotalIngresos;//$store.state.dashboard.subtotalIngresos;
     },
     getIvaTrasladadoIngresos(){
       //${{$store.state.dashboard.ivaTrasladadoIngresos}}
       var ivaTrasladadoIngresos = this.$store.state.dashboard.ivaTrasladadoIngresos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       if(ivaTrasladadoIngresos.includes('.'))
           return "$"+ivaTrasladadoIngresos;
           else
           return "$"+ivaTrasladadoIngresos + ".00";


       return "$"+ivaTrasladadoIngresos;//$store.state.dashboard.subtotalIngresos;
     },
     getIvaRetenidoIngresos(){
//{{$store.state.dashboard.ivaRetenidoIngresos}}
       var ivaRetenidoIngresos = this.$store.state.dashboard.ivaRetenidoIngresos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       if(ivaRetenidoIngresos.includes('.'))
           return "$"+ivaRetenidoIngresos;
           else
           return "$"+ivaRetenidoIngresos + ".00";


       return "$"+ivaRetenidoIngresos;//$store.state.dashboard.subtotalIngresos;
     },
     getIsrRetenido(){
        //{{$store.state.dashboard.isrRetenido}}
       var isrRetenido = this.$store.state.dashboard.isrRetenido.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       if(isrRetenido.includes('.'))
           return "$"+isrRetenido;
           else
           return "$"+isrRetenido + ".00";


       return "$"+isrRetenido;//$store.state.dashboard.subtotalIngresos;
     },
     getTotalIngresos(){
       //{{$store.state.dashboard.totalIngresos}}
        var totalIngresos = this.$store.state.dashboard.totalIngresos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       if(totalIngresos.includes('.'))
           return "$"+totalIngresos;
           else
           return "$"+totalIngresos + ".00";


       return "$"+totalIngresos;//$store.state.dashboard.subtotalIngresos;
     },
     vista(){
         return 6;
     }
    },
    beforeCreate: function() {
    
  },
  }
</script>

<style scoped>
</style>