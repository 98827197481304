<template >
<div>
  <v-card class="mx-auto" max-width="1400">
     <v-img class="imgDiv portada white lighten-2" style="height:600px;"
      src="https://utaxon.com/img/home1.png"
      lazy-src="https://picsum.photos/id/11/100/60"
      aspect-ratio="1">
      <div style="display:center!important;margin-top:200px;font-family:'Arial',Times,serif;font-weight:bold;font-size:46px;color:white;text-align:center">Abre la puerta a la contabilidad inteligente</div>
    </v-img>   
  </v-card>

<v-dialog v-model="dialogoLogin" persistent max-width="450px" > 
      <v-card>
        <div id="respuestaActivacion" style="position:absolute;text-align:center;margin-top:10px;color:#227aff;font-family:'Arial',Times,
                      serif;font-size:24px;width:100%;font-weight:bold;"></div>
        <v-card-text>
          <v-form id="frmLogin" style="max-width:400px;margin-top:45px" ref="form" v-model="valid" :lazy-validation="lazy">
          <v-container >
            <v-row >
              <v-col cols="12">
               <h2 style="margin-top:78px;">Ingresa tus credenciales:</h2>
                <v-text-field  style="margin-top:30px;border-radius: 8px;" 
                label="Correo electrónico" 
                v-model="correo"
                maxlength="60"
                hint="correo@domain.com"
                  filled  dense :rules="rulesCorreo" hide-details="auto"
                background-color="#f0f8ff"
                 ></v-text-field>

                 <v-text-field  style="margin-top:8px;border-radius: 8px;" 
                label="Contraseña" 
                :type="show1 ? 'text' : 'password'"
                v-model="contrasenia"
                maxlength="12"
                hint="Ingrese su contraseña"
                  filled  dense :rules="rulesContrasenia" hide-details="auto"
                background-color="#f0f8ff"
                 ></v-text-field>
              </v-col>

              <v-col cols="12" style="width: 100%;text-align: center;">
              <!-- <router-link :to="{name: 'proceso'}" style="width: 100%;text-align: center;"> -->
                <v-btn rounded class="mr-4" color="#6292ba" 
                style="width:150px;font-size:22px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;" 
                :disabled="!valid" @click="login"
                >Aceptar</v-btn>
                <!-- </router-link> -->

                 <v-btn rounded class="mr-4" color="red" 
                style="width:150px;font-size:22px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;" 
                 @click="salir"
                >Cancelar</v-btn>
             </v-col>
            </v-row>
          </v-container>
          </v-form>

           <v-form id="frmMsg" style="max-width:400px;margin-top:45px" ref="form" v-model="valid" :lazy-validation="lazy">
          <v-container >
            <v-row >
              <v-col cols="12">
               <!-- <h2 style="margin-top:78px;text-align:center">Verifica tú correo</h2> -->
               <br><br><br>
              </v-col>

           <!-- <router-link :to="{name: 'home'}" style="width: 100%;text-align: center;"> -->
             <div style="width: 100%;text-align: center;">
              <v-btn rounded class="mr-4" color="#6292ba" @click="salir"
              style="width:250px;font-size:22px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;" 
              >Verifica tú correo</v-btn>
              </div>
          <!-- </router-link> -->
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
import router from '../router';
import { VueMaskDirective   } from 'v-mask'
import operations from '../operations'

  export default {
    directives: {
      'mask': 
      VueMaskDirective
    },
    name: 'Login',

    data: () => ({
      mensajeActivacion:'',
      showLogin:true,
      show1: false,
      valid: true,
      lazy: false,
      dialogoLogin: true,
       correo:'',
      rulesCorreo: [
        value => !!value || 'Requerido.',
        value => /.+@.+\..+/.test(value) || 'El correo debe ser válido',
      ],
       contrasenia:'',
      rulesContrasenia: [
        value => !!value || 'Requerido.',
        value => (value && value.length >= 3) || 'Min 8 caracteres',
      ],
    }),
    computed:{
        
    },
    created(){
      console.log("before");
      this.callActivation();
    },
    methods:{
      async login(){
          let currentObj = this;
          document.getElementById('respuestaActivacion').innerText="";
         
         this.$store.state.cliente.password=this.contrasenia;
         this.$store.state.cliente.email=this.correo;
         
         try{
           const response = await operations.login({ correo: this.correo, contrasenia: this.contrasenia });
              console.log(response);
              if(response.status == "200"){
                 console.log("ok");
                 console.log(response);
                 
                 this.$store.state.sesion.email=this.correo;
                 this.$store.state.sesion.step="proceso";
                 this.$store.state.cliente.celular= response.data.telefono == null ? '0000000000' : response.data.telefono;
                 this.$store.state.cliente.nombre= response.data.nombre == null ? 'Nombre' : response.data.nombre;

                 router.push('/proceso');
              }
              if(response.status == "204"){
                 console.log("204");
                 document.getElementById('respuestaActivacion').innerText="Las credenciales ingresadas no existen";
                 document.getElementById('respuestaActivacion').style.fontSize ='20px';   
              }else{
                document.getElementById('respuestaActivacion').innerText="Las credenciales ingresadas no existen";
                document.getElementById('respuestaActivacion').style.fontSize ='20px';
              }
              
         }catch(error){
           console.log(error);
             console.log(error.response.data);
             if(error.response.data.code=="500"){
                if(error.response.data.response=="No coinciden las credenciales"){
                   document.getElementById('respuestaActivacion').innerText="Credenciales inválidas";
                }else if( (error.response.data.response).startsWith("could not extract")){
                  document.getElementById('respuestaActivacion').innerText="El servicio esta temporalmente inactivo";
                }else{
                  document.getElementById('respuestaActivacion').innerText="El usuario no esta activo en la plataforma";
                }
                 
              }else{
                document.getElementById('respuestaActivacion').innerText=error.response.data.response;
              }
         }

      },
       callActivation(){
         let currentObj = this;
         this.correo = this.$route.query.email;
          this.axios
            .post("https://utaxon.com/utaxon/activation", {
              codigoActivacion: this.$route.query.activationCode,
              email:this.$route.query.email
            })
            .then(function(response) {
              console.log(response.data.code);
              
              if(response.data.code != "200"){
                document.getElementById('respuestaActivacion').innerText=response.data.response;
                document.getElementById('frmMsg').style.display='block';
                document.getElementById('frmLogin').style.display='none';
              }else{
                 document.getElementById('respuestaActivacion').innerText="Usuario activado correctamente";
                 document.getElementById('frmMsg').style.display='none';
                 document.getElementById('frmLogin').style.display='block';
              }
            })
            .catch(function(error) {
              console.log("error:"+error.response.data);
              document.getElementById('respuestaActivacion').innerText=response.data.response;
              document.getElementById('frmMsg').style.display='block';
              document.getElementById('frmLogin').style.display='none';
            });
      },
      checkboxmethod(){
        this.terminos=!this.terminos;
      },
      salir() {
        console.log("salir");
        window.location = 'https://www.utaxme.com'          
      }
    }
  }
</script>
<style scoped>

</style>