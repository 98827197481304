<template>
 <div>
    <content-activacion></content-activacion>
  </div>
</template>

<script>
  import ContentActivacion from '../components/ContentActivacion'

  export default {
    name: 'Activacion',
    components: {
      ContentActivacion
    },
  }
</script>