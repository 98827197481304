 <template>
 <v-col  :cols="cols" style="padding: 0px;padding-bottom:10px;padding-right:5px">
     <v-hover v-slot:default="{ hover }" open-delay="200">
        <v-card :elevation="hover ? 16 : 2">
          <v-toolbar color="#042E52" dark style="height:40px">
            <v-btn icon style="margin-top:-20px">
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
            <v-toolbar-title style="margin-top:-20px">Datos fiscales</v-toolbar-title>
          </v-toolbar>
          <v-container fluid>
            <v-list-item style="height:140px;margin-left:7px;padding-left: 5px;padding-right: 5px;;margin-top:10px">
              <v-list-item-content style="margin-left:-5px;margin-top:-30px;">
                  <v-list-item-subtitle style="color:black;font-weight:bolder;">{{$store.state.cliente.rfc}}</v-list-item-subtitle>
                  <v-list-item-subtitle style="width:100%;word-break: break-word;white-space: normal;color:black;font-weight:bolder;">{{$store.state.dashboard.razonSocial}}</v-list-item-subtitle>
                  <v-list-item-subtitle style="width:100%;word-break: break-word;white-space: normal;margin-top:5px">{{$store.state.dashboard.domicilio}}</v-list-item-subtitle>
                  <v-list-item-subtitle style="width:100%;word-break: break-word;white-space: normal;margin-top:5px;color:black;font-weight:bolder;">{{$store.state.dashboard.email}}</v-list-item-subtitle>
                  <v-list-item-subtitle style="width:100%;word-break: break-word;white-space: normal;margin-top:5px;color:black;font-weight:bolder;">{{$store.state.dashboard.regimenFiscal}}</v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
            </v-container>
              <v-divider color="grey"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              depressed
              small
              color="success"
              outlined
              style="margin-right:5px"
              @click="verConstancia"
              :loading="constanciaBtn"
              :disabled="constanciaBtn"
            ><v-icon x-small>mdi-send</v-icon>CONSTANCIA
            </v-btn>
          </v-card-actions>
          <v-snackbar v-model="snackbar2" :color="colorSnack">
            {{ mensajeSnack }}
            <v-btn text @click="snackbar2 = false">Cerrar</v-btn>
          </v-snackbar>
        </v-card>
    </v-hover>
  </v-col>
</template> 

<script>

import operations from '../../operations';

  export default {
     directives: {

    },
    components:{
     
    },
    name: 'Fiscales',
    data: () => ({
    title:'Datos fiscales',
    src:'https://cdn.vuetifyjs.com/images/cards/house.jpg',
    constanciaBtn: false,
    snackbar2: false,
    colorSnack: "",
    mensajeSnack: "",
    }),
    props:{
     cols:String
    },
    mounted() {
       //console.log("mounted");
      
    },
    methods: {
      verConstancia(){
        this.constanciaBtn = true;
        console.log("constanciaUrl: " + this.$store.state.dashboard.constanciaUrl);
        if(this.$store.state.dashboard.constanciaUrl!=''){
            window.open(this.$store.state.dashboard.constanciaUrl, "_blank");   
        } else {
          console.log("No se encontró ninguna constancia...");
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack =
            "No se encontró ninguna constancia.";
        }
        this.constanciaBtn = false;
      }
    },
    getWindowHeight(event) {

    },
    beforeDestroy() {
      
    },
    created(){
    
    },
    computed: {
     vista(){
         return 6;
     }
    },
    beforeCreate: function() {
    
  },
  }
</script>

<style scoped>
</style>