 <template>
 <v-col  :cols="cols" style="padding: 0px;padding-bottom:10px;padding-right:5px">
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card :elevation="hover ? 16 : 2">
            <v-toolbar color="#042E52" dark style="height:40px">
             <v-btn icon style="margin-top:-20px">
                <v-icon>mdi-police-badge</v-icon>
              </v-btn>
              <v-toolbar-title style="margin-top:-20px">Cumplimiento fiscal</v-toolbar-title>
            </v-toolbar>
            
            <v-container fluid>
              <v-list-item style="height:120px;margin-left:7px;padding-left: 5px;padding-right: 5px;margin-top:0px">
                <v-list-item-content style="margin-left:-5px;margin-top:-10px;">
                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="6">Tipo declaración</v-col>
                    <v-col cols="2" style="margin-top:6px"><hr style="border-style: dotted;color:gray"/> </v-col>
                    <v-col cols="4" style="color:black;font-weight:bolder;">Mensual</v-col>
                  </v-row>

                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px;margin-top:-8px">
                    <v-col cols="6">Año</v-col>
                    <v-col cols="2" style="margin-top:6px"><hr style="border-style: dotted;color:gray"/> </v-col>
                    <v-col cols="4" style="color:black;font-weight:bolder;">{{anio}}</v-col>
                  </v-row>

                   <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px;margin-top:-8px">
                    <v-col cols="6">Mes</v-col>
                    <v-col cols="2" style="margin-top:6px"><hr style="border-style: dotted;color:gray"/> </v-col>
                    <v-col cols="4" style="color:black;font-weight:bolder;">{{mes}}</v-col>
                  </v-row>

                   <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px;margin-top:-8px">
                    <v-col cols="6">Estatus</v-col>
                    <v-col cols="2" style="margin-top:6px"><hr style="border-style: dotted;color:gray"/> </v-col>
                    <v-col cols="4" style="color:black;font-weight:bolder;">{{$store.state.dashboard.estatus}}</v-col>
                  </v-row>

                  <hr></hr>

                   <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px">
                    <v-col cols="7">Monto a pagar</v-col>
                    <v-col cols="5" style="color:black;font-weight:bolder;">{{getmontoPagar}}</v-col>
                  </v-row>

                  <v-row dense style="width:100%;font-size: 0.875rem;color: rgba(0, 0, 0, 0.6);text-align:right;padding:0px;margin-top:-5px">
                    <v-col cols="7">Recargos/multas</v-col>
                    <v-col cols="5" style="color:red;font-weight:bolder;">{{getmontoRecargosMulta}}</v-col>
                  </v-row>

                </v-list-item-content>
               </v-list-item>
               </v-container>

                <!-- <v-divider color="grey"></v-divider> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              
              <v-btn 
                depressed
                small
                color="success"
                outlined
                @click="verAcuse"
                :loading="loadingBtnAcuse"
                :disabled="loadingBtnAcuse"
              ><v-icon x-small>mdi-send</v-icon>
                Ver Acuse
              </v-btn>
              <!-- <v-spacer></v-spacer> -->
              <v-btn 
                depressed
                small
                color="#0065BB"
                outlined 
                @click="verOpinion"
                :loading="loadingBtnOpinion"
                :disabled="loadingBtnOpinion"
              ><v-icon x-small>mdi-send</v-icon>
                Opinión
              </v-btn>
              <!-- <v-btn depressed  small color="success" style="margin-left:-5px" @click="verDeclaracion">
                <v-icon x-small>mdi-send</v-icon>
                Declaración
              </v-btn> -->
              <!-- <v-spacer></v-spacer> -->
              <!-- <v-btn depressed  small color="primary" style="margin-right:-5px">
                <v-icon x-small>mdi-send</v-icon>
                Historial
              </v-btn> -->

            </v-card-actions>
            <v-snackbar v-model="snackbar2" :color="colorSnack">
              {{ mensajeSnack }}
              <v-btn text @click="snackbar2 = false">Cerrar</v-btn>
            </v-snackbar>
          </v-card>
          </v-hover>
        </v-col>
</template>
<script>
  export default {
     directives: {

    },
    components:{
     
    },
    name: 'Cumplimiento',
    data: () => ({
    title:'Datos fiscales',
    src:'https://cdn.vuetifyjs.com/images/cards/house.jpg',
    loadingBtnAcuse: false,
    loadingBtnOpinion: false,
    snackbar2: false,
    colorSnack: "",
    mensajeSnack: "",
    }),
    props:{
     cols:String
    },
    mounted() {
       //console.log("mounted");
      
    },
    methods: {
      verDeclaracion(){
         if(this.$store.state.dashboard.declaracionUrl!=''){
           window.open(this.$store.state.dashboard.declaracionUrl, "_blank");   
        }
      },
      verAcuse(){
        this.loadingBtnAcuse = true;
        if(this.$store.state.dashboard.acuseUrl!=''){
           window.open(this.$store.state.dashboard.acuseUrl, "_blank");   
        } else{
          console.log("No hay Acuse...");
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack =
            "No se encontró ningún acuse.";
        }
        this.loadingBtnAcuse = false;
      },
      verOpinion(){
        this.loadingBtnOpinion = true;
        if(this.$store.state.dashboard.opinionUrl!=''){
            window.open(this.$store.state.dashboard.opinionUrl, "_blank");   
        } else{
          console.log("No hay Opinión...");
          this.snackbar2 = true;
          this.colorSnack = "red";
          this.mensajeSnack =
            "No se encontró ninguna opinión de cumplimiento.";
        }
        this.loadingBtnOpinion = false;
      }
    },
    computed: {
       getmontoPagar(){
       //{{$store.state.dashboard.montoPagar}}
       var montoPagar = this.$store.state.dashboard.montoPagar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       if(montoPagar.includes('.'))
           return "$"+montoPagar;
           else
           return "$"+montoPagar + ".00";


       return "$"+montoPagar;//$store.state.dashboard.montoPagar;
     },
     getmontoRecargosMulta(){
         //{{$store.state.dashboard.montoRecargosMulta}}
       var montoRecargosMulta = this.$store.state.dashboard.montoRecargosMulta.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       if(montoRecargosMulta.includes('.'))
           return "$"+montoRecargosMulta;
           else
           return "$"+montoRecargosMulta + ".00";


       return "$"+montoRecargosMulta;//$store.state.dashboard.montoRecargosMulta;
     },
     vista(){
         return 6;
     },
     mes(){
       return (this.$store.state.dashboard.fechaPeriodo.split('-'))[1];
     },
     anio(){
       return (this.$store.state.dashboard.fechaPeriodo.split('-'))[0];
     }
    },
  }
</script>

<style scoped>
</style>